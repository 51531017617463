import accountApi from "api/accountApi";
import sotApi from "api/sotApi"
import { useState } from "react"

const useFieldChoices = () => {
    const [fields, setFields] = useState(null);
    const [fieldsLoading, setFieldsLoading] = useState(false);
    const [snippets, setSnippets] = useState(null);
    const [snippetsLoading, setSnippetsLoading] = useState(false)
    const [values, setValues] = useState(null);
    const [valuesLoading, setValuesLoading] = useState(false);

    const getFieldChoices = async (payload) => {
        setFieldsLoading(true)
        setFields([])
        const result = await sotApi.marketingBullets.getExpressionFields(payload)
        setFieldsLoading(false)
        setFields(result.data.mbm_fields)
        return result.data.mbm_fields
    };

    const getSnippetChoices = async () => {
        setSnippetsLoading(true);
        setSnippets([]);
        const res = await accountApi.lookups.getSnippets();
        setSnippetsLoading(false)
        const snippets = res.data.map(snippet => ({
            label: snippet.name,
            value: snippet.value
        }));
        setSnippets(snippets);
        return snippets;
    };

    const getCategoryForAPI = (category) => {
        if (category) {
            switch (category) {
                case "Product":
                    return "products";
                default:
                    return category.toLowerCase();
            }
        } else return "providers";
    };

    const getValueOptions = async (field_name, category) => {
        setValuesLoading(true)
        setValues([])
        const payload = {
            dbcolumn: field_name,
            category: getCategoryForAPI(category),
            order: {type: "asc"}
        };
        try {
            const res = await sotApi.getDistinctFieldValues(payload);
            const result = await res.data?.values;
            setValues(result);
            setValuesLoading(false);
            return result;
        } catch {
            setValuesLoading(false);
            return [];
        }
    };

    return {
        fieldChoices: fields,
        getFieldChoices: getFieldChoices,
        fieldsLoading: fieldsLoading,
        snippetChoices: snippets,
        getSnippetChoices: getSnippetChoices,
        snippetsLoading: snippetsLoading,
        getValueOptions: getValueOptions,
        valueChoices: values,
        valuesLoading: valuesLoading
    };
}
export default useFieldChoices;
