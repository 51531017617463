import axios from "axios";
import { baseURL, axiosInstance } from "./axios";
const api = {
  login: (body) => {
    return axiosInstance.post(`${baseURL}/auth/token/`, body);
  },
  refreshToken: (body) => {
    return axiosInstance.post(`${baseURL}/auth/refresh_token`, body);
  },
  logout: (body) => {
    return axiosInstance.delete(`${baseURL}/auth/logout`, body);
  },
  getUser: () => {
    return axiosInstance.get(`${baseURL}/api/account/profile`);
  },
  resetPassword: (body) => {
    return axios.post(`${baseURL}/api/account/password/reset/`, body);
  },
  setNewPassword: (body) => {
    return axios.put(`${baseURL}/api/account/password/reset/`, body);
  },
  changePassword: (body) => {
    return axiosInstance.put(`${baseURL}/api/account/password/change/`, body);
  },
  updateProfile: (body, id) => {
    return axiosInstance.patch(`${baseURL}/api/account/luser/${id}/`, body);
  },
  createNewTfn: (body) => {
    return axiosInstance.post(`${baseURL}/api/tfn/requests/`, body);
  },
  loadTfn: (body, params) => {
    return axiosInstance.put(
      `${baseURL}/api/tfn/requests/query/${params}`,
      body
    );
  },
  getTfnHeaders: () => {
    return axiosInstance.get(`${baseURL}/api/tfn/fields?start=B`);
  },
  getTfnData: (tfnid) => {
    return axiosInstance.get(`${baseURL}/api/tfn/requests/${tfnid}/`);
  },
  updateTfnData: (body, flag) => {
    return axiosInstance.put(`${baseURL}/api/tfn/data?notify=${flag}`, body);
  },
  forceUpdateTfnData: (body) => {
    return axiosInstance.put(`${baseURL}/api/tfn/data?force=True`, body);
  },
  mediaChannelGroup: (body) => {
    return axiosInstance.put(
      `${baseURL}/api/tfn/channelsubchannel/mediachannelgroup/`,
      body
    );
  },
  getLookupData: () => {
    return axiosInstance.get(`${baseURL}/api/tfn/lookups`);
  },
  getLookupDataForUhhs: () => {
    return axiosInstance.get(`${baseURL}/api/tfn/lookups?request_type=3`);
  },
  getMaxLookupValue: () => {
    return axiosInstance.get(`${baseURL}/api/common/lookups/max-lookupcount/`);
  },
  swapRequest: (body) => {
    return axiosInstance.post(`${baseURL}/api/tfn/requests/swaprequest/`, body);
  },
  loadTfnData: (obj) => {
    return axiosInstance.put(
      `${baseURL}/api/tfn/requests/query/?limit=${obj.limit}&offset=${obj.offset}`,
      obj.data
    );
  },
  cloneTfn: (data) => {
    return axiosInstance.post(`${baseURL}/api/tfn/requests/copy/`, data);
  },
  createTfn: (data) => {
    return axiosInstance.post(`${baseURL}/api/tfn/requests/`, data);
  },
  deleteTfn: (id, data) => {
    return axiosInstance.post(
      `${baseURL}/api/tfn/requests/${id}/delete-tfn/`,
      data
    );
  },
  addnewTfn: (tfnid, body) => {
    return axiosInstance.post(
      `${baseURL}/api/tfn/requests/${tfnid}/add-tfn/`,
      body
    );
  },
  autoAssignTfn: (body) => {
    return axiosInstance.post(
      `${baseURL}/api/tfn/requests/autoassign-tfn/`,
      body
    );
  },
  customAssignTfn: (body) => {
    return axiosInstance.post(
      `${baseURL}/api/tfn/requests/customassign-tfn/`,
      body
    );
  },
  addTfnNote: (body) => {
    return axiosInstance.post(`${baseURL}/api/tfn/notes/add-note/`, body);
  },
  retrieveLastTfnNote: (tfnrequest_id) => {
    return axiosInstance.get(
      `${baseURL}/api/tfn/notes/${tfnrequest_id}/get-note/`
    );
  },
  retrieveAllTfnNotes: (tfnrequest_id) => {
    return axiosInstance.get(
      `${baseURL}/api/tfn/notes/${tfnrequest_id}/get-all-note/`
    );
  },
  trasferTfn: (tfnrequest_id, body) => {
    return axiosInstance.put(
      `${baseURL}/api/tfn/requests/${tfnrequest_id}/transfer/`,
      body
    );
  },
  lockTfn: (id) => {
    return axiosInstance.put(`${baseURL}/api/tfn/requests/${id}/lock/`, {});
  },
  unlockTfn: (id) => {
    return axiosInstance.put(`${baseURL}/api/tfn/requests/${id}/unlock/`, {});
  },
  cancelTfn: (id) => {
    return axiosInstance.put(`${baseURL}/api/tfn/requests/${id}/cancel/`);
  },
  uploadTfn: (dataFile) => {
    return axiosInstance.post(`${baseURL}/api/tfn/upload/`, dataFile, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  uploadShutdownTfn: (dataFile) => {
    return axiosInstance.post(`${baseURL}/api/tfn/upload-shutdown/`, dataFile, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  uploadTfnApproval: (dataFile) => {
    return axiosInstance.post(
      `${baseURL}/api/tfn/tfndata/upload_tfnapprovals/`,
      dataFile,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  },
  loadApprovalTfnData: (obj) => {
    return axiosInstance.put(
      `${baseURL}/api/tfn/tfndata/query/?limit=${obj.limit}&offset=${obj.offset}`,
      obj.data
    );
  },
  getTfnInfo: (tfn_id) => {
    return axiosInstance.get(`${baseURL}/api/tfn/tfndata/${tfn_id}`);
  },
  approveTfn: (tfn_id, body) => {
    return axiosInstance.put(
      `${baseURL}/api/tfn/tfndata/${tfn_id}/approve/`,
      body
    );
  },
  rejectTfn: (tfn_id, body) => {
    return axiosInstance.put(
      `${baseURL}/api/tfn/tfndata/${tfn_id}/reject/`,
      body
    );
  },
  loadTfnViewList: (obj) => {
    return axiosInstance.put(
      `${baseURL}/api/tfn/vwtfn-number/query/?limit=${obj.limit}&offset=${obj.offset}`,

      obj.data
    );
  },
  updateTfnNumberData: (obj) => {
    return axiosInstance.patch(
      `${baseURL}/api/tfn/tfn-number/${obj.tfn_number_id}/tfn-single/`,
      obj.data
    ); //changes tfn_number_id to tfnnumber_id
  },
  getTfnCampaignCalendar: () => {
    return axiosInstance.get(`/api/tfn/campaign_calendar`);
  },
  newTfnCampaignName: (body) => {
    return axiosInstance.post(`/api/tfn/campaign_calendar`, body);
  },
  getAllUsersForLookup: () => {
    return axiosInstance.get(`${baseURL}/api/account/luser/`);
  },
  getRequestsTypeForLookup: () => {
    return axiosInstance.get(`${baseURL}/api/tfn/request_types`);
  },
  getLookups: (key) => {
    return axiosInstance.get(`${baseURL}/api/tfn/lookups?key=${key}`);
  },
  getGroupTypeForLookup: (data) => {
    return axiosInstance.put(
      `${baseURL}/api/common/lookups/query/?no_page=true`,
      data
    );
  },
  addNewTfnLookup: (body) => {
    return axiosInstance.post(`${baseURL}/api/common/lookups/`, body);
  },
  deleteTfnRecord: (tfnrequest_id, body) => {
    return axiosInstance.post(
      `${baseURL}/api/tfn/requests/${tfnrequest_id}/delete-tfn/`,
      body
    );
  },
  updateBulkTFNNumberData: (user_id, body) => {
    return axiosInstance.patch(
      `${baseURL}/api/tfn/tfn-number/${user_id}/tfn-multiple/`,
      body
    );
  },
  shutdownBulkTFNNumberData: (body) => {
    return axiosInstance.put(
      `${baseURL}/api/tfn/tfnshutdownrecycle/shutdowntfn/`,
      body
    );
  },
  recycleBulkTFNNumberData: (body) => {
    return axiosInstance.put(
      `${baseURL}/api/tfn/tfnshutdownrecycle/recycletfn/`,
      body
    );
  },
  downloadUsageReport: (body, payload) => {
    return axiosInstance.patch(`${baseURL}/api/tfn/${body}`, payload, {
      responseType: "blob",
    });
  },
  downloadReportByGet: (reportType) => {
    return axiosInstance.get(`${baseURL}/api/tfn/${reportType}/`, {
      responseType: "blob",
    });
  },
  downloadReportByPut: (reportType, body) => {
    return axiosInstance.put(`${baseURL}/api/tfn/${reportType}/`, body, {
      responseType: "blob",
    });
  },
  updateTitle: (request_id, body) => {
    return axiosInstance.patch(
      `${baseURL}/api/tfn/requests/${request_id}/`,
      body
    );
  },
  loadGeographyList: (obj) => {
    return axiosInstance.put(
      `${baseURL}/api/common/geographys/query/?limit=${obj.limit}&offset=${obj.offset}`,
      obj.payload
    );
  },
  loadCreativeTypesList: (obj) => {
    return axiosInstance.put(
      `${baseURL}/api/sot/creativetypes/query/?limit=${obj.limit}&offset=${obj.offset}`,
      obj.payload
    );
  },
  loadCreativeDataList: (obj) => {
    return axiosInstance.put(
      `${baseURL}/api/sot/creativedata/query/?limit=${obj.limit}&offset=${obj.offset}`,
      obj.payload
    );
  },
  loadUIFields: (category, subcategory) => {
    return axiosInstance.get(
      `${baseURL}/api/common/ui_grids/all_ui_Fields/?category=${category}&subcategory=${subcategory}`
    );
  },
  loadUserPreferences: (category, subcategory) => {
    return axiosInstance.get(
      `${baseURL}/api/common/userpreference/preference_grid/?category=${category}&subcategory=${subcategory}`
    );
  },
  updateUserPreferences: (category, subcategory, payload) => {
    return axiosInstance.put(
      `${baseURL}/api/common/userpreference/update_usrprefernce_grid/?category=${category}&subcategory=${subcategory}`,
      payload
    );
  },
  getGeography: (id) => {
    return axiosInstance.get(`${baseURL}/api/common/geographys/${id}/`);
  },
  updateGeography: (id, object) => {
    return axiosInstance.patch(
      `${baseURL}/api/common/geographys/${id}/edit-geography/`,
      object
    );
  },
  getRequiredFields: (body) => {
    return axiosInstance.put(
      `${baseURL}/api/common/lookups/query/?no_page=true`,
      body
    );
  },
  addGeography: (body) => {
    return axiosInstance.post(`${baseURL}/api/common/geographys/`, body);
  },
  deleteGeographies: (obj) => {
    return axiosInstance.post(
      `${baseURL}/api/common/geographys/del-geography/`,
      obj
    );
  },
  downloadGeography: () => {
    return axiosInstance.get(
      `${baseURL}/api/common/geographys/download-geography/`,
      {
        responseType: "blob",
      }
    );
  },
  uploadGeography: (dataFile) => {
    return axiosInstance.post(
      `${baseURL}/api/common/geographys/upload-geography/`,
      dataFile,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  },
  snapfields: () => {
    return axiosInstance.put(`${baseURL}/api/sot/snap/snapfields/`);
  },
  loadChannels: () => {
    return axiosInstance.post(`${baseURL}/api/sot/snap/channel/`);
  },
  loadChannelSegments: (body) => {
    return axiosInstance.post(`${baseURL}/api/sot/snap/chanelsegment/`, body);
  },
  createSnap: (body) => {
    return axiosInstance.post(`${baseURL}/api/sot/snap/addNew/`, body);
  },
  loadSnapList: (obj) => {
    return axiosInstance.put(
      `${baseURL}/api/sot/snap/query/?limit=${obj.limit}&offset=${obj.offset}`,
      obj.payload
    );
  },
  deleteSnaps: (obj) => {
    return axiosInstance.delete(`${baseURL}/api/sot/snap/deletesnap/`, {
      data: obj,
    });
  },
  downloadSnap: () => {
    return axiosInstance.get(`${baseURL}/api/sot/snap/saveas/`, {
      responseType: "blob",
    });
  },
  uploadSnap: (dataFile) => {
    return axiosInstance.post(`${baseURL}/api/sot/uploadsnap/`, dataFile, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  getSnap: (id) => {
    return axiosInstance.post(`${baseURL}/api/sot/snap/${id}/getsnap/`);
  },
  updateSnap: (id, body) => {
    return axiosInstance.put(`${baseURL}/api/sot/snap/${id}/updatesnap/`, body);
  },
  getClone: (id) => {
    return axiosInstance.post(`${baseURL}/api/sot/snap/${id}/copy/`);
  },
  getCreativeTypes: (refId) => {
    //to get specific record of creativeType
    return axiosInstance.get(`${baseURL}/api/sot/removecreative/${refId}`);
  },
  createCreativeType: (obj) => {
    return axiosInstance.post(
      `${baseURL}/api/sot/creativetypes/add-creative/`,
      obj
    );
  },
  cloneCreativeType: (id) => {
    return axiosInstance.post(`${baseURL}/api/sot/creativetypes/${id}/copy/`);
  },
  updateCreativeType: (id, obj) => {
    return axiosInstance.patch(
      `${baseURL}/api/sot/creativetypes/${id}/patch-creative/`,
      obj
    );
  },
  deleteCreativeTypes: (obj) => {
    return axiosInstance.delete(
      `${baseURL}/api/sot/removecreative/delete-creative/`,
      { data: obj }
    );
  },
  downloadCreativeTypes: () => {
    return axiosInstance.get(`${baseURL}/api/sot/creative/saveas/`, {
      responseType: "blob",
    });
  },
  uploadCreativeTypes: (dataFile) => {
    return axiosInstance.post(`${baseURL}/api/sot/fileupload/`, dataFile, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  getCopyBlocksOfCreativeType: (id) => {
    return axiosInstance.post(`${baseURL}/api/sot/creativetypes/${id}/getblk/`);
  },
  getCopyBlocks: () => {
    return axiosInstance.get(`${baseURL}/api/sot/copyblock/lst-blk/`);
  },
  addCopyBlock: (id, body) => {
    return axiosInstance.post(
      `${baseURL}/api/sot/creativetypes/${id}/add/`,
      body
    );
  },
  deleteCopyBlock: (id, body) => {
    return axiosInstance.delete(
      `${baseURL}/api/sot/creativetypes/${id}/remove/`,
      { data: body }
    );
  },
  getRefId: () => {
    return axiosInstance.post(`${baseURL}/api/sot/creativetypes/get_new/`);
  },
  createProduct: (data) => {
    return axiosInstance.post(`${baseURL}/api/sot/product/`, data);
  },
  getProduct: (id) => {
    return axiosInstance.get(`${baseURL}/api/sot/product/${id}/`);
  },
  patchProduct: (id, data) => {
    return axiosInstance.patch(`${baseURL}/api/sot/product/${id}/`, data);
  },
  getCreativeData: (id) => {
    return axiosInstance.get(`${baseURL}/api/sot/creativedata/${id}/`);
  },
  createCreativeData: (obj) => {
    return axiosInstance.post(`${baseURL}/api/sot/creativedata/`, obj);
  },
  cloneCreativeData: (id) => {
    return axiosInstance.post(
      `${baseURL}/api/sot/creativedata/${id}/copy-creativedata/`
    );
  },
  updateCreativeData: (id, obj) => {
    return axiosInstance.patch(
      `${baseURL}/api/sot/creativedata/${id}/edit-creativedata/`,
      obj
    );
  },
  deleteCreativeData: (obj) => {
    return axiosInstance.post(
      `${baseURL}/api/sot/creativedata/del-creativedata/`,
      obj
    );
  },
  downloadCreativeData: (fileOption = "channel_cd") => {
    return axiosInstance.get(
      `${baseURL}/api/sot/creativedata/download?fileoption=${fileOption}`,
      {
        responseType: "blob",
      }
    );
  },
  uploadCreativeData: (dataFile) => {
    return axiosInstance.post(
      `${baseURL}/api/sot/creativedata/upload/`,
      dataFile,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  },
  getRefIdCreativeData: () => {
    return axiosInstance.get(`${baseURL}/api/sot/creativedata/max-refid/`);
  },
  loadChannelsCreativeData: () => {
    return axiosInstance.get(`${baseURL}/api/common/channel`);
  },
  loadChannelSegment: (payload) => {
    return !payload
      ? axiosInstance.get(`${baseURL}/api/common/channel/segments`)
      : axiosInstance.put(`${baseURL}/api/common/channel/segments`, payload);
  },
  loadDisclaimerRefId: () => {
    return axiosInstance.get(`${baseURL}/api/sot/disclaimers_sot/disclaimername/`);
  },
  loadCreativeTypeRefID: () => {
    return axiosInstance.post(`${baseURL}/api/sot/creativetypes/get-creative/`);
  },
  loadProductFields: () => {
    return axiosInstance.get(`${baseURL}/api/common/product_fields/`);
  },
  loadProductDataList: (obj, config = {}) => {
    return axiosInstance.put(
      `${baseURL}/api/sot/product/query/?limit=${obj.limit}&offset=${obj.offset}`,
      obj.payload, { ...config }
    );
  },
  deleteProductData: (obj) => {
    return axiosInstance.post(`${baseURL}/api/sot/product/del-products/`, obj);
  },
  downloadProductData: () => {
    return axiosInstance.get(`${baseURL}/api/sot/product/download/`, {
      responseType: "blob",
    });
  },
  uploadProductData: (dataFile) => {
    return axiosInstance.post(`${baseURL}/api/sot/product/upload/`, dataFile, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      timeout: 0
    });
  },
  loadMarketingBulletsList: (obj) => {
    return axiosInstance.put(
      `${baseURL}/api/sot/user_bullets/query/?limit=${obj.limit}&offset=${obj.offset}`,
      obj.payload
    );
  },
  createNewBulletList: (data) => {
    return axiosInstance.post(`${baseURL}/api/sot/user_bullets/create_set/`, data)
  },
  deleteBulletList: (id) => {
    return axiosInstance.delete(`${baseURL}/api/sot/user_bullets/${id}`);
  },
  previewMarketingBullet: (data) => {
    return axiosInstance.put(`${baseURL}/api/mbm/marketingbullets/preview/`, data)
  },
  previewValProp: (data) => {
    return axiosInstance.put(`${baseURL}/api/mbm/creativedata/preview/`, data)
  },
  createNewUser: (data) => {
    return axiosInstance.post(`/api/account/admin/users/`, data);
  },
  updateUser: (userId, data) => {
    return axiosInstance.patch(`/api/account/admin/users/${userId}/`, data);
  },
  checkUserExist: (username) => {
    return axiosInstance.get(`/api/account/admin/users/username/`, {
      params: { username: username },
    });
  },
  getMBPublish: (id) => {
    return axiosInstance.get(`/api/sot/published_mbs/${id}/`);
  },
  loadLeadcreativeData: () => {
    return axiosInstance.get(
      '/api/common/lookups/by-category-name/?category=CreativeData&lookupname=LeadCreative'
    )
  },
};

export default api;
