import PropTypes from "prop-types";

import RuleSummary from "../Rules/RuleSummary";
import DynamicTabs from "components/common/DynamicTabs";
import SelectionRule from "./SelectionRule";
import TextRuleContainer from "../Rules/TextRuleContainer";
import CreativeTypesGrid from "../ValProps/AddEdit/CreativeTypesGrid";
import { varNamePrefix } from "../Rules/RuleConstants";
import "./RuleContainer.styles.scss";


const RuleContainer = ({
  control,
  refText,
  textRulesJSON,
  setTextRulesJSON,
  selectionRuleJSON,
  setSelectionRuleJSON,
  baseSelectionRuleJSON,
  setBaseSelectionRuleJSON,
  moduleName,
  hasCreativeTypeTab,
  copyblockid, //For CT tab
  isDisabled
}) => {

  const tabs = selectionRuleJSON ? [
    {
      title: "Selection Rules",
      content: <SelectionRule
        control={control}
        selectionRuleJSON={selectionRuleJSON}
        setSelectionRuleJSON={setSelectionRuleJSON}
        baseSelectionRuleJSON={baseSelectionRuleJSON}
        setBaseSelectionRuleJSON={setBaseSelectionRuleJSON}
        moduleName={moduleName}
        isDisabled={isDisabled}
      />
    },
  ] : [];

  const textRuleVars = textRulesJSON.vars?.filter((variable) => variable.text.startsWith(varNamePrefix))

  textRuleVars?.forEach(variable => tabs.push({
    title: variable.symbol.replace(/<\*|\*>/g, ''),
    content: <TextRuleContainer
      variableName={variable.text}
      textRulesJSON={textRulesJSON}
      setTextRulesJSON={setTextRulesJSON}
      isDisabled={isDisabled}
    />
  }))

  if (hasCreativeTypeTab) {
    tabs.push({
      title: "Creative Types",
      content: <CreativeTypesGrid copyblockid={copyblockid} />
    })
  }

  if (textRulesJSON.vars?.length) {
    tabs.push({
      title: "Summary",
      content: <RuleSummary rulesJSON={textRulesJSON} isDisabled={isDisabled} />
    })
  }

  return (
    <div className="rule-container">
      <div className="ref-text"><div className="ref-label">{refText}</div></div>
      <div className="rule-content"><DynamicTabs tabs={tabs} initialValue={0} /></div>
    </div>
  );
};

RuleContainer.propTypes = {
  refText: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool
};

RuleContainer.defaultProps = {
  isDisabled: false
};

export default RuleContainer;