import { useState, useRef, useEffect } from "react";
import { Button, Menu, MenuItem, Checkbox, FormControlLabel, TextField, MenuList } from "@mui/material";
import MoreVert from '@mui/icons-material/MoreVert';

type ManageColumnsProps = {
  menuList: Array<any>;
  onChangeOption: (item: any, checked: 0 | 1) => void;
  closeOnChange?: boolean;
};

const ManageColumns = ({ menuList, onChangeOption, closeOnChange }: ManageColumnsProps): JSX.Element | null => {
  const manageColumnsChangeList = useRef([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [items, setItems] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    if(menuList?.length>0)
      setItems(menuList)
  },[menuList])

  useEffect(() => {
    if(searchTerm !== '')
      filterItems(searchTerm)
   else setItems(menuList)
  },[searchTerm])

  const handleClose = () => {
    setAnchorEl(null);
    if (manageColumnsChangeList?.current?.length) {
      onChangeOption(manageColumnsChangeList.current);
    }
    manageColumnsChangeList.current = [];
  };

  const handleChange = (item, e) => {
    let val = { ...item, visible: e.target?.checked ? 1 : 0 };
    manageColumnsChangeList.current.push(val);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filterItems = (term) => {
    const filteredItems = menuList?.filter((item) => item.colname.toLowerCase().includes(term.toLowerCase()));
    setItems(filteredItems);
  };

  const handleKeyDown = (e) => {
    // Prevent keyboard navigation in the menu
    e.stopPropagation();
  };

  return menuList.length > 0 ? (
    <>
      <Button variant="outlined" className="button" data-testid="button-click" onClick={handleClick}>
        Manage Columns
        <MoreVert style={{ marginLeft: '8px' }} />
      </Button>
      <Menu
        id="manage-columns"
        MenuListProps={{
          'aria-labelledby': 'manage-columns',
          style: {
            padding: '0'
          }
        }}
        anchorEl={anchorEl}
        open={open}
        data-testid="button-close"
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: '305px',
            maxWidth: '400px'
          },
        }}
      >
        <TextField
          label="Search"
          fullWidth
          size="small"
          margin="normal"
          variant="outlined"
          onChange={handleSearchChange}
          value={searchTerm}
          onKeyDown={handleKeyDown}
        />
        {items.map((item) => (
          <MenuItem key={item.dbcolumn} disabled={item.mandatory === 1}>
            <FormControlLabel
              sx={{
                display: 'block',
                width: '100%',
                marginRight: 0
              }}
              control={
                <Checkbox
                  size="small"
                  defaultChecked={item.visible === 1}
                  onChange={(e) => handleChange(item, e)}
                  sx={{
                    padding: '0 10px 0 5px',
                    color: '#754493',
                    '&.Mui-checked': {
                      color: '#754493',
                    },
                  }}
                />
              }
              label={item.colname}
            />
          </MenuItem>
        ))}
      </Menu>
    </>
  ) : null;
};

ManageColumns.defaultProps = {
  menuList: [],
  closeOnChange: true
};

export default ManageColumns;
