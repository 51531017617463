import Stack from "@mui/material/Stack";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import FormControl from "@mui/material/FormControl";
import Autocomplete from '@mui/material/Autocomplete';
import OutlinedInput from "@mui/material/OutlinedInput";
import CircularProgress from '@mui/material/CircularProgress';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import useFieldChoices from "./useFieldChoices";
import { stringOperatorChoices, planChoices, numberOperatorChoices } from "./RuleConstants";
import { RulesContext } from "./context/RulesContext";
import { useContext } from "react";
import { MAP_PROD_HOSTNAME } from "constant";

const isProdEnv = window.location.hostname === MAP_PROD_HOSTNAME;

const RuleCondition = ({
    intialConditionValue,
    onChange,
    onDelete,
    onSelect,
    checked,
    canDelete,
    loadFieldsForTesting,
    isDisabled
}) => {
    const { getValueOptions, valueChoices, valuesLoading } = useFieldChoices()
    const { getPlanFields, filteredFields, fieldsLoading } = useContext(RulesContext)
    const condition = intialConditionValue;
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };
    const operatorChoices = condition.editorType === 4 ? numberOperatorChoices : stringOperatorChoices

    const updateCondition = (field, value, textValue, editorType = null) => {
        let tempCondition = { ...condition }
        const textFieldKey = field + "Text"
        tempCondition[field] = value
        if (tempCondition.hasOwnProperty(textFieldKey)) {
            tempCondition[textFieldKey] = textValue
        }
        if (field === "pln") {
            tempCondition.mbr = ''
            tempCondition.mbrText = ''
        } else if (field === "mbr") {
            tempCondition.editorType = editorType === 4 ? 4 : 1;
            tempCondition.val = '';
            getValueOptions(value, tempCondition.mbrCategory);
        }
        onChange && onChange(tempCondition)
    }

    const updateConditions = (fieldValuePair, textValue, editorType = null) => {
        let tempCondition = { ...condition }
        for (let field in fieldValuePair) {
            const value = fieldValuePair[field]
            const textFieldKey = field + "Text"
            tempCondition[field] = value
            if (tempCondition.hasOwnProperty(textFieldKey)) {
                tempCondition[textFieldKey] = textValue
            }
            if (field === "pln") {
                tempCondition.mbr = ''
                tempCondition.mbrText = ''
            } else if (field === "mbr") {
                tempCondition.editorType = editorType === 4 ? 4 : 1;
                tempCondition.val = '';
                getValueOptions(value, tempCondition.mbrCategory);
            }
        }
        onChange && onChange(tempCondition)
    }

    return (
        <Stack direction="row" spacing={2} sx={{ mb: 2 }} alignItems="center">

            <Checkbox onChange={(event) => onSelect(condition, event.target.checked)} checked={checked} disabled={isDisabled} />

            <FormControl size="small" fullWidth>
                <Select
                    displayEmpty
                    input={<OutlinedInput />}
                    renderValue={(selected) => {
                        return selected ? selected : "Plan";
                    }}
                    MenuProps={MenuProps}
                    inputProps={{ 'aria-label': 'Without label' }}
                    value={condition.pln}
                    onChange={(event) => { updateCondition('pln', event.target.value) }}
                    disabled={isDisabled}
                >
                    {planChoices?.filter(plan => isProdEnv ? !["AP2", "AP3", "AP4", "AP5"].includes(plan?.value) : true)
                    ?.map((choice) => {
                        return (
                            <MenuItem key={choice.value} value={choice.value}>
                                {choice.label}
                            </MenuItem>
                        )
                    })}
                </Select>
            </FormControl>

            <FormControl size="small" fullWidth>
                <Autocomplete
                    size="small"
                    disabled={isDisabled}
                    options={filteredFields.map(fieldChoice => {
                        return {
                            dbcolumn: fieldChoice.dbcolumn?.toLowerCase(),
                            displaytext: fieldChoice.displaytext,
                            relative_dbcolumn: fieldChoice.relative_dbcolumn,
                            category: fieldChoice.category,
                            editortype: fieldChoice.editortype
                        }
                    })}
                    value={{
                        dbcolumn: condition.mbr,
                        displaytext: condition.mbrText,
                        relative_dbcolumn: condition.mbrRelative || null,
                        category: condition.mbrCategory || null,
                        editortype: condition.editortype
                    }}
                    onChange={(event, newValue) => {
                        loadFieldsForTesting ?
                            updateConditions({
                                mbrCategory: newValue?.category,
                                mbr: newValue?.dbcolumn?.toLowerCase(),
                                mbrRelative: newValue?.relative_dbcolumn
                            }, newValue?.displaytext, newValue?.editortype) :
                            updateConditions({
                                mbrCategory: newValue?.category,
                                mbr: newValue?.dbcolumn?.toLowerCase()
                            }, newValue?.displaytext, newValue?.editortype)
                    }}
                    onOpen={() => { getPlanFields(condition.pln) }}
                    getOptionLabel={(option) => option?.displaytext || ""}
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            placeholder="Search Field"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <>
                                        {fieldsLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </>
                                ),
                            }}
                            disabled={isDisabled}
                        />}
                />
            </FormControl>

            <FormControl size="small" fullWidth>
                <Select
                    displayEmpty
                    input={<OutlinedInput />}
                    renderValue={(selected) => {
                        return selected?.value ? selected.label : "Operator";
                    }}
                    MenuProps={MenuProps}
                    inputProps={{ 'aria-label': 'Without label' }}
                    value={{
                        label: condition.opText,
                        value: condition.op
                    }}
                    onChange={(event) => { updateCondition('op', event.target.value.value, event.target.value.label) }}
                    disabled={isDisabled}
                >
                    {operatorChoices?.map((choice) => (
                        <MenuItem
                            key={choice.value}
                            value={{ value: choice.value, label: choice.label }}
                        >
                            {choice.label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            <FormControl size="small" sx={{ m: 1 }} variant="outlined" fullWidth>
                <Autocomplete
                    id="condition-value"
                    disabled={isDisabled}
                    freeSolo
                    multiple
                    autoSelect
                    size="small"
                    options={(valueChoices || []).map(valueChoice => valueChoice?.toString())}
                    value={condition.val?.split(",")?.filter(c => c).map(item=>item.replaceAll('//',','))
                }
                    onChange={(event, newValue) => { updateCondition('val', newValue?.map(c => c?.replace(/,/g, '//'))?.join(',') || '', newValue?.map(c => c?.replace(/,/g, '//'))?.join(',') || '') }}
                    onOpen={() => { condition.val && !valueChoices && getValueOptions(condition.mbr, condition.mbrCategory) }}
                    getOptionLabel={(option) => option || ""}
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <>
                                        {valuesLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </>
                                ),
                            }}
                            disabled={isDisabled}
                        />}
                />
            </FormControl>

            {canDelete && <IconButton
                aria-label="delete"
                onClick={onDelete}
                sx={{ height: '10%', color: '#827ac1' }}
                disabled={isDisabled}
            >
                <DeleteOutlineIcon />
            </IconButton>}

        </Stack >
    )
}

export default RuleCondition;
