import axios from "axios";

// export const baseURL = "http://localhost:8000"
export const baseURL = ""; // Deployment
// export const baseURL = "https://aetnaamp-qa.data-axle.com"

export const axiosInstance = axios.create({
  baseURL: baseURL,
  timeout: 3600000,
  headers: {
    Authorization: localStorage.getItem("access_token")
      ? "Bearer " + localStorage.getItem("access_token")
      : null,
    "Content-Type": "application/json",
    accept: "application/json",
  },
});

const AxiosComponent = ({ history }) => {
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (typeof error.response === "undefined") {
      console.log("A server/network/CORS error occurred.");
      return Promise.reject(error);
    }

    if (
      error.response.status === 401 &&
      originalRequest.url === baseURL + "token/refresh/"
    ) {
      window.location.href = "/login/";
      return Promise.reject(error);
    }

    if (
        (error.response.data.code === "token_not_valid" || error.response.data.constructor === Blob) &&
      error.response.status === 401
    ) {
      // if the existing access token is invalid
      const refreshToken = localStorage.getItem("refresh_token");

      if (refreshToken) {
        const tokenParts = JSON.parse(atob(refreshToken.split(".")[1]));

        // exp date in token is expressed in seconds, while now() returns milliseconds:
        const now = Math.ceil(Date.now() / 1000);

        if (tokenParts.exp > now) {
          //get new access token with existing refresh token
          return axiosInstance
            .post("/auth/token/refresh/", { refresh: refreshToken })
            .then((response) => {
              localStorage.setItem("access_token", response.data.access);
              //   localStorage.setItem("refresh_token", response.data.refresh);

              axiosInstance.defaults.headers["Authorization"] =
                "Bearer " + response.data.access;
              originalRequest.headers["Authorization"] =
                "Bearer " + response.data.access;

              return axiosInstance(originalRequest);
            })
            .catch((err) => {
              console.log(err)
              throw err
            });
        } else {
          console.log("Refresh token is expired", tokenParts.exp, now);
          localStorage.removeItem("access_token");
          localStorage.removeItem("refresh_token");
          localStorage.removeItem("channelType");
          history.push("/login", { fromLogout: true })
        }
      } else {
        console.log("Refresh token not available.");
        localStorage.removeItem("access_token");
        history.push("/login", { fromLogout: true });
      }
    }
    //  else {
    //   console.log("No active account found with the given credentials");
    // return Promise.reject('INVALID_CRED');
    // }

    // specific error handling done elsewhere
    return Promise.reject(error);
  }
);
  return null;
}

export default AxiosComponent;
