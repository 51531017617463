import React, {useState, useContext} from "react"
import {useHistory, withRouter} from "react-router-dom";

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import sotApi from 'api/sotApi'
import PromptAlert from "components/PromptAlert";
import styles from '../../sourcedocs.module.scss';
import {ApiParams} from '../constants'
import InfoDialog from 'components/common/Dialogs/InfoDialog';
import CreativeTypeForm from "./CreativeTypeForm";
import {SnackbarContext} from 'context/SnackbarContext'

const NewCreativeType = () => {
  const [exceptionError, setexceptionError] = useState("Some Error Occured!");
  const [openDialog, setOpenDialog] = useState(false)
  const [refId, setRefId] = React.useState()
  const [ctName, setCTName] = React.useState()
  const [cbCount, setCBCount] = React.useState(0)
  const [refIdError, setRefIdError] = React.useState()
  const [creativeTypeError, setCreativeTypeError] = React.useState()
  const [cbError, setCBError] = React.useState()
  const [callingApi, setCallingApi] = React.useState(false)
  const [largestRefId, setLargestRefId] = React.useState()
  const [isBlocking, setBlocking] = React.useState(false)
  const errorLength = [];
  const history = useHistory()
  const {setSnackBarConfig} = useContext(SnackbarContext); 

  React.useEffect(() => {
    const getLatestRefId = async () => {
      try {
        const res = await sotApi[ApiParams.moduleName].getRefId();

        if (res?.data)
          setLargestRefId(res.data.refId);
      } catch (e) {
      }
    }
    getLatestRefId();
  }, [])

  React.useEffect(() => {
    if (refId?.length > 7) {
      setRefIdError("RefId cannot be more than 7 characters")
      errorLength.push(Math.random())
    }
    if (cbCount < 0 || cbCount > 6) {
      setCBError("Copy Block Count must be a value between 0...6")
      // setCallingApi(false)
      errorLength.push(Math.random())
    }
    if (typeof cbCount === "undefined" || cbCount.length === 0) {
      setCBError("Copy Block Count is required")
      // setCallingApi(false)
      errorLength.push(Math.random())
    }
    if (isNaN(+cbCount)) {
      setCBError('The field Copy Block Count should be a number')
      errorLength.push(Math.random())
    }
  }, [cbCount, refId, ctName])

  React.useEffect(() => {
    if (callingApi) {
      createData();
    }
  }, [callingApi])
  const createData = async () => {
    try {
      await sotApi[ApiParams.moduleName].add(
        {
          refid: refId,
          creativetypename: ctName,
          copyblockcount: cbCount
        }
      )
      setSnackBarConfig({open: true, message: 'New creative type created successfully.', severity: 'success'})
      history.push({
        pathname: ApiParams.baseUrl,
      });
      setCallingApi(false)
    } catch (e) {

      let err = ""
      e?.response?.data?.refid?.forEach(c => (err = err + c))
      setOpenDialog(true)
      setexceptionError(err)
    }
    setRefId('')
    setCTName('')
    setCBCount(0)
    setCallingApi(false)
  }
  const handleCBCount = (e) => {
    setCBError('')
    setCBCount(e.target.value)
  }
  const handleChange = (e) => {
    e.preventDefault();
    setBlocking(false);

    if (typeof cbCount === "undefined" || cbCount.length === 0) {
      setCBError("Copy Block Count is required")
      errorLength.push(Math.random())
    }
    if (cbCount < 0 || cbCount > 6) {
      setCBError("Copy Block Count must be a value between 0...6")
      errorLength.push(Math.random())
    }
    if (isNaN(+cbCount)) {
      setCBError('The field CopyBlockCount should be a number')
      errorLength.push(Math.random())
    }
    if (refId?.length > 7) {
      setRefIdError("RefId cannot be more than 7 characters")
      errorLength.push(Math.random())
    }
    if (cbCount < 0 || cbCount > 6) {
      setCBError("Copy Block Count must be a value between 0...6")
      errorLength.push(Math.random())
    }
    if (typeof refId === "undefined" || refId.length === 0) {
      setRefIdError("Ref ID is required")
      errorLength.push(Math.random())
    }
    if (typeof ctName === "undefined" || ctName?.length === 0 || ctName === ""
      || ctName?.match(/ /g)?.length === ctName?.length
    ) {
      setCreativeTypeError("Creative Type Name is required")
      errorLength.push(Math.random())
    }
    if (typeof ctName !== "undefined" && ctName?.length > 100) {
      setCreativeTypeError('Creative Type Name cannot be more than 100 characters')
      errorLength.push(Math.random())
    }
    const val = (cbCount + "").split(".")[1] || 0;
    if (val !== 0 || cbCount.toString().indexOf('.') !== -1) {
      setCBError(`${cbCount} value is not valid for Copy Block Count`)
      errorLength.push(Math.random())
    }
    if (errorLength.length === 0) {
      setCallingApi(true)
    }
  }
  return <> <h4 style={{color: '#8F8A92', fontSize: '14px', margin: 0}}>CREATIVE TYPES</h4>
    <h3 style={{color: '#050307', fontSize: '29px', margin: 0}}>New</h3>
    <div className="new-wrapper">
      <div style={{padding: 30}}>
        <Stack direction="row" spacing={1}>
          <Button variant="outlined" className="button"
                  onClick={handleChange}>Save
          </Button>
        </Stack>
        <Box pt={5} className={styles.sourceDocsContainer}>
          <Grid container spacing={2}>
            <Grid item sm={12} md={6}>
              <div>
                <label className={`required font ${styles.labelText}`}>Ref.ID</label>
              </div>
              <div>
                <input className={styles.inputText} id="refid" type="text" value={refId}
                       onChange={event => {
                         setBlocking(true)
                         setRefIdError("")
                         setRefId(event.target.value)
                       }}/>
              </div>
              <label>(largest currently used RefId:{largestRefId})</label>
              <div><label style={{color: "red"}}>{refIdError}</label></div>
            </Grid>
            <Grid item sm={12} md={6}>
              <div><label className={`required font ${styles.labelText}`}>Creative Type Name</label></div>
              <div><input
                className={styles.inputText} type="text" id="ctName" value={ctName}
                onChange={(e) => {
                  setBlocking(true)
                  setCreativeTypeError('')
                  if (e.target.value.length > 100) {
                    setCreativeTypeError('Creative Type Name cannot be more than 100 characters')
                    errorLength.push(Math.random())
                  }
                  setCTName(e.target.value)
                }}>
              </input></div>
              <label style={{color: "red"}}>{creativeTypeError}</label>
            </Grid>
            <Grid item sm={12} md={6} pt={10}>
              <div><label className={`font ${styles.labelText}`}>Copy Block Count</label></div>
              <div>
                <input
                  className={styles.inputText} type="text" id="cbCount" value={cbCount}
                  onChange={(e) => {
                    const value = e.target.value;
                    setBlocking(true)
                    handleCBCount(e)
                  }}/>
              </div>
              <div><label style={{color: "red"}}>{cbError}</label></div>
            </Grid>
          </Grid>
        </Box>
      </div>
      <PromptAlert isBlocking={isBlocking}/>
      {openDialog && <InfoDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        messageInfo={{title: 'Error', message: exceptionError}}
      />}
    </div>
  </>
}
export default withRouter(NewCreativeType)
