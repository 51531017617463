import React, { useState } from "react";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarContent,
} from "react-pro-sidebar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { default as MUIMenu } from "@mui/material/Menu";
import { default as MUIMenuItem } from "@mui/material/MenuItem";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Avatar from "@mui/material/Avatar";
import { Link, useLocation, useHistory } from "react-router-dom";
import "./style.scss";
import auth from "../auth";
import { axiosInstance } from "../../axios";
import CurrentUserService from "../../services/current-user";
import {hasAccessByPrivilegeName} from "utils";
import {MAP_PROD_HOSTNAME, PRIVILEGES} from "constant";

const NavIcon = (props) => {
  return (
    <div className="navIcon">
      <img src={props.url} className="sideBarButtonIcon" />
    </div>
  );
};
const options = ["Change Password", "Update Profile", "Logout"];

function stringAvatar(name) {
  return {
    sx: {
      bgcolor: "#F8F5FA",
    },
    children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
  };
}
const ITEM_HEIGHT = 48;

const Aside = ({
  image,
  collapsed,
  rtl,
  toggled,
  handleToggleSidebar,
  handleCollapsedChange,
  user,
}) => {
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [appServicesOpen, setAppServicesOpen] = useState(location.pathname.indexOf("/admin/appServices") !== -1);
  const [securityOpen, setSecurityOpen] = useState(location.pathname.indexOf("/admin/groups") !== -1 ||
    location.pathname.indexOf("/admin/users") !== -1 || location.pathname.indexOf("/admin/roles") !== -1);
  const [lookupOpen, setLookupOpen] = useState(location.pathname.indexOf("/admin/lookups/") !== -1);
  const [reportOpen, setReportOpen] = useState(location.pathname.indexOf("/reports/") !== -1);
  const [mbmOpen, setMbmOpen] = useState(location.pathname.indexOf("/messageByMarket/") !== -1)
  const [supportOpen, setSupportOpen] = useState(location.pathname.indexOf("/support/") !== -1)
  let history = useHistory();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const isProdEnv = window.location.hostname === MAP_PROD_HOSTNAME;

  const handleClose = (e) => {
    setAnchorEl(null);
    if (e.currentTarget.textContent === "Change Password") {
      history.push("/changePassword");
    } else if (e.currentTarget.textContent === "Update Profile") {
      history.push("/updateProfile");
    } else if (e.currentTarget.textContent === "Logout") {
      // The refresh token needs to be blacklisted if user logout, so that same refresh_token is not
      // used to get new access_token when previous access_token is expired or timedout
      auth.logout(() => {
        localStorage.removeItem("access_token");
        localStorage.removeItem("refresh_token");
        localStorage.removeItem("channelType");
        axiosInstance.defaults.headers["Authorization"] = null;
        history.push('/login', { fromLogout: true });
      });
    }
  };


  const currentUser = CurrentUserService.getUser();
  const hasAdminPrivileges = currentUser && currentUser.Privileges?.some(privilege=>privilege.indexOf('admin') !== -1)
            && location.pathname.indexOf("/admin/") !== -1
  const hasSeedListPrivileges= currentUser && currentUser.Privileges?.some(privilege=>privilege.indexOf('seedlistadmin') !== -1) &&
            location.pathname.indexOf("/seedLists") !== -1
   return (
    <ProSidebar
      image={false}
      rtl={rtl}
      collapsed={collapsed}
      toggled={toggled}
      breakPoint="md"
      onToggle={handleToggleSidebar}
    >
      <SidebarHeader>
        <div
          style={{
            padding: "24px",
            textTransform: "uppercase",
            fontWeight: "bold",
            fontSize: 14,
            letterSpacing: "1px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          <div>
            {!collapsed && (
              <img
                style={{ width: "100px", marginTop: "3px" }}
                src="/icons/navbar/amplogo.svg"
              />
            )}
            <IconButton
              onClick={() => history.push('/selectModule')}
              aria-label="collapse menu"
              style={{
                float: "right",
                fontSize: "25px",
                marginTop: "-10px",
                color: "#8f58b1",
              }}
              size="large"
            >
              <MenuIcon fontSize="inherit" />
            </IconButton>
          </div>
        </div>
      </SidebarHeader>

      <SidebarContent>
        {!collapsed && (
          <div
            style={{
              height: 200,
              backgroundColor: "#FDFDFD",
              marginTop: "-20px",
              padding: "48px 0px",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Avatar
                {...stringAvatar(`${user.first_name} ${user.last_name}`)}
                sx={{ width: 76, height: 76 }}
                className="avatar"
              />
            </div>
            <div style={{ textAlign: "center" }}>
              <span>
                {user.first_name} {user.last_name}
              </span>
              <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? "long-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onClick={handleClick}
              >
                <ArrowDropDownIcon />
              </IconButton>
              <MUIMenu
                id="long-menu"
                MenuListProps={{
                  "aria-labelledby": "long-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    maxHeight: ITEM_HEIGHT * 4.5,
                    width: "20ch",
                  },
                }}
              >
                {options.map((option) => (
                  <MUIMenuItem
                    key={option}
                    selected={option === "Pyxis"}
                    onClick={handleClose}
                  >
                    {option}
                  </MUIMenuItem>
                ))}
              </MUIMenu>
            </div>
          </div>
        )}
        {/* handle this condition based on admin requirement. showing all other modules, if it's not admin. */}
        {
           location.pathname.indexOf("/admin/") === -1 && location.pathname.indexOf("/seedLists") === -1 &&  <Menu iconShape="circle" popperArrow>
          {hasAccessByPrivilegeName([PRIVILEGES.SystemAdmin,PRIVILEGES.TFNManager,PRIVILEGES.TFNMarketing,PRIVILEGES.TFNTelesales,
                 PRIVILEGES.TFNRequestor,PRIVILEGES.TFNRequestorUHHS,PRIVILEGES.TFNCallCenter]) &&<SubMenu
              // suffix={<span className="badge yellow">3</span>}
              title="TOLL-FREE NUMBERS"
              icon={<NavIcon url="/icons/navbar/phone.svg"/>}
              open={location.pathname.indexOf("/tfn/") !== -1 ? true : undefined}
          >
            <MenuItem active={location.pathname === "/tfn/tfnDashboard"}>
              Dashboard <Link to="/tfn/tfnDashboard"/>
            </MenuItem>
            {hasAccessByPrivilegeName([PRIVILEGES.SystemAdmin,PRIVILEGES.TFNRequestor,PRIVILEGES.TFNMarketing,PRIVILEGES.TFNCallCenter,PRIVILEGES.TFNTelesales,PRIVILEGES.TFNRequestorUHHS]) && <MenuItem
                active={
                  location.pathname === "/tfn/tfnRequests" ||
                  location.pathname.indexOf("/tfn/requests/") !== -1
                }
            >
              Requests <Link to="/tfn/tfnRequests"/>
            </MenuItem>}
            {hasAccessByPrivilegeName([PRIVILEGES.SystemAdmin,PRIVILEGES.TFNManager]) && <><MenuItem active={location.pathname === "/tfn/viewTFN"}>
              View TFNs <Link to="/tfn/viewTFN"/>
            </MenuItem>
              <MenuItem active={location.pathname === "/import"}>
              Import TFNs <Link to="/import"/>
              </MenuItem></>}
          </SubMenu>}

          {/*Documents*/}
           { hasAccessByPrivilegeName([PRIVILEGES.SystemAdmin,PRIVILEGES.MbmManager,PRIVILEGES.MbmEditor, PRIVILEGES.MbmPublisher, PRIVILEGES.MbmPublishScheduleAdmin, PRIVILEGES.SourceDocManager, PRIVILEGES.TFNManager, PRIVILEGES.ApprovalAdmin, PRIVILEGES.JobManager]) && <SubMenu
              title="DOCUMENTS"
              icon={<NavIcon url="/icons/navbar/message_by_market.svg" />}
              open={location.pathname.indexOf("/documents") !== -1 ? true : undefined}
          >
            {hasAccessByPrivilegeName([PRIVILEGES.SystemAdmin,PRIVILEGES.MbmManager,PRIVILEGES.MbmEditor]) && <>
              <MenuItem active={location.pathname === "/documents"}>
                Dashboard <Link to="/documents"/>
              </MenuItem>
              <MenuItem
                  active={
                    location.pathname === "/documents/gtm" ||
                    location.pathname.indexOf("/documents/gtm") !== -1
                  }
              >
                Go to Market <Link to="/documents/gtm"/>
              </MenuItem>
              <MenuItem
                  active={
                    location.pathname === "/documents/leadzip" ||
                    location.pathname.indexOf("/documents/leadzip") !== -1
                  }
              >
                Lead ZIP <Link to="/documents/leadzip"/>
              </MenuItem>
            <MenuItem
                  active={
                    location.pathname === "/documents/mbm" ||
                    location.pathname.indexOf("/documents/mbm") !== -1
                  }
              >
                Message by Market <Link to="/documents/mbm"/>
              </MenuItem>
            </>}
            <MenuItem
              active={
                location.pathname === "/documents/jobStatus" ||
                location.pathname.indexOf("/documents/jobStatus") !== -1
              }
            >
              Job Status <Link to="/documents/jobStatus" />
            </MenuItem>
          </SubMenu>}
          {hasAccessByPrivilegeName([PRIVILEGES.SystemAdmin,PRIVILEGES.ApprovalAdmin,PRIVILEGES.TFNTester,PRIVILEGES.ApprovalManager])&& <SubMenu
              title="APPROVALS"
              icon={<NavIcon url="/icons/navbar/approval.svg"/>}
              open={
                ["/approvals"].indexOf(location.pathname) !== -1
                    ? true
                    : undefined
              }
          >
            { hasAccessByPrivilegeName([PRIVILEGES.SystemAdmin,PRIVILEGES.TFNTester])&& <MenuItem active={location.pathname === "/approvals"}>
              TFN Approvals <Link to="/approvals"/>
            </MenuItem>}
          </SubMenu>}
          { hasAccessByPrivilegeName([PRIVILEGES.SystemAdmin,PRIVILEGES.ReportViewer, PRIVILEGES.MBMReportViewer, PRIVILEGES.GTMReportViewer, PRIVILEGES.TFNReporter, PRIVILEGES.LeadZipReportViewer, PRIVILEGES.SOTReportViewer]) && <SubMenu
              // suffix={<span className="badge yellow">3</span>}
              icon={<NavIcon url="/icons/navbar/reports.svg"/>}
              title="REPORTS"
              open={reportOpen}
              onOpenChange={(value) => setReportOpen(value)}
          >
            {hasAccessByPrivilegeName([PRIVILEGES.SystemAdmin,PRIVILEGES.TFNReporter]) && <MenuItem active={location.pathname === "/reports/tfn"}>
              TFN Reports <Link to="/reports/tfn"/>
            </MenuItem>}
             {hasAccessByPrivilegeName([PRIVILEGES.SystemAdmin,PRIVILEGES.ReportViewer, PRIVILEGES.SOTReportViewer]) && <MenuItem active={location.pathname === "/reports/sot"} id="sot-audit">
              SOT Audit <Link to="/reports/sot"/>
            </MenuItem>}
            {hasAccessByPrivilegeName([PRIVILEGES.SystemAdmin,PRIVILEGES.ReportViewer, PRIVILEGES.GTMReportViewer]) && <MenuItem active={location.pathname === "/reports/gtm"} id="gtm-audit">
              GTM Reports <Link to="/reports/gtm"/>
            </MenuItem>}
            {hasAccessByPrivilegeName([PRIVILEGES.SystemAdmin,PRIVILEGES.ReportViewer, PRIVILEGES.LeadZipReportViewer]) && <MenuItem active={location.pathname === "/reports/leadZip"} id="lead-zip">
              LeadZip Reports <Link to="/reports/leadZip"/>
            </MenuItem>}
            {hasAccessByPrivilegeName([PRIVILEGES.SystemAdmin,PRIVILEGES.ReportViewer, PRIVILEGES.MBMReportViewer]) && <MenuItem active={location.pathname === "/reports/mbm"} id="mbm-report">
              MBM Reports <Link to="/reports/mbm"/>
            </MenuItem>}
          </SubMenu>}
          {hasAccessByPrivilegeName([PRIVILEGES.SystemAdmin,PRIVILEGES.SourceDocManager,PRIVILEGES.TestAdmin,PRIVILEGES.ProviderOperator]) && <SubMenu
            icon={<NavIcon url="/icons/navbar/sourceDocs.svg" />}
            title="SOURCE DOCUMENTS"
            open={
              location.pathname.indexOf("/sourcedocs") !== -1
                  ? true
                  : undefined
            }
          >
            {hasAccessByPrivilegeName([PRIVILEGES.SystemAdmin,PRIVILEGES.SourceDocManager]) &&  <>
            <MenuItem active={location.pathname === "/sourcedocs/creativeData"}>
              Creative Data <Link to="/sourcedocs/creativeData"/>
              </MenuItem>
              <MenuItem active={location.pathname === "/sourcedocs/creativeTypes"}>
              Creative Types <Link to="/sourcedocs/creativeTypes" />
              </MenuItem>
              <MenuItem active={location.pathname === "/sourcedocs/geography"}>
              Geography <Link to="/sourcedocs/geography" />
              </MenuItem>
              <MenuItem active={location.pathname === "/sourcedocs/productData"}>
              Product Data <Link to="/sourcedocs/productData" />
              </MenuItem>
            </>}
            { hasAccessByPrivilegeName([PRIVILEGES.SystemAdmin,PRIVILEGES.SourceDocManager,PRIVILEGES.TestAdmin,PRIVILEGES.ProviderOperator])&&
                <MenuItem active={location.pathname === "/sourcedocs/providersData"}>
              Providers Data <Link to="/sourcedocs/providersData" />
              </MenuItem>
              }
            { hasAccessByPrivilegeName([PRIVILEGES.SystemAdmin,PRIVILEGES.SourceDocManager,PRIVILEGES.TestAdmin]) && <MenuItem active={location.pathname === "/sourcedocs/marketingBullets"}>
              Marketing Bullets <Link to="/sourcedocs/marketingBullets" />
            </MenuItem>}
            { hasAccessByPrivilegeName([PRIVILEGES.SystemAdmin,PRIVILEGES.SourceDocManager]) && <MenuItem active={location.pathname === "/sourcedocs/valProps"}>
              Val Props <Link to="/sourcedocs/valProps" />
            </MenuItem>}
            { hasAccessByPrivilegeName([PRIVILEGES.SystemAdmin,PRIVILEGES.SourceDocManager]) && <MenuItem active={location.pathname === "/sourcedocs/disclaimers"}>
              Disclaimers <Link to="/sourcedocs/disclaimers" />
            </MenuItem>}
            { hasAccessByPrivilegeName([PRIVILEGES.SystemAdmin,PRIVILEGES.SourceDocManager]) && <MenuItem active={location.pathname === "/sourcedocs/snap"}>
              Snap <Link to="/sourcedocs/snap"/>
            </MenuItem>}
            { hasAccessByPrivilegeName([PRIVILEGES.SystemAdmin,PRIVILEGES.SourceDocManager]) && <MenuItem active={location.pathname === "/sourcedocs/bulletPositioning"}>
              Marketing Bullets Repositioning <Link to="/sourcedocs/bulletPositioning"/>
            </MenuItem>}
          </SubMenu>}
          <SubMenu
            title="SUPPORT"
            icon={<NavIcon url="/icons/navbar/help.svg" />}
            open={supportOpen}
            onOpenChange={(value) => setSupportOpen(value)}
          >
           {hasAccessByPrivilegeName([PRIVILEGES.SystemAdmin]) &&  <MenuItem active={location.pathname === "/support/upload"}>
             Upload <Link to="/support/upload" />
            </MenuItem>}
            <MenuItem active={location.pathname === "/support/docs"}>
             Documents <Link to="/support/docs" />
            </MenuItem>
            <MenuItem active={location.pathname === "/support/videos"}>
              Videos <Link to="/support/videos"/>
            </MenuItem>
          </SubMenu>
        </Menu>}
        {/* Administration */}
        { hasAdminPrivileges && <Menu id='administration' iconShape="circle" popperArrow>
          {/* {!isProdEnv && hasAdminPrivileges && hasAccessByPrivilegeName([PRIVILEGES.JobManager,PRIVILEGES.SystemAdmin,PRIVILEGES.AppServiceAdmin]) && <SubMenu id = 'appServices' onOpenChange={(value)=> setAppServicesOpen(value)} open={appServicesOpen}
            title="APP SERVICES" icon={<NavIcon url="/icons/navbar/app_services.svg"/>}>
             <MenuItem id='appServicesItem' active={location.pathname === "/admin/appServices"}>App Services  <Link to ="/admin/appServices" /></MenuItem>
          </SubMenu>} */}
          {hasAccessByPrivilegeName([PRIVILEGES.SystemAdmin,PRIVILEGES.DigitalContentAdmin]) && <SubMenu id='digitalTargeting' title="DIGITAL TARGETING" icon={<NavIcon url="/icons/navbar/digital_targeting.svg" />}>
            <MenuItem id='importModels'
            active={location.pathname==="/admin/digtalTargeting/dataModel"}
            >Import Models <Link to="/admin/digtalTargeting/dataModel" /></MenuItem>
             <MenuItem id='manageFields' active={location.pathname === "/admin/digitalTargeting/manageFields"}>Manage Fields <Link to ="/admin/digitalTargeting/manageFields" /></MenuItem>
            <MenuItem id='viewModelData'
            active={location.pathname==="/admin/digtalMarketing/viewDataModel"}
            >View Model Data <Link to="/admin/digtalMarketing/viewDataModel" /> </MenuItem>
            <MenuItem id='publishDigitalOutput' active={location.pathname === "/admin/digitalTargeting/publishDigitalOutput"} >Publish Digital Output<Link to ="/admin/digitalTargeting/publishDigitalOutput" /></MenuItem>
          </SubMenu>}
          {hasAdminPrivileges && hasAccessByPrivilegeName([PRIVILEGES.SystemAdmin,PRIVILEGES.LookupManager,PRIVILEGES.NotifyTypesAdmin])&&<SubMenu id='lookups' title="LOOKUPS" icon={<NavIcon url="/icons/navbar/lookups.svg"/>} open={lookupOpen}
                                                                onOpenChange={(value) => setLookupOpen(value)}>
            {hasAccessByPrivilegeName([PRIVILEGES.SystemAdmin])&&<MenuItem id='lookupsCategories' active={location.pathname === "/admin/lookups/categories"}>Lookup
              Categories <Link to="/admin/lookups/categories"/></MenuItem>}
            {hasAccessByPrivilegeName([PRIVILEGES.SystemAdmin])&&<MenuItem id='lookupsTypes' active={location.pathname === "/admin/lookups/types"}>Lookup Types <Link
                to="/admin/lookups/types"/></MenuItem>}
            {hasAccessByPrivilegeName([PRIVILEGES.SystemAdmin,PRIVILEGES.LookupManager])&&<MenuItem id='lookupsValues' active={location.pathname === "/admin/lookups/values"}>Lookup Values <Link
                to="/admin/lookups/values"/></MenuItem>}
            {!isProdEnv && hasAccessByPrivilegeName([PRIVILEGES.SystemAdmin,PRIVILEGES.NotifyTypesAdmin,PRIVILEGES.LookupManager]) && <MenuItem id='lookupsNotification' active={location.pathname === "/admin/lookups/notificationTypes"}>Notification Types <Link
                to="/admin/lookups/notificationTypes"/></MenuItem>}
            {hasAccessByPrivilegeName([PRIVILEGES.SystemAdmin,PRIVILEGES.LookupManager]) && <MenuItem id='lookupsSnippet' active={location.pathname === "/admin/lookups/snippet"}>Snippets <Link
                to="/admin/lookups/snippet"/></MenuItem>}
            {hasAccessByPrivilegeName([PRIVILEGES.SystemAdmin,PRIVILEGES.LookupManager]) && <MenuItem id='lookupsTooltip' active={location.pathname === "/admin/lookups/tooltip"}>Tool-Tips <Link
                to="/admin/lookups/tooltip"/></MenuItem>}
            {hasAccessByPrivilegeName([PRIVILEGES.SystemAdmin]) && <MenuItem id='lookupsImportMediaChannel' active={location.pathname === "/admin/lookups/mediachannelimport"}>Import TFN Media Channel Rules <Link
                to="/admin/lookups/mediachannelimport"/></MenuItem>}
            {hasAccessByPrivilegeName([PRIVILEGES.SystemAdmin]) && <MenuItem id='lookupsExportMediaChannel' active={location.pathname === "/admin/lookups/mediachannelexport"}>Download TFN Media Channel Rules <Link
                to="/admin/lookups/mediachannelexport"/></MenuItem>}
          </SubMenu>}
          {hasAccessByPrivilegeName([PRIVILEGES.MbmManager,PRIVILEGES.SystemAdmin]) &&
            <SubMenu id='messageByMarket' title="MESSAGE BY MARKET"
             icon={<NavIcon url="/icons/navbar/message_by_market.svg"/>}  open={mbmOpen}
            onOpenChange={(value) => setMbmOpen(value)}>
            <MenuItem id='channel'>Channel</MenuItem>
            {hasAccessByPrivilegeName([PRIVILEGES.InternalAdmin]) && <MenuItem id='channelSegmentsUpload' active={location.pathname === "/admin/messageByMarket/channelSegmentsUpload"}>
              Channel Segments<Link to="/admin/messageByMarket/channelSegmentsUpload"/>
            </MenuItem>}
            <MenuItem id='cmsExclusion'>CMS Exclusion</MenuItem>
            <MenuItem id='creativeHits'>Creative Hits</MenuItem>
            <MenuItem id='outputFields' active={location.pathname === "/admin/messageByMarket/prodOutputFields"}>MBM
              Product Output Fields <Link to="/admin/messageByMarket/prodOutputFields"/></MenuItem>
            <MenuItem id='marketingBullet'>Marketing Bullet Configuration</MenuItem>
            <MenuItem id='mbmFields' active={location.pathname === "/admin/messageByMarket/mbmfields"}>MBM Fields <Link
              to="/admin/messageByMarket/mbmfields"/></MenuItem>
            { hasAccessByPrivilegeName([PRIVILEGES.MbmPublisher,PRIVILEGES.SystemAdmin]) && <MenuItem id='publishMBM' active={location.pathname === "/admin/messageByMarket/publishMbm"}>
              Publish MBM <Link to="/admin/messageByMarket/publishMbm"/></MenuItem>}
            { hasAccessByPrivilegeName([PRIVILEGES.MbmPublisher,PRIVILEGES.SystemAdmin]) && <MenuItem id='publishSchedule' active={location.pathname === "/admin/messageByMarket/publishSchedule"}>
              Publish Schedule(s) <Link to="/admin/messageByMarket/publishSchedule"/></MenuItem>}
          </SubMenu>}
          {hasAccessByPrivilegeName([PRIVILEGES.SystemAdmin, PRIVILEGES.SecurityAdmin,PRIVILEGES.GroupAdmin]) && <SubMenu
            title="SECURITY"
            id='security'
            icon={<NavIcon url="/icons/navbar/security.svg"/>}
            open={securityOpen}
            onOpenChange={(value) => setSecurityOpen(value)}
          >
            {hasAccessByPrivilegeName([PRIVILEGES.SystemAdmin,PRIVILEGES.GroupAdmin])&& <MenuItem id='groups' active={location.pathname === "/admin/groups"}>Groups <Link
                to="/admin/groups"/></MenuItem>}
            {hasAccessByPrivilegeName([PRIVILEGES.SystemAdmin]) && <MenuItem id='roles' active={location.pathname === "/admin/roles"}>Roles <Link
                to="/admin/roles"/></MenuItem>}
            {hasAccessByPrivilegeName([PRIVILEGES.SystemAdmin, PRIVILEGES.SecurityAdmin])&& <MenuItem id='users' active={location.pathname === "/admin/users"}>Users <Link
                to="/admin/users"/></MenuItem>}
          </SubMenu>}
        </Menu>}
        {hasSeedListPrivileges && hasAccessByPrivilegeName([PRIVILEGES.SeedListAdmin])&& <Menu>
          <SubMenu
            title="SEED LIST"
            id='seedlists'
            icon={<NavIcon url="/icons/navbar/phone.svg"/>}
            open={
              location.pathname.indexOf("/seedLists") !== -1
                  ? true
                  : undefined
            }
          >
            <MenuItem  active={location.pathname==="/seedLists"} >Seed Portal<Link to="/seedLists"/></MenuItem>
          </SubMenu>
        </Menu>}
      </SidebarContent>
    </ProSidebar>
  );
};

export default Aside;
