import CloseIcon from "@mui/icons-material/Close";
import ReplayRounded from "@mui/icons-material/ReplayRounded";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ScienceOutlinedIcon from '@mui/icons-material/ScienceOutlined';

export const booleanOptions = [
  {
    text: "No",
    id: "N",
  },
  {
    text: "Yes",
    id: "Y",
  },
];

export const editButtonList = [
  {
    name: "Save",
    visible: true,
    value: "save",
  },
  {
    name: "Cancel",
    visible: false,
    value: "deleteClone",
    includeCloneOnly: true,
  },
  {
    name: "Copyblocks",
    visible: true,
    value: "copyBlocks",
    hasArrow: true,
    menuItems: [
      { label: "Add", value: "add", icon: <AddCircleIcon /> },
      {
        label: "Remove",
        value: "remove",
        icon: <CloseIcon style={{ fontWeight: "200" }} />,
      },
    ],
  },
];

export const publishList = [
  {
    name: "Save As",
    startIcon: (
      <img alt="save" src="/icons/saveAs.svg" className="buttonIcon" />
    ),
    visible: true,
    value: 'saveAs',
    hasArrow: true,

  },
  {
    name: "Reset Grid",
    startIcon: <ReplayRounded />,
    visible: true,
    value: "resetGrid",
  }
];

export const publishListForTestAdmin = [
  {
    name: "Save As",
    startIcon: (
      <img alt="save" src="/icons/saveAs.svg" className="buttonIcon" />
    ),
    visible: true,
    value: 'saveAs',
    hasArrow: true,

  },
  {
    name: "Reset Grid",
    startIcon: <ReplayRounded />,
    visible: true,
    value: "resetGrid",
  },
  {
    name: "Test",
    startIcon: <ScienceOutlinedIcon />,
    visible: true,
    value: "test",
  },
];

