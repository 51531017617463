import AddCircleIcon from "@mui/icons-material/AddCircle";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";

export const ApiParams = {
  header: ["source documents", "creativeTypes"],
  moduleName: 'creativeTypes',
  methodName: "getAll",
  apiExtraParams: [],
  responseKey: "results",
  cookiesKey: 'creativeTypes',
  baseUrl: '/sourcedocs/creativeTypes'
};
export const CustomTableParams = {
  tableId: 'creativetypeid',
  columnDef: [
    {
      "colname": "Copy Block Count",
      "dbcolumn": "copyblockcount",
      "visible": 1,
      "order": 3,
    },
    {
      "colname": "Ref ID",
      "dbcolumn": "refid",
      "visible": 1,
      "order": 1,
    },
    {
      "colname": "Creative Type Name",
      "dbcolumn": "creativetypename",
      "visible": 1,
      "order": 2,
      "mandatory": 1,
      width: 200
    }
  ]
}
