import {createTheme} from '@mui/material/styles'
import overrides from "./overrides"
import Purple from "./colors/purple";

const theme = () =>
  createTheme({
    palette: {
      primary: {
        main: Purple[500],
        dark: Purple[700],
        light: Purple[300],
      },
      secondary: {
        main: '#8F8A92',
        dark: '#050307'
      }
    },
    components: overrides,
    typography: {
      fontFamily: [
        "'SF Pro Text'",
        '-apple-system',
        'BlinkMacSystemFont',
        'Roboto', 'Segoe UI',
        'Helvetica',
        'Arial',
        'sans-serif',
        'Apple Color Emoji',
        'Segoe UI Emoji',
        'Segoe UI Symbol'
      ].join(),
    },
  })

export default theme
