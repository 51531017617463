import {useEffect, useState} from "react";
import {buildFilter, errorHandler, getDropdownOptionsFrom} from "components/common/utils";
import {compareStr} from "components/common/utils";

//dataObj.channelsegment?.channel?.channel_id
const useGetDropdowns = (api, channel_id) => {
  const [channelOptions, setChannelOptions] = useState([]);
  const [segmentOptions, setSegmentOptions] = useState([]);
  const [brandOptions, setBrandOptions] = useState([]);
  const [LPGOptions, setLPGOptions] = useState([]);
  const [hitOptions, setHitOptions] = useState([]);
  const [creativeTypeRefIDOptions, setCreativeTypeRefIDOptions] = useState([]);
  const [platformOptions, setPlatformOptions] = useState([]);
  const [languageOptions, setLanguageOptions] = useState([]);
  const [leadCreativeOptions, setLeadCreativeOptions] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const populateSegments = (channel_id) => {
    const payload = {
      channel_id_list: [channel_id]
    };
    // Segment options
    api.loadChannelSegment(payload).then(res => {
      setSegmentOptions(res.data.map(item => ({
        id: item.channelsegment_id,
        text: item.shortname,
        department: item.department
      })).sort((a, b) => compareStr(a.text, b.text)));
    })
      .catch(err => {
        alert("Something went wrong");
      });
  };

  useEffect(() => {
      const loadData = async() => {
      const loadChannelPromise = api.loadChannelsCreativeData()
      const loadSegmentsPromise = api.loadChannelSegment({channel_id_list: [channel_id]});
      const loadPlanSubBrandPromise = api.getGroupTypeForLookup(buildFilter(
        [{field: "category", value: "General"}, {field: "lookupname", value: "PlanSubBrand"}]));
      const loadLeadPlanGraphicPromise = api.getGroupTypeForLookup(buildFilter(
        [{field: "category", value: "GoToMarket"}, {field: "lookupname", value: "LPG"}]));
      const loadHitNumberPromise = api.getGroupTypeForLookup(buildFilter(
        [{field: "category", value: "CreativeData"}, {field: "lookupname", value: "HitNumber"}]))
      const loadRefIdPromise = api.loadCreativeTypeRefID();
      const loadPlatformPromise = api.getGroupTypeForLookup(buildFilter(
        [{field: "category", value: "CreativeData"}, {field: "lookupname", value: "CreativeDataPlatform"}]));
      const loadLanguagePromise = api.getGroupTypeForLookup(buildFilter(
        [{field: "category", value: "CreativeData"}, {field: "lookupname", value: "CreativeDataLanguage"}]));
      const loadLeadCreativePromise = api.loadLeadcreativeData();
      let results;
      try {
        setLoading(true)
        results = await Promise.all([loadChannelPromise, loadSegmentsPromise, loadPlanSubBrandPromise, loadLeadPlanGraphicPromise, loadHitNumberPromise, loadRefIdPromise, loadPlatformPromise, loadLanguagePromise, loadLeadCreativePromise]);
      } catch (error) {
        errorHandler(error);
      } finally {
        setLoading(false);
      }
      setChannelOptions(results[0].data.map(item => ({
        id: item.channel_id,
        text: item.shortname
      })).sort((a, b) => compareStr(a.text, b.text)));

      setSegmentOptions(results[1].data.map(item => ({
        id: item.channelsegment_id,
        text: item.shortname,
        department: item.department
      })).sort((a, b) => compareStr(a.text, b.text)));

      setBrandOptions(getDropdownOptionsFrom(results[2].data));
      let lpgLookup = results[3].data.filter(lpg => !["Portfolio National LPG", "0"].includes(lpg.lookupvalue));
      setLPGOptions(getDropdownOptionsFrom(lpgLookup));
      setHitOptions(getDropdownOptionsFrom(results[4].data));
      setCreativeTypeRefIDOptions(results[5].data.map(item => ({
        id: item.refid,
        text: `${item.refid} | ${item.creativetypename}`
      })).sort((a, b) => compareStr(a.id, b.id)));
      setPlatformOptions(getDropdownOptionsFrom(results[6].data));
      setLanguageOptions(getDropdownOptionsFrom(results[7].data));
      setLeadCreativeOptions(getDropdownOptionsFrom(results[8].data));
    }
  loadData();
  }, []);

  return {channelOptions, segmentOptions, brandOptions, LPGOptions, hitOptions, creativeTypeRefIDOptions, platformOptions, languageOptions, leadCreativeOptions, isLoading, populateSegments};
}
export default useGetDropdowns;
