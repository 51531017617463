import Box from "@mui/material/Box";
import React, { useState } from "react";
import TabPanel from "../TabPanel/TabPanel";
import { StyledTab } from "./StyledTab";
import { StyledTabs } from "./StyledTabs";
import PropTypes from "prop-types";

/**
 * Dynamic tabs to render tabs with content.
 * @param tabs example:[{
 *                  title: string
 *                  content: ReactChild | ReactChild[] | ReactChildren | ReactChildren[]
 *                }...]
 * @param initialValue Initial selected value of the tabs
 * @param onTabChange Expose on tab change event to handle at parent with the selected title
 */
const DynamicTabs = ({ tabs, initialValue, onTabChange }) => {
  const [tabValue, setTabValue] = useState(initialValue || 0)

  const handleChange = (tabIndex) => {
    if (tabValue !== tabIndex) {
      setTabValue(tabIndex)
      onTabChange && onTabChange(tabs[tabIndex].title)
    }
  }

  return (
    <>
      <Box>
        <StyledTabs
          value={tabValue}
          data-testid="change"
          onChange={(e, newValue) => handleChange(newValue)}
        >
          {tabs.map((tab) => (
            <StyledTab
              id={`tab-${tab.title}`}
              key={tab.title}
              label={tab.notification? <span style={{display:'flex', justifyContent: 'center', alignItems: 'center'}}>{tab.title} {tab.notification}</span>:`${tab.title}`}
            />
          ))}
        </StyledTabs>
      </Box>
      {tabs.map((tab, index) => (
        <TabPanel
          key={`tabPanel${tab.title}`}
          value={tabValue}
          index={index}
        >
          {tab.content}
        </TabPanel>
      ))}
    </>
  )
}

DynamicTabs.propTypes = {
  tabs: PropTypes.array.isRequired,
  initialValue: PropTypes.number,
  onTabChange: PropTypes.func,
  destroyable: PropTypes.bool
}

DynamicTabs.defaultProps = {
  initialValue: 0,
  destroyable: true
}

export default DynamicTabs
