import "./App.css";
import "@progress/kendo-theme-default/dist/all.css";
import "./components/tfn/importTfn/importTfn.styles.scss";
import "./components/login/login.styles.scss";
import "./components/tfn/ViewTFN/style.scss";
import "./components/sourcedocs/ProductData/productstyles.styles.scss";

import React, { useEffect, useState, lazy, Suspense } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import api from "./Api";
import auth from "./components/auth";
import { ProtectedRoute } from "./components/protectedRoute/protected.route";
import { ParentProtectedRoute } from "./components/protectedRoute/ParentProtectedRoute";

import theme from "./theme";
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from "@mui/material";

import CurrentUserService from './services/current-user';

import { SnackbarContextProvider } from './context/SnackbarContext';

import { TFNbreadCrumbsData } from "./components/breadCrumbs/TFNbreadCrumbsData";
import { SOTbreadCrumbsData } from "./components/breadCrumbs/SOTbreadCrumbsData";
import { SupportBreadcrumb } from "./components/Support";
import {SEEDLISTSbreadCrumbsData } from "./components/breadCrumbs/SEEDLISTSbreadCrumbsData";

import { NewSnap, EditCloneSnap, UploadSnap } from './components/sourcedocs/Snap/AddEditUpload';
import { NewProvider } from "components/sourcedocs/ProvidersData/AddEdit";
import { NewCreativeType, EditCreativeType, UploadCreativeTypes } from "./components/sourcedocs/CreativeTypes/AddEditUpload";
import { NewCreativeData, EditCreativeData, UploadCreativeData } from "./components/sourcedocs/CreativeData/AddEditUpload";
import { MarketingBulletsForm } from "./components/sourcedocs/MarketingBullets/AddEdit";
import { ValPropsForm } from "./components/sourcedocs/ValProps/AddEdit";
import withClearCache from "ClearCache";
import { isReportViewer, pathRedirectionForReportViewers } from "utils";
import AxiosComponent from "./axios";

const Login = lazy(() => import("./components/login/login"));
const ModuleSelection = lazy(() => import("./components/login/moduleSelection"));
const ResetPassword = lazy(() => import("./components/resetPassword/resetPassword"));
const SetNewPassword = lazy(() => import("./components/setNewPassword/setNewPassword"));
const SpreadsheetKendo = lazy(() => import("./components/tfn/spreadsheet/spreadsheet"));
const ChangePassword = lazy(() => import("./components/changePassword/changePassword"));
const UpdateProfile = lazy(() => import("./components/UpdateProfile/UpdateProfile"));
const TfnInfo = lazy(() => import("./components/tfn/approval/tfnInfo"));
const ImportTfn = lazy(() => import("./components/tfn/importTfn/importTfn"));
const UploadViewTFN = lazy(() => import("./components/tfn/ViewTFN/UploadViewTFN"));
const TfnDashboard = lazy(() => import('./pages/TfnDashboard'));
const Manage = lazy(() => import("./pages/Manage.js"));
const Approvals = lazy(() => import("./pages/Approvals"));
const Reports = lazy(() => import("./pages/Reports"));
const TFNRequests = lazy(() => import("./pages/TFNRequests"));
const ViewTFN = lazy(() => import("./pages/ViewTFN"));
const Documents = lazy(() => import("./pages/documents"));
const GeographyPage = lazy(() => import("./pages/GeographyPage"));
const SnapPage = lazy(() => import("./pages/SnapPage"));
const CreateGeographys = lazy(() => import("./components/sourcedocs/Geography/AddEditUpload/CreateGeographys"));
const UploadGeography = lazy(() => import("./components/sourcedocs/Geography/AddEditUpload/UploadGeography"));
const EditGeography = lazy(() => import("./components/sourcedocs/Geography/AddEditUpload/EditGeography"));
const DisclaimersPage = lazy(() => import("./pages/DisclaimersPage"));
const PublishGrid = lazy(() => import("components/sourcedocs/Disclaimers/WorkingSet/PublishGrid"));
const CreativeTypesPage = lazy(() => import("./pages/CreativeTypesPage"));
const ProductData = lazy(() => import("./pages/ProductData"));
const NewProductData = lazy(() => import("./components/sourcedocs/ProductData/NewProductData"));
const EditProductData = lazy(() => import("./components/sourcedocs/ProductData/EditProductData"));
const UploadProductData = lazy(() => import("./components/sourcedocs/ProductData/UploadProductData"));
const CreativeDataPage = lazy(() => import("./pages/CreativeDataPage"));
const Administration = lazy(() => import("./pages/Administration"));
const MarketingBulletsPage = lazy(() => import("./pages/MarketingBulletsPage"));
const ValPropsPage = lazy(() => import("./pages/ValPropsPage"));
const PublishDataGrid = lazy(() => import("./components/sourcedocs/MarketingBullets/WorkingSet/PublishDataGrid"));
const View = lazy(() => import("components/sourcedocs/MarketingBullets/Published/View"));
const ProvidersDataPage = lazy(() => import("pages/ProvidersDataPage"));
const UploadProvidersData = lazy(() => import("components/sourcedocs/ProvidersData/AddEditUpload/UploadProvidersData"));
const DisclaimerForm = lazy(() => import("components/sourcedocs/Disclaimers/AddEdit/DisclaimerForm"));
const PublishDataGrid_ValProps = lazy(() => import("components/sourcedocs/ValProps/WorkingSet/PublishDataGrid_ValProps"));
const DisclaimerGenForm = lazy(() => import("components/sourcedocs/Disclaimers/General Disclaimers/AddEdit/DisclaimerGenForm"));
const Support = lazy(() => import("./components/Support/components/Upload"));
const VideoPlayer = lazy(() => import("./components/Support/components/Video"));
const SupportDocuments = lazy(() => import("./components/Support/components/Documents.tsx"));
const SeedListsPage = lazy(() => import("pages/SeedListsPage"));
const SeedListForm = lazy(() => import("./components/seedLists/AddEditUpload/SeedListForm"));
const UploadSeedList = lazy(() => import("./components/seedLists/AddEditUpload/UploadSeedList"));
const SeedDetailForm = lazy(() => import("./components/seedLists/AddEditUpload/SeedDetailForm"));
const BulletPositioning = lazy(() => import("./components/sourcedocs/BulletPositioning/BulletPositioning"));

function App(props) {
  const initialData = Object.freeze({
    isLoggedIn: false,
    loading: true,
    display: "hide",
  });

  const [user, setUser] = useState({
    first_name: "",
    last_name: "",
    email: "",
  });

  const [appState, setAppState] = useState(initialData);

  useEffect(() => {
    (async () => {
      let access_token = localStorage.getItem("access_token");

      if (access_token) {
        try {
          const res = await api.getUser();
          CurrentUserService.setUser(res.data)
          localStorage.setItem("profile", JSON.stringify(res.data.Profile));
          localStorage.setItem("Privileges", JSON.stringify(res.data.Privileges));
          setUser({
            first_name: res.data.Profile.first_name,
            last_name: res.data.Profile.last_name,
            email: res.data.Profile.email,
          });

          auth.login(() => {
            pathRedirection()
          });
        } catch (error) {
          setAppState({ ...appState, loading: false, display: "show" });
          props.history.push("/login");
        }
      } else if (props.location.pathname.includes("setPassword")) {
        // detect token validity
        try {
          await api.setNewPassword({
            token: props.location.pathname.split("/")[2],
          });
        } catch (error) {
          // console.log(error)
          // if invalid token, redirect to reset password page
          if (error.response.data?.token?.[0] === "Must be a valid UUID.") {
            props.history.push("/reset", { tokenExpired: true });
          }
        }
      } else {
        setAppState({ ...appState, display: "show" });
        props.history.push("/login");
      }
    })();
  }, []);

  const pathRedirection = () => {
    let path = "";
    if (
      props.location.pathname === "/login" ||
      props.location.pathname === "/"
    ) {
      if (isReportViewer()) {
        path = pathRedirectionForReportViewers()
       } else path = "/tfn/tfnDashboard";
    } else path = props.location.pathname;

    props.history.push(path);
  };

  return (
    <ThemeProvider theme={theme()}>
      <SnackbarContextProvider>
        <CssBaseline />
        <Suspense fallback={<div>Loading...</div>}>
          <AxiosComponent history={props.history} />
          <Switch>
            <Route
              path="/login"
              render={() => <Login display={appState.display} setUser={setUser} />}
            />
            <Route path="/selectModule" user={user} component={ModuleSelection} />

            <Route path="/reset" render={() => <ResetPassword />} />
            <Route path="/setPassword/:tokenId" render={() => <SetNewPassword />} />
            {/* <Route path="/changePassword" render={() => <ChangePassword />} />
            <Route path="/updateProfile" render={() => <UpdateProfile />} /> */}
            <ProtectedRoute breadcrumb={TFNbreadCrumbsData.changePassword} path="/changePassword" user={user}
              component={ChangePassword} />
            <ProtectedRoute breadcrumb={TFNbreadCrumbsData.updateProfile} path="/updateProfile" user={user}
              component={UpdateProfile} />
            <ProtectedRoute user={user} path="/manage" component={Manage} />
            <ProtectedRoute breadcrumb={TFNbreadCrumbsData.tfnapproval} user={user} path="/approvals"
              component={Approvals} />
            <ProtectedRoute breadcrumb={TFNbreadCrumbsData.tfnReports} user={user} path="/reports/tfn" component={Reports} />
            <ProtectedRoute breadcrumb={TFNbreadCrumbsData.sotReports} user={user} path="/reports/sot" component={Reports} />
            <ProtectedRoute breadcrumb={TFNbreadCrumbsData.gtmReports} user={user} path="/reports/gtm" component={Reports} />
            <ProtectedRoute breadcrumb={TFNbreadCrumbsData.leadZipReports} user={user} path="/reports/leadZip" component={Reports} />
            <ProtectedRoute breadcrumb={TFNbreadCrumbsData.MbmReports} user={user} path="/reports/mbm" component={Reports} />
            <ProtectedRoute breadcrumb={TFNbreadCrumbsData.importTfn} user={user} path="/import" component={ImportTfn} />
            {/* <ProtectedRoute user={user} path="/support" component={Support} /> */}
            <ProtectedRoute breadcrumb={TFNbreadCrumbsData.requestDetailsPage} user={user} path="/tfn/requests/:tfnid"
              component={SpreadsheetKendo} />
            <ProtectedRoute breadcrumb={TFNbreadCrumbsData.tfnInfoPage} user={user} path="/tfn/approvals/:tfnid"
              component={TfnInfo} />
            <ProtectedRoute breadcrumb={TFNbreadCrumbsData.dashboard} user={user} path="/tfn/tfnDashboard"
              component={TfnDashboard} />
            {/* <ProtectedRoute user={user} path="/tollfreeNumbers" component={TollFreeNumbers} /> */}
            <ProtectedRoute breadcrumb={TFNbreadCrumbsData.requestPage} user={user} path="/tfn/tfnRequests"
              component={TFNRequests} />
            <ProtectedRoute breadcrumb={TFNbreadCrumbsData.viewTfn} user={user} path="/tfn/viewTFN" component={ViewTFN} />
            <ProtectedRoute breadcrumb={TFNbreadCrumbsData.viewTfn} user={user} path="/tfn/tfnReports" component={ViewTFN} />
            <ProtectedRoute breadcrumb={TFNbreadCrumbsData.uploadViewTfn} user={user} path="/tfn/viewTFNs/upload" component={UploadViewTFN} />

            {/* Documents Routes */}
            <ParentProtectedRoute user={user} path="/documents" component={Documents} />

            {/* Support Routes */}
            <ProtectedRoute breadcrumb={SupportBreadcrumb} user={user} path="/support/upload" component={Support}></ProtectedRoute>
            <ProtectedRoute breadcrumb={SupportBreadcrumb} user={user} path="/support/docs" component={SupportDocuments}></ProtectedRoute>
            <ProtectedRoute breadcrumb={SupportBreadcrumb} user={user} path="/support/videos" component={VideoPlayer}></ProtectedRoute>

            {/* SOT Routes */}
            <ProtectedRoute breadcrumb={SOTbreadCrumbsData.uploadGeography} user={user} path="/sourcedocs/geography/upload"
              component={UploadGeography} />
            <ProtectedRoute breadcrumb={SOTbreadCrumbsData.editGeography} user={user} path="/sourcedocs/geography/edit"
              component={EditGeography} />
            <ProtectedRoute breadcrumb={SOTbreadCrumbsData.newGeography} user={user} path="/sourcedocs/geography/new"
              component={CreateGeographys} />
            <ProtectedRoute breadcrumb={SOTbreadCrumbsData.geography} user={user} path="/sourcedocs/geography"
              component={GeographyPage} />

            <ProtectedRoute breadcrumb={SOTbreadCrumbsData.cloneSnap} user={user} path="/sourcedocs/snap/clone"
              component={EditCloneSnap} />
            <ProtectedRoute breadcrumb={SOTbreadCrumbsData.editSnap} user={user} path="/sourcedocs/snap/edit"
              component={EditCloneSnap} />
            <ProtectedRoute breadcrumb={SOTbreadCrumbsData.uploadSnap} user={user} path="/sourcedocs/snap/upload"
              component={UploadSnap} />
            <ProtectedRoute breadcrumb={SOTbreadCrumbsData.createSnap} user={user} path="/sourcedocs/snap/new"
              component={NewSnap} />
            <ProtectedRoute breadcrumb={SOTbreadCrumbsData.snap} user={user} path="/sourcedocs/snap" component={SnapPage} />

            <ProtectedRoute breadcrumb={SOTbreadCrumbsData.newCreativeTypes} user={user}
              path="/sourcedocs/creativeTypes/new" component={NewCreativeType} />
            <ProtectedRoute breadcrumb={SOTbreadCrumbsData.editCreativeTypes} user={user}
              path="/sourcedocs/creativeTypes/edit/:id" component={EditCreativeType} />
            <ProtectedRoute breadcrumb={SOTbreadCrumbsData.cloneCreativeTypes} user={user}
              path="/sourcedocs/creativeTypes/clone/:id" component={EditCreativeType} />
            <ProtectedRoute breadcrumb={SOTbreadCrumbsData.uploadCreativeTypes} user={user}
              path="/sourcedocs/creativeTypes/upload" component={UploadCreativeTypes} />
            <ProtectedRoute breadcrumb={SOTbreadCrumbsData.creativeTypes} user={user} path="/sourcedocs/creativeTypes"
              component={CreativeTypesPage} />

            <ProtectedRoute breadcrumb={SOTbreadCrumbsData.editProductData} user={user} path="/sourcedocs/productData/edit"
              component={EditProductData} />
            <ProtectedRoute breadcrumb={SOTbreadCrumbsData.newProductData} user={user} path="/sourcedocs/productData/new"
              component={NewProductData} />
            <ProtectedRoute breadcrumb={SOTbreadCrumbsData.uploadProductData} user={user}
              path="/sourcedocs/productData/upload" component={UploadProductData} />
            <ProtectedRoute breadcrumb={SOTbreadCrumbsData.productData} user={user} path="/sourcedocs/productData"
              component={ProductData} />

            <ProtectedRoute breadcrumb={SOTbreadCrumbsData.newCreativeData} user={user} path="/sourcedocs/creativeData/new"
              component={NewCreativeData} />
            <ProtectedRoute breadcrumb={SOTbreadCrumbsData.editCreativeData} user={user}
              path="/sourcedocs/creativeData/edit/:id" component={EditCreativeData} />
            <ProtectedRoute breadcrumb={SOTbreadCrumbsData.cloneCreativeData} user={user}
              path="/sourcedocs/creativeData/clone/:id" component={EditCreativeData} />
            <ProtectedRoute breadcrumb={SOTbreadCrumbsData.uploadCreativeData} user={user}
              path="/sourcedocs/creativeData/upload" component={UploadCreativeData} />
            <ProtectedRoute breadcrumb={SOTbreadCrumbsData.creativeData} user={user} path="/sourcedocs/creativeData"
              component={CreativeDataPage} />

            <ProtectedRoute breadcrumb={SOTbreadCrumbsData.newMarketingBullets} user={user} path="/sourcedocs/marketingBullets/:action/:id?"
              component={MarketingBulletsForm} />
            <ProtectedRoute breadcrumb={SOTbreadCrumbsData.view} user={user}
              path="/sourcedocs/marketingBullets/details/:id" component={View} />
            <ProtectedRoute breadcrumb={SOTbreadCrumbsData.marketingBulletsPublish} user={user}
              path="/sourcedocs/marketingBulletsPublish" component={PublishDataGrid} />
            <ProtectedRoute breadcrumb={SOTbreadCrumbsData.marketingBullets} user={user} path="/sourcedocs/marketingBullets"
              component={MarketingBulletsPage} />
            <ProtectedRoute breadcrumb={SOTbreadCrumbsData.valPropsNeedToPublish} user={user}
              path="/sourcedocs/valPropsNeedToPublish"
              component={PublishDataGrid_ValProps} />
            <ProtectedRoute breadcrumb={SOTbreadCrumbsData.newValProps} user={user} path="/sourcedocs/valProps/:action/:id?"
              component={ValPropsForm} />
            <ProtectedRoute breadcrumb={SOTbreadCrumbsData.newValProps} user={user} path="/sourcedocs/valProps_pub/:action/:id?"
              component={ValPropsForm} />
            <ProtectedRoute breadcrumb={SOTbreadCrumbsData.valProps} user={user} path="/sourcedocs/valProps"
              component={ValPropsPage} />
            <ProtectedRoute breadcrumb={SOTbreadCrumbsData.uploadProvidersData} user={user} path="/sourcedocs/providersData/upload"
              component={UploadProvidersData} />
            <ProtectedRoute breadcrumb={SOTbreadCrumbsData.createProvider} user={user} path="/sourcedocs/providersData/:action/:id?"
              component={NewProvider} />
            <ProtectedRoute breadcrumb={SOTbreadCrumbsData.ProvidersData} user={user} path="/sourcedocs/providersData"
              component={ProvidersDataPage} />
            <ProtectedRoute breadcrumb={SOTbreadCrumbsData.disclaimersNeedToPublish} user={user} path="/sourcedocs/disclaimersNeedToPublish"
              component={PublishGrid} />
            <ProtectedRoute breadcrumb={SOTbreadCrumbsData.newDisclaimergen} user={user} path="/sourcedocs/disclaimers_gen/:action/:id?"
              component={DisclaimerGenForm} />
            <ProtectedRoute breadcrumb={SOTbreadCrumbsData.newDisclaimer} user={user} path="/sourcedocs/disclaimers/:action/:id?"
              component={DisclaimerForm} />
            <ProtectedRoute breadcrumb={SOTbreadCrumbsData.disclaimers} user={user} path="/sourcedocs/disclaimers"
              component={DisclaimersPage} />
            <ProtectedRoute breadcrumb={SOTbreadCrumbsData.bulletPositioning} user={user} path="/sourcedocs/bulletPositioning"
              component={BulletPositioning} />

            {/* Administration Routes */}
            <ParentProtectedRoute user={user} path="/admin" component={Administration} />

            {/* Seed Lists*/}
            <ProtectedRoute breadcrumb={SEEDLISTSbreadCrumbsData.uploadSeedList} user={user} path="/seedLists/upload" component={UploadSeedList}/>
            <ProtectedRoute breadcrumb={SEEDLISTSbreadCrumbsData.editSeedList} user={user} path="/seedLists/edit/seedDetail/:id?" component={SeedDetailForm}/>
            <ProtectedRoute breadcrumb={SEEDLISTSbreadCrumbsData.newSeedList} user={user} path="/seedLists/:action/:id?" component={SeedListForm}/>
            <ProtectedRoute breadcrumb={SEEDLISTSbreadCrumbsData.seedLists} user={user} path="/seedLists" component={SeedListsPage}/>

            {/* Default Route */}
            <Route path="*" component={() => "404 NOT FOUND"} />
          </Switch>
        </Suspense>
      </SnackbarContextProvider>
    </ThemeProvider>
  );
}

export default withRouter(withClearCache(App));
