import { filterOpMap } from "components/sourcedocs/TestDialog/TestDialog.constants";
import { cloneDeep } from "lodash";

export const download = (response, fileName) => {
  const url = window.URL.createObjectURL(
    new Blob([response.data], { type: "application/json" })
  );
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  window.URL.revokeObjectURL(url);
};

export const toTitleCase = (str) => {
  // As we are getting errors in two format Array of string & string 
  if (Array.isArray(str)) {
    return str[0].split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.substr(1))
      .join(' ')
  }
  return str.split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.substr(1))
    .join(' ')
};
export const errorHandler = async (error, setSnackBarConfig = false) => {
  let errorMessage = "Something went wrong!";
  if (error.response && error.response.status === 400 && error.response.data) {
    errorMessage = Object.values(error.response.data)[0];
  }
  // 405 Specific for Grid report - error response as blob-type
  if (error.response && (error.response.status === 405) && error.response.data instanceof Blob) {
    errorMessage = JSON.parse(await error.response.data?.text());
    errorMessage = Object.values(errorMessage)[0];
  }
  !setSnackBarConfig ? alert(errorMessage) : setSnackBarConfig({ open: true, message: errorMessage, severity: 'error' });
};
export const toId = (title) => {
  return title.replace(/\s+/g, "");
};
export const compareStr = (str1, str2) => {
  if (str1 < str2) {
    return -1;
  }
  if (str1 > str2) {
    return 1;
  }
  return 0;
};
/**
 * list => res.data, {key: 'text', value: 'displaytext'}]
 * @param list
 * @param keys keys default to [{key: 'id', value: 'lookupvalue'}, {key: 'text', value: 'displaytext'}]
 * @returns {*}
 */
export const getDropdownOptionsFrom = (
  list,
  keys = [{ key: 'id', value: 'lookupvalue' }, { key: 'text', value: 'displaytext' }]) => {
  return list
    .filter((item) => item[keys[0].value] && item[keys[1].value])
    .map((item) => ({
      [keys[0].key]: item[keys[0].value],
      [keys[1].key]: item[keys[1].value],
    }))
    .sort((a, b) => compareStr(a[keys[1].key], b[keys[1].key]));
};
export const getDropdownOptionsFromObj = (list, keys) => {
  let resultList = [];
  Object.keys(list).forEach(d => {
    resultList.push({ [keys[0].key]: d.toString(), [keys[1].key]: list[d] })
  })
  return resultList;
};

export const getDropdownOptionsFromArray = (list, keys = [{ key: 'label' }, { key: 'value' }], name = undefined) => {
  let resultList = [];
  list.forEach(d => {
    const obj = {
      [keys[0].key]: d.toString(), [keys[1].key]: d.toString()
    }
    if (name) obj[name] = d;
    resultList.push(obj)
  })
  return resultList;
};

export const getDropdownOptionsFromArrayOfObj = (list, keys = [{ key: 'label' }, { key: 'value' }], name = undefined) => {
  let resultList = [];
  list.forEach(d => {
    d[name] && resultList.push({ ...d, [keys[0].key]: d[name].toString(), [keys[1].key]: d[name].toString() })
  })
  return resultList;
};


/**
 * This method can be used to build body for api calls.
 * @param fieldCriterias
 * @param filter
 * @return {{[p: string]: *}}
 * eg : buildFilter([{field: 'category', value: 'General', 'op':'contains'},{field: 'lookupname', value: 'PlanSubBrand',"op": "contains"}])
 */
export const buildFilter = (fieldCriterias, filter = 'filter') => {
  return {
    [filter]: fieldCriterias.map(fieldCriteria => {
      return {
        field: fieldCriteria.field,
        criteria: [
          {
            op: fieldCriteria.op || "is",
            value: fieldCriteria.value,
          }]
      }
    })
  }
};

const getCriteriaForContainsWithMultipleValues = (valArray, operator) => {
  let i = 0;
  const criteriaArray = [];
  while (i < valArray?.length) {
    criteriaArray.push({
      op: filterOpMap[operator],
      value: valArray[i]
    });
    if (i < valArray?.length - 1) {
      criteriaArray.push({
        op: operator === "contains" ? "or" : "and"
      })
    }
    i++;
  }
  return criteriaArray;
};

export const transformExpressionToFilters = (expression, isRoot = false) => {
  if (expression?.type === "group") {
    let groupExpr = expression?.exprs?.map(expr => transformExpressionToFilters(expr));
    let i = 0;
    while (i < groupExpr.length - 1) {
      groupExpr.splice(i + 1, 0, {
        op: expression?.op
      })
      i = i + 2;
    }
    groupExpr = isRoot ? groupExpr : {
      group: groupExpr
    }
    return groupExpr;
  } else {
    return {
      field: expression?.mbrRelative?.toLowerCase(),
      criteria: ((expression?.op === "contains" || expression?.op === "doesnotcontain") && expression?.val?.split(',')?.length > 1) ?
        getCriteriaForContainsWithMultipleValues(expression?.val?.split(',')?.map(c => c?.replace('//', ',')), expression?.op)
        : [{
          op: filterOpMap[expression?.op] || expression?.op,
          value: ['in', 'not in'].indexOf(filterOpMap[expression?.op]) !== -1 ? expression?.val?.split(',')?.map(c => c?.replace('//', ',')) : expression?.val?.replace('//', ',')
        }]
    }
  }
};

export const getValidFiltersFromStorage = (filter = []) => {
  if (filter?.filters?.length > 0) {
    filter.filters.forEach(ff => {
      if (ff?.filters?.length > 0) {
        ff.filters.forEach(f => {
          const temp = cloneDeep(f);
          if (["publishdatetime", "modifydatetime", "createdatetime", "material_due_date", "insertion_date"].indexOf(temp.field) !== -1) {
            f.value = temp.value ? new Date(temp.value) : null;
          }
        })
      }
    });
  }
  return filter;
};

// https://stackoverflow.com/questions/7616461/generate-a-hash-from-string-in-javascript
export const getHashCode = (str) => {
  let hash = 0, i, chr;
  if (str.length === 0) return hash;
  for (i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i);
    hash = ((hash << 5) - hash) + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};

/**
 * This method can be used to retrieve channels as per current channelType in localStorage.
 * @param data
 * @return {[{[p: string]: *}]}
 */
export const getChannelsByDepartment = (data) => {
  const channelType = getOriginalChannelType();
  const channelsByDepartment = data?.filter(channel => {
    if (channelType === "Non-Core Provider") {
      return channel?.shortname === "ADM" || channel?.channelname === "Acquisition Direct Mail";
    } else if (channelType === "Acquisition") {
      // ADM returns department as Non-Core Provider but is part of Acquisition as well
      return channel?.department === channelType || channel?.shortname === "ADM" || channel?.channelname === "Acquisition Direct Mail";
    } else {
      return channel?.department === channelType;
    }
  });
  return channelsByDepartment;
};

export const getOriginalChannelType = () => {
  const channelType = localStorage.getItem("channelType");
  return channelType === "Secondary Retention" ? "Retention" : channelType;
};
