import { regEx } from "../RuleContainer/SelectionRule.contants";

import { varNamePrefix } from "./RuleConstants";

const parseText = (text, existingVars = []) => {
  const symbolvarMap = {};
  let maxVarSeq = 1;
  const existingVarsToConsider = existingVars?.filter((variable) =>
    variable.text.startsWith(varNamePrefix)
  );

  for (let variable of existingVarsToConsider) {
    symbolvarMap[variable.symbol] = variable.text;
    const attrs = variable.text.split(varNamePrefix);
    if (attrs.length > 1 && attrs[1] && parseInt(attrs[1]) >= maxVarSeq) {
      maxVarSeq = parseInt(attrs[1]) + 1;
    }
  }

  const variables = text
    .match(regEx)
    ?.map((val) => {
      return {
        text: val.replace(/<\*|\*>/g, ""),
        symbol: val,
      };
    })
    .filter((c) => Boolean(c.text.trim()))
    .map((variable) => {
      if (symbolvarMap[variable.symbol]) {
        variable.text = symbolvarMap[variable.symbol];
      } else {
        variable.text = `${varNamePrefix}${maxVarSeq}`;
        maxVarSeq++;
      }
      return variable;
    });

  return variables;
};

const isValidObj = (obj) => obj.pln && obj.mbr && obj.op;

const getValidExpr = (expr) => {
  if (expr.type === "group") {
    for (let exIndex = expr.exprs.length - 1; exIndex >= 0; exIndex--) {
      if (
        expr.exprs[exIndex].type === "expr" &&
        !isValidObj(expr.exprs[exIndex])
      ) {
        expr.exprs.splice(exIndex, 1);
      } else if (expr.exprs[exIndex].type === "group") {
        getValidExpr(expr.exprs[exIndex]);
        if (!expr.exprs[exIndex].exprs.length) {
            expr.exprs.splice(exIndex, 1);
        }
      } else {
        getValidExpr(expr.exprs[exIndex]);
      }
    }
  }
};

export { parseText, getValidExpr };
