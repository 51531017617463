import Form from './Form'

const NewSnap = () => {
  return (
    <div>
      <h4 style={{ color: "#8F8A92", fontSize: "14px", margin: 0 }}>
        SOURCE DOCUMENTS
      </h4>
      <h3 style={{ color: "#050307", fontSize: "29px", margin: 0 }}>
        Snap:New
      </h3>
      <div className="newgeography-wrapper">
        <div style={{ padding: 25 }}>
          <div>
            <Form />
          </div>
        </div>
      </div>
    </div>
  );
};
export default NewSnap;
