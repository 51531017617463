import { useState } from "react";

const useTest = () => {
  const [testDialog, setTestDialog] = useState(false);
  const [idSelected, setIdSelected] = useState([]);

  const resetAction = () => {
    setTestDialog(false);
  }
  return {testDialog, setTestDialog, idSelected, setIdSelected, resetAction};
};

export default useTest;
