export const ApiParams = {
  header: ["source documents", "snap"],
  moduleName: 'snap',
  methodName: "getAll",
  apiExtraParams: [],
  responseKey: "results",
  cookiesKey: 'snap',
  baseUrl: '/sourcedocs/snap'
};
export const CustomTableParams = {
  tableId: 'snap_id',
}
