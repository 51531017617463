export const SOTbreadCrumbsData = {
  geography: [
    { title: "Source Documents", to: "/sourcedocs/geography" },
    { title: "Geography" },
  ],
  newGeography: [
    { title: "Source Documents", to: "/sourcedocs/geography" },
    { title: "Geography", to: "/sourcedocs/geography" },
    { title: "New" },
  ],
  editGeography: [
    { title: "Source Documents", to: "/sourcedocs/geography" },
    { title: "Geography", to: "/sourcedocs/geography" },
    { title: "Edit" },
  ],
  uploadGeography: [
    { title: "Source Documents", to: "/sourcedocs/geography" },
    { title: "Geography:Upload" },
  ],
  creativeTypes: [{ title: "Source Documents" }, { title: "Creative Types" }],
  editCreativeTypes: [
    { title: "Source Documents" },
    { title: "Creative Types", to: "/sourcedocs/creativeTypes" },
    { title: "Edit" },
  ],
  newCreativeTypes: [
    { title: "Source Documents", to: "/sourcedocs/creativeTypes" },
    { title: "Creative Types", to: "/sourcedocs/creativeTypes" },
    { title: "New" },
  ],
  uploadCreativeTypes: [
    { title: "Source Documents", to: "/sourcedocs/creativeTypes" },
    { title: "Creative Types: Upload " },
  ],
  cloneCreativeTypes: [
    { title: "Source Documents", to: "/sourcedocs/creativeTypes" },
    { title: "Creative Types", to: "/sourcedocs/creativeTypes" },
    { title: "Clone" },
  ],
  snap: [
    { title: "Source Documents", to: "/sourcedocs/snap" },
    { title: "SNAP" },
  ],
  createSnap: [
    { title: "Source Documents", to: "/sourcedocs/snap" },
    { title: "SNAP", to: "/sourcedocs/snap" },
    { title: "New" },
  ],
  uploadSnap: [
    { title: "Source Documents", to: "/sourcedocs/snap" },
    { title: "SNAP", to: "/sourcedocs/snap" },
    { title: "Upload" },
  ],
  editSnap: [
    { title: "Source Documents", to: "/sourcedocs/snap" },
    { title: "SNAP", to: "/sourcedocs/snap" },
    { title: "Edit" },
  ],
  cloneSnap: [
    { title: "Source Documents", to: "/sourcedocs/snap" },
    { title: "SNAP", to: "/sourcedocs/snap" },
    { title: "Clone" },
  ],
  creativeData: [
    { title: "Source Documents", to: "/sourcedocs/creativeData" },
    { title: "Creative Data" },
  ],
  editCreativeData: [
    { title: "Source Documents", to: "/sourcedocs/creativeData" },
    { title: "Creative Data", to: "/sourcedocs/creativeData" },
    { title: "Edit" },
  ],
  newCreativeData: [
    { title: "Source Documents", to: "/sourcedocs/creativeData" },
    { title: "Creative Data", to: "/sourcedocs/creativeData" },
    { title: "New" },
  ],
  uploadCreativeData: [
    { title: "Source Documents", to: "/sourcedocs/creativeData" },
    { title: "Creative Data: Upload " },
  ],
  cloneCreativeData: [
    { title: "Source Documents", to: "/sourcedocs/creativeData" },
    { title: "Creative Data", to: "/sourcedocs/creativeData" },
    { title: "Clone" },
  ],
  productData: [
    { title: "Source Documents", to: "/sourcedocs/productData" },
    { title: "Product Data", to: "/sourcedocs/productData" },
  ],
  newProductData: [
    { title: "Source Documents", to: "/sourcedocs/productData" },
    { title: "Product Data", to: "/sourcedocs/productData" },
    { title: "New" },
  ],
  editProductData: [
    { title: "Source Documents", to: "/sourcedocs/productData" },
    { title: "Product Data", to: "/sourcedocs/productData" },
    { title: "Edit" },
  ],
  uploadProductData: [
    { title: "Source Documents", to: "/sourcedocs/productData" },
    { title: "Product Data", to: "/sourcedocs/productData" },
    { title: "Upload" },
  ],
  marketingBullets: [
    { title: "Source Documents", to: "/sourcedocs/marketingBullets" },
    { title: "Marketing Bullets" },
  ],
  editMarketingBullets: [
    { title: "Source Documents", to: "/sourcedocs/marketingBullets" },
    { title: "Marketing Bullets", to: "/sourcedocs/marketingBullets" },
    { title: "Edit" },
  ],
  newMarketingBullets: [
    { title: "Source Documents", to: "/sourcedocs/marketingBullets" },
    { title: "Marketing Bullets", to: "/sourcedocs/marketingBullets" },
    { title: null },
  ],
  cloneMarketingBullets: [
    { title: "Source Documents", to: "/sourcedocs/marketingBullets" },
    { title: "Marketing Bullets", to: "/sourcedocs/marketingBullets" },
    { title: "Clone" },
  ],
  marketingBulletsPublish: [
    { title: "Source Documents", to: "/sourcedocs/marketingBullets" },
    { title: "Marketing Bullets", to: "/sourcedocs/marketingBullets" },
    { title: "Publish" },
  ],
  view: [
    { title: "Source Documents", to: "/sourcedocs/marketingBullets" },
    { title: "Marketing Bullets", to: "/sourcedocs/marketingBullets" },
    { title: "View" },
  ],
  valProps:[
    {title:"Source Documents",to:"/sourcedocs/valProps"},
    {title:"Val Props",to:"/sourcedocs/valProps"}
  ],
 valPropsNeedToPublish: [
    { title: "Source Documents", to: "/sourcedocs/valProps" },
    { title: "Val Props", to: "/sourcedocs/valProps" },
    {
      title: "Publish",
    },
  ],
  ProvidersData:[
    { title: 'Source Documents', to: '/sourcedocs/providersData' },
    { title: 'Providers Data' }
  ],
  uploadProvidersData: [
    { title: "Source Documents", to: "/sourcedocs/providersData" },
    { title: "Providers Data", to: "/sourcedocs/providersData" },
    { title: "Upload" },
  ],
  createProvider: [
    { title: "Source Documents", to: "/sourcedocs/providersData" },
    { title: "Providers Data", to: "/sourcedocs/providersData" },
    { title: null },
  ],
  disclaimers: [
    { title: "Source Documents", to: "/sourcedocs/disclaimers" },
    { title: "Disclaimers", to: "/sourcedocs/disclaimers" },
  ],
  disclaimersNeedToPublish: [
    { title: "Source Documents", to: "/sourcedocs/disclaimers" },
    { title: "Disclaimers", to: "/sourcedocs/disclaimers" },
    {
      title: "Publish",
    },
  ],
  viewDisclaimer:[
    { title: "Source Documents", to: "/sourcedocs/disclaimers" },
    { title: "Disclaimers", to: "/sourcedocs/disclaimers" },
  ],
  newDisclaimer: [
    { title: "Source Documents", to: "/sourcedocs/disclaimers" },
    { title: "Disclaimers", to: "/sourcedocs/disclaimers" },
    { title: null},
  ],
  newValProps: [
    { title: "Source Documents", to: "/sourcedocs/ValProps" },
    { title: "ValProps", to: "/sourcedocs/ValProps" },
    { title: null },
  ],
  newDisclaimergen: [
    { title: "Source Documents", to: "/sourcedocs/disclaimers" },
    { title: "Disclaimers", to: "/sourcedocs/disclaimers" },
    { title: null},
  ],
  bulletPositioning: [
    { title: "Source Documents", to: "/sourcedocs/bulletPositioning" },
    { title: "Marketing Bullets Repositioning", to: "/sourcedocs/bulletPositioning" }
  ],
};
