import PropTypes from "prop-types";
import { Link } from "@mui/material"
import supportApi from "api/supportApi";
import { useDownload } from "./hooks";

const SampleDownload = (props) => {
  const {
    fileName,
    title
  } = props;

  const [handleDownload, isDownloading] = useDownload(
    supportApi,
    "downloadSampleFile",
    `${fileName}.xlsx`
  );

  return (
    <Link
      onClick={() => handleDownload(`${fileName}.xlsx`)}
      style={{cursor: "pointer"}}
    >{title}</Link>
  );
};

SampleDownload.propTypes = {
  fileName: PropTypes.string.isRequired,
  title: PropTypes.string
};

SampleDownload.defaultProps = {
  title: "Download Sample File"
};

export default SampleDownload;
