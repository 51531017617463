
import Add from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import { cloneDeep } from "lodash";

import RuleBlock from "./RuleBlock";
import { getDefaultBlock } from './RuleConstants'

const TextRuleContainer = ({ variableName, textRulesJSON, setTextRulesJSON, isDisabled }) => {

    const blocks = []
    const blockIndexMapping = {}
    for (let blockIndex in textRulesJSON.blocks) {
        if (textRulesJSON.blocks[blockIndex].variable === variableName) {
            blockIndexMapping[blocks.length] = blockIndex
            blocks.push(textRulesJSON.blocks[blockIndex])
        }
    }

    const addBlock = () => {
        let tempTextRulesJSON = { ...textRulesJSON }
        tempTextRulesJSON.blocks.push(
            cloneDeep({ ...getDefaultBlock(), variable: variableName })
        )
        setTextRulesJSON(tempTextRulesJSON)
    }

    const deleteBlock = (index) => {
        let tempTextRulesJSON = { ...textRulesJSON }
        tempTextRulesJSON.blocks.splice(index, 1)
        setTextRulesJSON(tempTextRulesJSON)
    }

    const handleBlockChange = (index, block) => {
        let tempRextRulesJSON = { ...textRulesJSON }
        tempRextRulesJSON.blocks[index] = block;
        setTextRulesJSON(tempRextRulesJSON)
    }

    const handlePrecedenceChange = (precedenceDirection, index) => {
        let tempRextRulesJSON = { ...textRulesJSON }
        let indexToBeswapped;
        const firstBlock = blocks[index]
        if (precedenceDirection === "up") {
            indexToBeswapped = index - 1
        }
        else {
            indexToBeswapped = index + 1
        }
        const secondBlock = blocks[indexToBeswapped]
        tempRextRulesJSON.blocks[blockIndexMapping[index]] = secondBlock
        tempRextRulesJSON.blocks[blockIndexMapping[indexToBeswapped]] = firstBlock
        setTextRulesJSON(tempRextRulesJSON)
    }

    return (
        <>
            {/* --------- Block --------- */}
            {
                blocks.map((block, index) => {
                    return (
                        < RuleBlock
                            canDelete={index !== 0}
                            canSetPredenceDown={index !== blocks.length - 1}
                            variableName={variableName}
                            initialBlockValue={block}
                            onBlockChange={handleBlockChange}
                            onDelete={() => deleteBlock(blockIndexMapping[index])}
                            onChange={(updatedBlock) => handleBlockChange(blockIndexMapping[index], updatedBlock)}
                            onPrecedenceChange={(precedenceDirection) => handlePrecedenceChange(precedenceDirection, index)}
                            isDisabled={isDisabled}
                        />
                    )
                })
            }
            <Button variant="outlined" startIcon={<Add />} size="large" onClick={addBlock} data-testid={"button-click"} disabled={isDisabled}>Add A Block</Button>
        </>
    )
}

export default TextRuleContainer;