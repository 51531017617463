import { Prompt } from "react-router-dom";

const PromptAlert = (props) => {
  const {
    isBlocking
  } = props;

  return (
    <div>
      <Prompt
        when={isBlocking}
        message={() => `You might have unsaved changes, are you sure you want to leave? `}
      />
    </div>
  );
};
export default PromptAlert;
