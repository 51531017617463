import CurrentUserService from "../services/current-user";
import { PRIVILEGES } from "constant";

const reportPrivileges = [
  PRIVILEGES.ReportViewer,
  PRIVILEGES.MBMReportViewer,
  PRIVILEGES.SOTReportViewer,
  PRIVILEGES.GTMReportViewer,
  PRIVILEGES.LeadZipReportViewer,
];

export function hasAccessByPrivilegeName(privilegeName) {
  const currentUser = CurrentUserService.getUser();
  let hasAccess = false;
  if (currentUser) {
    privilegeName?.forEach((privilegeName) => {
      if (currentUser && currentUser.Privileges?.includes(privilegeName)) {
        hasAccess = true;
      }
    });
  }
  return hasAccess;
}

/** check if user has only reporting priviledges */
export function isReportViewer() {
  const currentUser = CurrentUserService.getUser();
  if ( currentUser ) 
  {
    return currentUser?.Privileges.every(value => reportPrivileges.includes(value))
  }
  return false;
}

/** funtion to decide redirection path for Reporting viewers based on sub priviledge */
export function pathRedirectionForReportViewers() {
    let path = '';
    if (hasAccessByPrivilegeName([PRIVILEGES.ReportViewer]))
          path = "/reports/sot";
        else if (hasAccessByPrivilegeName([PRIVILEGES.MBMReportViewer]))
          path = "/reports/mbm";
        else if (hasAccessByPrivilegeName([PRIVILEGES.SOTReportViewer]))
          path = "/reports/sot";
        else if (hasAccessByPrivilegeName([PRIVILEGES.GTMReportViewer]))
          path = "/reports/gtm";
        else path = "/reports/leadZip";
    return path;
}

export const loadingPanel = () => (
  <div className="k-loading-mask">
    <span className="k-loading-text">Loading</span>
    <div className="k-loading-image"></div>
    <div className="k-loading-color"></div>
  </div>
);
