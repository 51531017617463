import { useState, useContext, useRef, useEffect } from "react";

import * as yup from "yup";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useHistory, useParams } from "react-router-dom";

import sotApi from "api/sotApi";
import InfoDialog from "components/common/Dialogs/InfoDialog";
import PromptAlert from "components/PromptAlert";
import LoadingPanel from "components/common/TabPanel/LoadingPanel";
import RuleContainer from "components/sourcedocs/RuleContainer/RuleContainer";
import useTextRuleJson from "components/sourcedocs/Rules/useTextRuleJson";
import BaseValPropsForm from "./BaseValPropsForm";
import { parseText } from "components/sourcedocs/Rules/utils";
import { SnackbarContext } from "context/SnackbarContext";
import { useSelectionRule } from "components/sourcedocs/MarketingBullets/hooks/useSelectionRule";
import { RulesContextProvider } from "../../Rules/context/RulesContext";
import { errorHandler } from "components/common/utils";

const schema = yup.object().shape({
  refid: yup
    .string()
    .required("Ref. id is required.")
    .max(7, "Ref ID cannot be more than 7 characters"),
  title: yup
    .string()
    .required("Title is required")
    .max(100, "Title cannot be more than 100 characters"),
  copytext: yup.string().required("Copy Text is required"),
  copytext_add: yup
    .string()
    .max(50, "Copy Text Append cannot be more than 50 characters"),
  copytype: yup.string().test('copytype', "Copy Type is required", function (value) {
    return value !== "Select Type"

  }).required("CopyType is required"),

  disclaimerrefid: yup.string(),
});

const ValPropsForm = () => {
  const { id, action } = useParams();
  const copyblock_id = useRef(id);
  const [isLoading, setLoading] = useState(false);
  const [isBlocking, setIsBlocking] = useState(false);
  const [cbObj, setcbObj] = useState(null)
  const [refId, setRefId] = useState("")
  const [openDialog, setOpenDialog] = useState(false);
  const [displayPopUpErr, setDisplayPopUpErr] = useState([]);

  const {
    selectionRuleJSON,
    setSelectionRuleJSON,
    baseSelectionRuleJSON,
    setBaseSelectionRuleJSON,
    getSelectionRuleToSend
  } = useSelectionRule();

  const displayCreativeTypesTab = ["details", "edit"];
  const { handleSubmit, control, watch, setValue, setError, formState
  } = useForm({
    mode: "all",
    resolver: yupResolver(schema),
    defaultValues: {
      refid: "",
      title: "",
      copytype: "Select Type",
      copytext: "",
      disclaimerrefid: "None",
      selectionrule_json: "",
      copytextrule_json: "",
      copytext_add: "",
    },
  });


  const watchRefId = watch("refid");
  const history = useHistory();
  const { setSnackBarConfig } = useContext(SnackbarContext);
  const { textRulesJSON,
    setTextRulesJSON,
    handleVariableChange } = useTextRuleJson()

  useEffect(async () => {

    if (copyblock_id.current) {
      setLoading(true);
      let res;
      try {
        if (action === "clone") {
          res = await sotApi.valProps.clone(id);
          res = await sotApi.valProps.get(res.data?.copyblock_id);
        } else if (action === "details") {
          res = await sotApi.valprops_pub.get(copyblock_id.current);
        } else {
          res = await sotApi.valProps.get(copyblock_id.current);
        }
        setcbObj(res.data);
        copyblock_id.current = res.data.copyblockid;
        setRefId(res?.data?.refid)
        const copyBlockData = {
          refid: res?.data?.refid,
          title: res?.data?.title,
          copytype: res?.data?.copytype,
          copytext: res?.data?.copytext,
          selectionrule_json: res?.data?.selectionrule_json,
          copytextrule_json: res?.data?.copytextrule_json,
          disclaimerrefid: res?.data?.disclaimerrefid ? res?.data?.disclaimerrefid : "None",
          copytext_add: res?.data?.copytext_add,
        };
        for (const field in schema.fields) {
          setValue(field, copyBlockData[field]);
        }
        setIsBlocking(false);

        try {
          if (typeof res.data["selectionrule_json"] === "string") {
            res.data["selectionrule_json"] = JSON.parse(res.data["selectionrule_json"])
          }
          if (res.data["selectionrule_json"].base_operator) {
            let tempBaseSelectionRuleJSON = { ...baseSelectionRuleJSON }
            for (const field in baseSelectionRuleJSON) {
              tempBaseSelectionRuleJSON[field] = res.data["selectionrule_json"][field]
            }
            setBaseSelectionRuleJSON(
              tempBaseSelectionRuleJSON
            )
            const exprsIndex = res.data["selectionrule_json"].exprs.findIndex(exprs => !exprs.baseRules)
            if (exprsIndex >= 0) {
              setSelectionRuleJSON({ ...res.data["selectionrule_json"].exprs[exprsIndex] })
            }
          }
          else {
            setSelectionRuleJSON({ ...selectionRuleJSON, ...res.data["selectionrule_json"] })
          }
        } catch (e) {

        }

        if (typeof (res.data["copytextrule_json"]) === 'string') {
          res.data["copytextrule_json"] = JSON.parse(res.data["copytextrule_json"])
        }
        setTextRulesJSON(res.data["copytextrule_json"])

      } catch (error) {
        setLoading(false);

        for (const field in error?.response?.data) {
          setError(field, {
            type: "custom",
            message: error.response.data[field][0],
          })
        }
      } finally {
        setLoading(false);
      }
    }
  }, []);

  const handleSelectionRuleJSONChange = (obj) => {
    setSelectionRuleJSON(obj);
    setIsBlocking(true);
  };
  const handleBaseSelectionRuleJSONChange = (obj) => {
    setBaseSelectionRuleJSON(obj)
    setIsBlocking(true)
  }
  const handleTextRulesJSONChange = (obj) => {
    setTextRulesJSON(obj);
    setIsBlocking(isBlocking);
  };

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      { action !== "details" && setIsBlocking(true) };
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const onSubmitValPropsData = async (valPropsJSON) => {
    setLoading(true)
    try {
      if (copyblock_id.current) {
        await sotApi.valProps.update(copyblock_id.current, valPropsJSON)
      }
      else await sotApi.valProps.add(valPropsJSON);
      setSnackBarConfig({
        open: true,
        message: "Saved successfully.",
        severity: "success",
      });
      setIsBlocking(false);
      history.push({
        pathname: "/sourcedocs/valProps",
        state: {
          importSuccess: true,
        },
      });
    } catch (error) {
      const errorData = error?.response?.data;
      if (error?.response?.status === 400) {
        for (const field in errorData) {
          setError(field, {
            type: "custom",
            message: error.response.data[field][0],
          })
        }
      }
      else {
        setDisplayPopUpErr("Something went wrong!");
        setOpenDialog(true);
      }
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = (data) => {
    const selectionRuleToSend = getSelectionRuleToSend()
    if (data.disclaimerrefid === "None") {
      data = { ...data, disclaimerrefid: "" }
    }
    const valPropJSON = {
      ...data,
      selectionrule_json: JSON.stringify(selectionRuleToSend),
      copytextrule_json: JSON.stringify(textRulesJSON)
    }
    onSubmitValPropsData(valPropJSON);
  };
  const handleTextBlur = (e) => {
    const text = e.target.value;
    handleVariableChange(parseText(text));
  };

  // Todo- move to custom hook
  const handleDeleteClone = async () => {
    try {
      setLoading(true);
      if (copyblock_id.current) {
        const payload = {
          copyblock_id_list: [copyblock_id?.current]
        }
        await sotApi.valProps.deleteAll(payload);
      }
      history.push({
        pathname: "/sourcedocs/valProps",
      });
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <RulesContextProvider>
        {isLoading && <LoadingPanel />}
        <form onSubmit={handleSubmit(onSubmit)} data-testid="form">
          <Stack direction="row" justifyContent="space-between">
            <div>
              <h4 style={{ color: "#8F8A92", fontSize: "14px", margin: 0 }}>
                VAL PROPS
              </h4>
              <h3
                style={{
                  color: "#050307",
                  fontSize: "29px",
                  margin: 0,
                  textTransform: "capitalize",
                }}
              >
                {action}
              </h3>
            </div>
          </Stack>
          <div className="new-wrapper">
            {action !== 'details' && <div style={{ padding: "30px 30px 0" }}>
              {action === 'clone' && <Button variant="outlined" onClick={handleDeleteClone} data-testid="button-click" style={{ marginRight: 20 }}>
                Cancel{" "}
              </Button>}
              <Button variant="contained" type="submit">
                Save Changes{" "}
              </Button>
            </div>}
            <div style={{ padding: 30 }}>
              <fieldset
                disabled={action === "details"}
                style={{
                  border: 'none',
                  margin: 0,
                  padding: 0
                }}
              >
                <BaseValPropsForm
                  action={action}
                  control={control}
                  onTextBlur={handleTextBlur}
                  dataObj={cbObj}
                  formState={formState}
                  watch={watch}
                />
              </fieldset>
            </div>
          </div>
          <div className="new-wrapper">
            <div style={{ marginTop: 30, padding: "10px" }}>
              <RuleContainer
                control={control}
                refText={watchRefId || "Reference ID"}
                textRulesJSON={textRulesJSON}
                setTextRulesJSON={handleTextRulesJSONChange}
                selectionRuleJSON={selectionRuleJSON}
                setSelectionRuleJSON={handleSelectionRuleJSONChange}
                moduleName="ValProps"
                hasCreativeTypeTab={displayCreativeTypesTab.includes(action)}
                copyblockid={refId}
                baseSelectionRuleJSON={baseSelectionRuleJSON}
                setBaseSelectionRuleJSON={handleBaseSelectionRuleJSONChange}
                isDisabled={action === "details"}
              />
            </div>
          </div>
          <PromptAlert isBlocking={isBlocking} />
          {openDialog && <InfoDialog
            openDialog={openDialog}
            setOpenDialog={setOpenDialog}
            messageInfo={{ title: 'Error', message: displayPopUpErr }}
          />}
        </form>
      </RulesContextProvider>
    </>
  );
};

export default ValPropsForm;
