export const SEEDLISTSbreadCrumbsData = {
  seedLists: [
    {
      title: "Seed List",
      to: "/seedLists",
    },
    {
      title: "Seed Portal",
      to: "/seedLists",
    },
  ],
  newSeedList: [
    {
      title: "Seed List",
      to: "/seedLists",
    },
    {
      title: "Seed Portal",
      to: "/seedLists",
    },
    {
      title: null,
    },
  ],
  editSeedList: [
    {
      title: "Seed List",
      to: "/seedLists",
    },
    {
      title: "Seed Portal",
      to: "/seedLists",
    },
    {
      title: "edit",
    },
  ],
  cloneSeedList: [
    {
      title: "Seed List",
      to: "/seedLists",
    },
    {
      title: "Seed Portal",
      to: "/seedLists",
    },
    {
      title: "Clone",
    },
  ],
  uploadSeedList: [
    {
      title: "Seed List",
      to: "/seedLists",
    },
    {
      title: "Upload",
    },
  ],
};
