import { DropDownList, MultiSelect } from "@progress/kendo-react-dropdowns";
import {
  InputLabel,
  TextField,
  Tabs,
  Tab,
  Box,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";

const CustomTabs = styled(Tabs)({
  borderBottom: "1px solid #e8e8e8",
  "& .MuiTabs-indicator": {
    backgroundColor: "#B3AEDA",
  },
});

const CustomTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    minWidth: 0,
    [theme.breakpoints.up("sm")]: {
      minWidth: 0,
    },
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    color: "#130B18",
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:hover": {
      color: "#754493",
      opacity: 1,
    },
    "&.Mui-selected": {
      color: "#130B18",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&.Mui-focusVisible": {
      backgroundColor: "#d1eaff",
    },
  })
);
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
const CustomMultiSelect = styled(MultiSelect)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .k-multiselect-wrap": {
    display: "flex",
    alignItems: "center",
    maxHeight: "6rem",
    minHeight: "2.5rem",
    overflow: "auto",
  },
  "& .k-multiselect-wrap .k-clear-value": {
    top: "50%",
  },
  "& .k-multiselect-wrap .k-reset": {
    maxHeight: "6rem",
  },
}));

const CustomDropdownSelect = styled(DropDownList)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .k-dropdown-wrap": {
    display: "flex",
    alignItems: "center",
    minHeight: "2.5rem",
  },
  "& .k-dropdown-wrap .k-clear-value": {
    top: "50%",
  },
}));

const CustomTextField = styled(TextField)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiOutlinedInput-root": {
    display: "flex",
    alignItems: "center",
    maxHeight: "6rem",
    minHeight: "2.5rem",
    overflow: "auto",
    "& input": {
      padding: "9px 10px",
    },
    "& .Mui-disabled": {
      backgroundColor: "#f6f6f6",
    },
  },
}));

const CustomInputLabel = styled(InputLabel)(({}) => ({
  fontSize: "1.4rem",
  fontWeight: "400",
  color: "#000",
}));
const CustomInputDis = styled(InputLabel)(({}) => ({
  fontSize: "1.4rem",
  color: "#000",
}));


export {
  CustomTabs,
  CustomTab,
  CustomMultiSelect,
  CustomInputLabel,
  CustomTextField,
  CustomDropdownSelect,
  CustomInputDis,
  TabPanel,
};
