import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React from "react";
import { WARM_WHITE } from "../../../theme/colors";

/** A common tab-panel component that can be used anywhere across the project with "Tabs" */
const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${value}-${index}`}
      aria-labelledby={`tab-${index}`}
      sx={{ backgroundColor: WARM_WHITE, padding: '0.5rem' }}
      {...other}
    >
      {other.destroyable ? value === index && (
        <Typography>{children}</Typography>
      ) : <Typography sx={{ display: value === index ? "inherit" : "none" }}>{children}</Typography>
      }
    </Box>
  );
}

export default TabPanel
