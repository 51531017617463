import { useEffect, useState } from "react";

import { compareStr } from "components/common/utils";
import { buildFilter, errorHandler } from "components/common/utils";

const useGetDropdowns = (api) => {
  const [disclaimerRefIDOptions, setDisclaimerRefIDOptions] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [segmentOptions, setSegmentOptions] = useState([]);
  const [typeOptions, setTypeOptions] = useState([]);
  const [levelOptions, setLevelOptions] = useState([]);
  const [copyBlocksTypeOptions, setCopyBlocksTypeOptions] = useState([]);
  const [isLoading, setLoading] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    const loadDisclaimerPromise = api.loadDisclaimerRefId();
    const loadCategoryPromise = api.getGroupTypeForLookup(buildFilter(
      [{ field: "category", value: "MarketingBullets" }, { field: "lookupname", value: "Category" }]));
    const loadSegmentPromise = api.getGroupTypeForLookup(buildFilter(
      [{ field: "category", value: "MarketingBullets" }, { field: "lookupname", value: "ChannelSegment" }]));
    const loadTypePromise = api.getGroupTypeForLookup(buildFilter(
      [{ field: "category", value: "MarketingBullets" }, { field: "lookupname", value: "Type" }]));
    const loadLevelPromise = api.getGroupTypeForLookup(buildFilter(
      [{ field: "category", value: "MarketingBullets" }, { field: "lookupname", value: "Level" }]));
    const loadCopyBlocksTypePromise = api.getGroupTypeForLookup(buildFilter([{ field: "category", value: "CopyBlocks" }, { field: "lookupname", value: "CopyType" }]))
    let results;
    try {
      setLoading(true)
      results = await Promise.all([loadDisclaimerPromise, loadCategoryPromise, loadSegmentPromise, loadTypePromise, loadLevelPromise, loadCopyBlocksTypePromise]);
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoading(false);
    }
    if (results) {
      results[0].data.results = results[0]?.data?.results?.map(item => ({
        id: item.refid,
        text: item.disclaimername,
        disclaimertext: item.disclaimertext
      })).sort((a, b) => compareStr(a.text, b.text));
     results[0].data.results.unshift({
      id:"None",
      text:"None",
      disclaimertext:"None"
     })
      setDisclaimerRefIDOptions(results[0]?.data?.results)

      setCategoryOptions(results[1]?.data.map(item => ({
        id: item.lookupvalue,
        text: item.displaytext
      })).sort((a, b) => compareStr(a.text, b.text)));

      results[2].data = results[2]?.data.map(item => ({
        id: item.lookupvalue,
        text: item.displaytext
      })).sort((a, b) => compareStr(a.text, b.text))
      setSegmentOptions(results[2].data);

      setTypeOptions(results[3]?.data.map(item => ({
        id: item.lookupvalue,
        text: item.displaytext
      })).sort((a, b) => compareStr(a.text, b.text)));

      setLevelOptions(results[4]?.data.map(item => ({
        id: item.lookupvalue,
        text: item.displaytext
      })).sort((a, b) => compareStr(a.text, b.text)));

      results[5].data = (results[5]?.data.map(item =>({
        id: item.lookupvalue,
        text: item.displaytext
      })).sort((a, b) => compareStr(a.text, b.text)));
      setCopyBlocksTypeOptions(results[5].data)
    }
  }, []);


  return {
    disclaimerRefIDOptions,
    categoryOptions,
    segmentOptions,
    typeOptions,
    levelOptions,
    copyBlocksTypeOptions,
    isLoading
  };
}
export default useGetDropdowns;
