import React, {useState, useRef, useContext} from "react";
import {Button} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import {useHistory} from "react-router-dom";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import api from "Api";
import { SnackbarContext } from "context/SnackbarContext";
import SampleDownload from "components/common/SampleDownload";

const UploadCreativeTypes = () => {
  const [isUploading, setIsUploading] = useState(false);
  const uploadInput = useRef();
  const [preValidationError, setpreValidationError] = useState({});
  const [exceptionError, setexceptionError] = useState("Some Error Occured!");
  const [openDialog, setOpenDialog] = useState(false);
  const [labels, setLabels] = useState([]);
  const {setSnackBarConfig} = useContext(SnackbarContext); 
  const history = useHistory();

  const changeHandler = async (e) => {
    setIsUploading(true);
    setpreValidationError({});
    const files = e.target.files;
    setLabels(Object.values(files).map(file => file.name));
    const formData = new FormData();
    Object.values(files).forEach(file => formData.append("file", file))
    try {
      const res = await api.uploadCreativeTypes(formData);
      setSnackBarConfig({open: true, message: 'File(s) uploaded successfully.', severity: 'success'})
      history.push({
        pathname: "/sourcedocs/creativeTypes",
        state: {
          importSuccess: true,
        },
      });
    } catch (error) {
      if (error?.response?.status === 400) {
        if (error.response?.data?.error)
          setexceptionError(error.response.data?.error);
        else if (error.response?.data?.Error)
          setexceptionError(error.response.data?.Error);
        else if (error.response?.data?.creativetype && error.response?.data?.creativetypeBlock)
          setpreValidationError(error.response?.data);
        else
          setexceptionError("Something went wrong!");
      } else {
        setexceptionError("Something went wrong!");
      }
      setOpenDialog(true);
    }
  };
  const closeErrorDialoge = ()=>{
    setOpenDialog(false);
    setIsUploading(false);
    setLabels([]);
    setpreValidationError({});
  }
  return (
    <>
      <h4 style={{color: "#8F8A92", fontSize: "14px", margin: 0}}>
        SOURCE DOCUMENTS
      </h4>
      <h3 style={{color: "#050307", fontSize: "29px", margin: 0}}>
        Creative Types: Upload
      </h3>
      <div className="dnd-wrapper">
        {isUploading ? (
          <>
            <p className="uploading">Uploading....</p>
            {labels.map(label => <label>{label}</label>)}
          </>
        ) : (
          <>
            <div className="dnd-title"></div>
            <input
              id="upload-input"
              type="file"
              accept=".xls,.xlsx"
              className="input-hide"
              ref={uploadInput}
              onChange={changeHandler}
              onClick={(e) => {
                e.target.value = null;
              }}
              multiple
            />
            <button
              style={{width: "350px", height: "100px"}}
              className="uploadfile-button"
              id="uploadfile-button"
              onClick={(e) => {
                uploadInput.current.click();
              }}
            >
              <FileUploadOutlinedIcon/>
              <label style={{fontSize: "20px"}}> Upload Two Files</label>
            </button>
            <div style={{marginTop: '50px'}}>
              <SampleDownload fileName="CreativeTypesCopyBlocksImport_sample" title="Download Sample File1" />
              <div style={{marginTop: '20px'}}>
                <SampleDownload fileName="CreativeTypesImport_sample" title="Download Sample File2" />
              </div>
            </div>
          </>
        )}

        <Dialog
          open={openDialog}
          onClose={closeErrorDialoge}
          className="importTfn-dialog"
          PaperProps={{
            sx: {
              position: "fixed",
              top: 0,
              left: "auto",
              m: 0,
              padding: "10px",
              width: 600,
            },
          }}
        >
          <div className="dialog-import-tfn">
            <div className="content">
              <h2>
                <InfoRoundedIcon/> Something went wrong
              </h2>
              {Object.keys(preValidationError).length > 0 ? (
                <>
                  {typeof preValidationError.creativetype === "string" &&
                  <li><b>Creative Type:</b> {preValidationError.creativetype}</li>}
                  {typeof preValidationError.creativetypeBlock === "string" &&
                  <li><b>Copy Block:</b> {preValidationError.creativetypeBlock}</li>}
                  {typeof preValidationError.creativetype !== "string" && (
                    <>
                      <ul><b>Creative Type:</b>
                        {preValidationError.creativetype.map(error => <li>{error}</li>)}
                      </ul>
                    </>
                  )}
                  {typeof preValidationError.creativetypeBlock !== "string" && (
                    <>
                      <ul><b>Copy Block:</b>
                        {preValidationError.creativetypeBlock.map(error => <li>{error}</li>)}
                      </ul>
                    </>
                  )}
                </>
              ) : (
                <li>{"Something went wrong: " + exceptionError}</li>
              )}
            </div>
          </div>
          <DialogActions>
            <Button
              className="import-dialog-button"
              variant="default"
              onClick={closeErrorDialoge}
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};
export default UploadCreativeTypes;
