import { getOriginalChannelType } from "components/common/utils";
import { axiosInstance } from "../axios";

const CurrentUserService = {
  _user: null,
  getUser() {
    axiosInstance.defaults.headers["Channel-Type"] = getOriginalChannelType();
    return this._user;
  },
  setUser(usr) {
    this._user = {
      Privileges: usr?.Privileges.map((item) => item.toLowerCase()),
      Profile: usr?.Profile,
    };
  },
};

export default CurrentUserService;

// Privileges = [
//     "ApprovalManager",
//     "MbmPublisher",
//     "JobManager",
//     "TFNMarketing",
//     "SourceDocManager",
//     "TFNManager",
//     "LookupManager",
//     "TFNTelesales",
//     "ToolTipsAdmin",
//     "TFNTester",
//     "MbmPublishScheduleAdmin",
//     "TFNRequestorUHHS",
//     "ReportViewer",
//     "MbmEditor",
//     "GroupAdmin",
//     "Approver",
//     "NotifyTypesAdmin",
//     "RuleManager",
//     "MbmManager",
//     "TFNRequestor",
//     "AppServiceAdmin",
//     "DigitalContentAdmin",
//     "CreativeHitsManager",
//     "SnippetManager",
//     "SecurityAdmin",
//     "ApprovalAdmin",
//     "TFNCallCenter",
//     "TestAdmin",
//     "TFNReporter",
//     "SeedListAdmin"
// ]
