import React from "react"

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import api from "Api";
import styles from '../../sourcedocs.module.scss';

const CreativeTypeForm = (props) => {
  const [refId, setRefId] = React.useState(props?.object?.refid)
  const [creativetypename, setCreativeTypeName] = React.useState(props?.object?.creativetypename)
  const [copyblockcount, setCopyBlockCount] = React.useState(props?.object?.copyblockcount)
  const [updatedObject, setUpdatedObject] = React.useState()
  const [ctNameError, setCTNameError] = React.useState();
  const [cbError, setCBError] = React.useState()
  const [error, setError] = React.useState(props.error)
  const [largeRefId, setLargeRefId] = React.useState()
  const [isBlocking, setBlocking] = React.useState(false)

  React.useEffect(() => {
    setError(props.error)
  }, [props.error])
  React.useEffect(() => {
    if (typeof copyblockcount != "undefined" || typeof creativetypename != "undefined") {
      if (copyblockcount < 0 || copyblockcount > 6) {
        setCBError('Copy Block Count value should be between 0...6')
      }
      if (copyblockcount?.length === 0 || typeof copyblockcount === "undefined") {
        setCBError('Copy Block Count is required')
      }
      if (typeof creativetypename === "undefined" || creativetypename?.length === 0) {
        setCTNameError('Creative Type Name is required')
      }
    }
    setUpdatedObject({
      refid: refId, creativetypename: creativetypename,
      copyblockcount: copyblockcount, creativetypeid: props?.object?.creativetypeid
    })
  }, [creativetypename, copyblockcount, refId])
  React.useEffect(() => {
    props.handleDataFromForm({...updatedObject})
    props.blockingStatus(isBlocking)
  }, [updatedObject, isBlocking])
  React.useEffect(async () => {
    setRefId(props?.object?.refid)
    setCreativeTypeName(props?.object?.creativetypename)
    setCopyBlockCount(props?.object?.copyblockcount)
    try {
      const res = await api.getRefId();
      setLargeRefId(res.data.refId)
    } catch (e) {
    }
  }, [props?.object])
  return (<Box className={styles.sourceDocsContainer}>
    <Grid container spacing={2}>
      <Grid item sm={12} md={6}>
        <div>
          <label className={`required font ${styles.labelText}`}>Ref.ID</label>
        </div>
        <div>
          <input
            className={styles.inputText} type="text"
            id="refid" value={refId} disabled={props.disabled}
            data-testid="button-change"
            onChange={event => {
              setRefId(event.target.value)
              setBlocking(true)
            }}
          />
        </div>
      </Grid>
      <Grid item sm={12} md={6}>
        <div><label className={`required font ${styles.labelText}`}>Creative Type Name</label></div>
        <div>
          <input
            className={styles.inputText} id="creativetypename" type="text" name="creativetypename"
            value={creativetypename}
            data-testid="button-change2"
            onChange={e => {
              e.preventDefault();
              setCTNameError('')
              if (e.target.value.length > 100) {
                setCTNameError('Creative Type Name cannot be more than 100 characters')
              }
              setBlocking(true)
              setCreativeTypeName(e.target.value)
              props?.setCreativeDetails({
                ...props?.object,
                creativetypename: e.target.value
              })
              props.blockingStatus(true)
            }}
          >
          </input></div>
        <label style={{color: "red"}}>{error.creativetypename}</label>
        {error.creativetypename.length === 0 && <div><label style={{color: "red"}}>{ctNameError}</label></div>}
      </Grid>
      <Grid item sm={12} md={6} pt={10}>
        <div><label className={`font ${styles.labelText}`}>Copy Block Count</label></div>
        <div>
          <input
            className={styles.inputText} type="text" name="copyblockcount"
            id="copyblockcount"
            value={copyblockcount}
            data-testid="button-change3"
            onChange={(e) => {
              e.preventDefault();
              const value = e.target.value;
              setBlocking(true)
              setCBError('')
              if (isNaN(+value)) {
                setCBError('The field Copy Block Count should be a number')
              }
              const val = (e.target.value + "").split(".")[1] || 0;
              if (val !== 0 || ((e.target.value)?.toString().indexOf('.') !== -1)) {   //temp.push(`$[key]`);
                setCBError(`${e.target.value} value is not valid for Copy Block Count`)
              }
              setCopyBlockCount(e.target.value)
              props?.setCreativeDetails({
                ...props?.object,
                copyblockcount: e.target.value
              })
              props.blockingStatus(true)
            }}
          />
          <div><label style={{color: "red"}}>{error.copyblockcount}</label></div>
          {error.copyblockcount.length === 0 && <div><label style={{color: "red"}}>{cbError}</label></div>}
        </div>
      </Grid>
    </Grid>
  </Box>)
}
export default CreativeTypeForm;
