export const PRIVILEGES = {
    SystemAdmin :"systemadmin",
    SecurityAdmin : "securityadmin",
    GroupAdmin:"groupadmin",
    ApprovalManager:"approvalmanager",
    Approver:"approver",
    MbmManager : "mbmmanager",
    MbmEditor:"mbmeditor",
    MbmPublisher :"mbmpublisher",
    SourceDocManager:"sourcedocmanager",
    ReportViewer:"reportviewer",
    TestAdmin:"testadmin",
    RuleManager:"rulemanager",
    TFNRequestor:"tfnrequestor",
    TFNMarketing:"tfnmarketing",
    TFNTelesales:"tfntelesales",
    TFNCallCenter:"tfncallcenter",
    TFNManager:"tfnmanager",
    ApprovalAdmin:"approvaladmin",
    TFNReporter:"tfnreporter",
    LookupManager:"lookupmanager",
    NotifyTypesAdmin:"notifytypesadmin",
    AppServiceAdmin:"appserviceadmin",
    JobManager:"jobmanager",
    TFNTester:"tfntester",
    SnippetManager:"snippetmanager",
    SeedListAdmin:"seedlistadmin",
    ToolTipsAdmin:"tooltipsadmin",
    DigitalContentAdmin:"digitalcontentadmin",
    CreativeHitsManager:"creativehitsmanager",
    MbmPublishScheduleAdmin:"mbmpublishscheduleadmin",
    TFNRequestorUHHS:"tfnrequestoruhhs",
    AcquisitionDepartment:"acquisitiondepartment",
    RetentionDepartment:"retentiondepartment",
    PDPDepartment:"pdpdepartment",
    NCPDepartment:"ncpdepartment",
    ProviderOperator:"provideroperator",
    InternalAdmin:"internaladmin",
    GTMReportViewer:"gtmreportviewer",
    MBMReportViewer:"mbmreportviewer",
    SOTReportViewer:"sotreportviewer",
    LeadZipReportViewer:"leadzipreportviewer"
}

export const MAP_PROD_HOSTNAME="aetmap.data-axle.com";
export const MAP_UAT_HOSTNAME="aetnaamp-uat.data-axle.com";

