import { Popup } from "@progress/kendo-react-popup";

import styles from "../../sourcedocs.module.scss";

export const CustomPopup = props => {

    return <Popup
        popupClass={styles.customPopup}
        {...props}
        style={{ width: '100%', maxWidth: '430px' }}
    />;
};