import React, { useState, useRef, useContext } from "react";
import { useHistory } from "react-router-dom";

import Button from "@mui/material/Button";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { SnackbarContext } from "context/SnackbarContext";
import {getErrors} from "utils";

import api from "Api";
import InfoDialog from "../../../common/Dialogs/InfoDialog";
import SampleDownload from "components/common/SampleDownload";

const CustomTabs = styled(Tabs)({
  marginTop: "25px"
});

const CustomTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    minWidth: 0,
    [theme.breakpoints.up("sm")]: {
      minWidth: 0,
    },
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: "16px",
    marginRight: theme.spacing(1),
    color: "#8F8A92",
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:hover": {
      color: "#754493",
      opacity: 1,
    },
    "&.Mui-selected": {
      color: "#130B18",
      fontWeight: theme.typography.fontWeightMedium,
      borderRadius: "8px 8px 0px 0px",
      backgroundColor: "#ECE3F2"
    },
    "&.Mui-focusVisible": {
      backgroundColor: "#d1eaff",
    },
  })
);

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  );
};

const UploadCreativeData = () => {
  const [isUploading, setIsUploading] = useState(false);
  const uploadInput = useRef();
  const [preValidationError, setpreValidationError] = useState([]);
  const [exceptionError, setexceptionError] = useState("Some Error Occured!");
  const [openDialog, setOpenDialog] = useState(false);
  const [label, setLabel] = useState();
  const [value, setValue] = useState(0);
  const {setSnackBarConfig} = useContext(SnackbarContext); 
  const history = useHistory();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const changeHandler = async (e, isEmail) => {
    setIsUploading(true);
    const file = e.target.files[0];
    setLabel(e.target.files[0].name);
    const formData = new FormData();
    formData.append("File", file);
    formData.append("fileoption", isEmail ? "email_cd" : "channel_cd");
    try {
      const res = await api.uploadCreativeData(formData);
      setSnackBarConfig({open: true, message: 'File(s) uploaded successfully.', severity: 'success'})
      history.push({
        pathname: "/sourcedocs/creativeData",
        state: {
          importSuccess: true,
        },
      });
    } catch (error) {
      if (error?.response?.status === 400) {
          let errors = []
          getErrors(error.response.data, errors)
          setpreValidationError(errors);
      } else {
        setexceptionError("Something went wrong!");
      }
      setOpenDialog(true);
      setIsUploading(false);
      setLabel("");
    }
  };

  const renderPanel = (isEmail = false) => {
    return (
      <div className="dnd-wrapper" style={{ marginTop: 0 }}>
        {isUploading ? (
          <>
            <p className="uploading">Uploading....</p>
            <label>{label}</label>
          </>
        ) : (
          <>
            <div className="dnd-title"></div>
            <input
              id="upload-input"
              type="file"
              accept=".xls,.xlsx"
              className="input-hide"
              ref={uploadInput}
              onChange={e => changeHandler(e, isEmail)}
              onClick={(e) => {
                e.target.value = null;
              }}
            />
            <button
              style={{ width: "350px", height: "100px" }}
              className="uploadfile-button"
              id="uploadfile-button"
              onClick={(e) => {
                uploadInput.current.click();
              }}
            >
              <FileUploadOutlinedIcon />
              <label style={{ fontSize: "20px" }}> Upload File</label>
            </button>
            <div style={{marginTop: '50px'}}>
              {!isEmail ? <SampleDownload title="Download Sample file" fileName="CreativeDataImport_sample" />
              : <SampleDownload title="Download Sample Email Creative Data file" fileName="Email_CreativeData_Sample" />
              }
            </div>
          </>
        )}

        {openDialog && <InfoDialog openDialog={openDialog} setOpenDialog={setOpenDialog}
                   messageInfo={{title: 'Something went wrong', message: preValidationError.length ? preValidationError:exceptionError}}/>}
      </div>
    )
  };

  return (
    <>
      <h4 style={{ color: "#8F8A92", fontSize: "14px", margin: 0 }}>
        SOURCE DOCUMENTS
      </h4>
      <h3 style={{ color: "#050307", fontSize: "29px", margin: 0 }}>
        Creative Data: Upload
      </h3>
      {/* Use common HeaderTabs here */}
      <CustomTabs
        value={value}
        indicatorColor="red"
        onChange={handleChange}
      >
        <CustomTab label="All Channels Creative Data File" />
        <CustomTab label="Email Creative Data File" />
      </CustomTabs>
      <TabPanel value={value} index={0}>
        {renderPanel()}
      </TabPanel>
      <TabPanel value={value} index={1}>
        {renderPanel(true)}
      </TabPanel>
    </>
  );
};
export default UploadCreativeData;
