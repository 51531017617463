export const ApiParams = {
  header: ["source documents", "creativeData"],
  moduleName: 'creativeData',
  methodName: "getAll",
  apiExtraParams: [],
  responseKey: "results",
  cookiesKey: 'creativeData',
  baseUrl: '/sourcedocs/creativeData'
};
export const CustomTableParams = {
  tableId: 'creativedataid',
}
