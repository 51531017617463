import React, {useState} from "react";
import {Redirect, Route} from "react-router-dom";
import auth from "../auth";
import MiniDrawer from "../sidebar/MUISideBar";
import "./style.scss";
import {APPLICATION_URL_PRIVILEGE} from "./constant";
import {hasAccessByPrivilegeName, hasAccessToJobStatus} from "../../utils";









export const ProtectedRoute = ({ component: Component, breadcrumb={}, user, isNested=false,...rest }) => {

   const {path} = {...rest}
    /**
     * this methods check whether URL is having permission to URL or not
     * @return {boolean}
     */
    const isUserHasAccessToURL = () => {
      
        if (!APPLICATION_URL_PRIVILEGE[path]) {
            return true
        }
        return hasAccessByPrivilegeName(APPLICATION_URL_PRIVILEGE[path])
    }


    const [rtl, setRtl] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const [image, setImage] = useState(true);
  const [toggled, setToggled] = useState(false);

  const handleCollapsedChange = (checked) => {
    setCollapsed(checked);
  };

  const handleRtlChange = (checked) => {
    setRtl(checked);
  };
  const handleImageChange = (checked) => {
    setImage(checked);
  };

  const handleToggleSidebar = (value) => {
    setToggled(value);
  };

  return (
    <Route
      {...rest}
      render={(props) => {
        if (auth.isAuthenticated() ) {
            if(isUserHasAccessToURL()){
                        return (
            <div
              className={`app ${rtl ? "rtl" : ""} ${toggled ? "toggled" : ""}`}
              style={{ overflow: "hidden" }}
            >
                {!isNested && <MiniDrawer
                image={image}
                collapsed={collapsed}
                rtl={rtl}
                toggled={toggled}
                handleToggleSidebar={handleToggleSidebar}
                handleCollapsedChange={handleCollapsedChange}
                user={user}
                breadcrumb={breadcrumb}
              />}
              <div
                className="main"
                style={{
                  overflow: "auto",
                  background: "#f7f7f9",
                  width: "100%",
                  paddingLeft: !isNested?"64px":0,
                  paddingTop: !isNested?"24px":0,
                }}
              >
                <Component user={user} {...props} />
              </div>
            </div>
          );
            }else{
                  return <div className="admin-contact"><span>Sorry,You Don't have access to this page .Kindly contact admin</span></div>
            }
        }
        else {
          return (
            <Redirect
              to={{
                pathname: "/login",
                state: {
                  from: props.location,
                },
              }}
            />
          );
        }
      }}
    />
  );
};

ProtectedRoute.defaultProps={
    isNested:false
}
