import React, { useState, useEffect, useContext, useRef } from "react";
import { useHistory } from "react-router-dom";

import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import sotApi from "api/sotApi";
import api from "Api.js";
import PromptAlert from "components/PromptAlert";
import styles from "../../sourcedocs.module.scss";
import { compareStr } from "components/common/utils";
import { SnackbarContext } from "context/SnackbarContext";

import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import LoadingPanel from "components/common/TabPanel/LoadingPanel";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";

const REQUIRED_ERROR = "This field is required.";
const schema = yup
  .object({
    refid: yup.string().required(REQUIRED_ERROR),
    channel: yup.object().required(REQUIRED_ERROR),
    channelsegment: yup.object().required(REQUIRED_ERROR),
    provider_type: yup.object().required(REQUIRED_ERROR),
    geography: yup
      .string()
      .required(REQUIRED_ERROR)
      .max(5, "Fips must be at most 5 characters"),
    provider_name: yup.string().required(REQUIRED_ERROR),
    provider_code: yup.string(),
  })
  .required();

const Form = ({ id, action }) => {
  const [isBlocking, setIsBlocking] = useState(false);
  const history = useHistory();
  const { setSnackBarConfig } = useContext(SnackbarContext);
  const [channelData, setChannelData] = useState([]);
  const [segmentData, setSegmentData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const provider_id = useRef(id);
  const [providerObj,  setProviderObj] = useState()
  const [largestRefId,setLargestRefId]=useState()

  useEffect(async () => {
    const res = await api.loadChannelsCreativeData();
    let temp = [];
    if (res?.data) {
      temp = res.data?.map(channel => ({
        text: channel?.shortname,
        value: channel?.channel_id
      }));
    }
    setChannelData(temp.sort((a, b) => compareStr(a.text, b.text)));
  }, []);
useEffect(async()=>{
const res=await sotApi.providers2.getRefId();
setLargestRefId(res?.data.maxrefid);
},[])
  useEffect(async () => {
    if (provider_id.current) {
      setLoading(true);
      let res;
      try {
        if (action == "clone") {
          res = await sotApi.providers2.clone(id);
        } else {
          res = await sotApi.providers2.get(provider_id.current);
        }
        setProviderObj(res.data)
        provider_id.current = res.data.provider2_id;
        let providerData = {
          refid: res.data.refid,
          provider_type: {
            id: res.data.provider_type,
            text: res.data.provider_type,
          },
          provider_code: res.data.provider_code,
          provider_name: res.data.provider_name,
          geography: res.data.geography.fips,
          channelsegment: {
            text: res.data.channelsegment.shortname,
            value: res.data.channelsegment.channelsegment_id.toString(),
          },
          channel: {
            text: res.data.channel.shortname,
            value: res.data.channel.channel_id.toString(),
          },
        };
        for (const field in schema.fields) {
          setValue(field, providerData[field]);
        }
        handleChannelChange({ value: providerData.channel }, false);
        setIsBlocking(false);
      } catch (error) {
        setLoading(false);
      }
    }
  }, []);

  const handleChannelChange = async (channel, resetSegment = true) => {
    setSegmentData([]);
    try {
      setLoading(true);
      const res = await api.loadChannelSegment({
        channel_id_list: [channel.value.value],
      });
      let data = [];
      if (res?.data) {
        data = res.data?.map(segment => ({
          text: segment?.shortname,
          value: segment?.channelsegment_id
        }))?.sort((a, b) => compareStr(a.text, b.text));
      }
      setSegmentData(data);
      if (resetSegment) {
        setValue("channelsegment", null);
      }
    } finally {
      setLoading(false);
    }
  };
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
    setValue,
    setError,
  } = useForm({
    resolver: yupResolver(schema),
  });

  React.useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      setIsBlocking(true);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const onSubmit = async (data) => {
    const res = window.confirm(
      "Updating this item may affect existing GTM selections . Continue ?"
    );
    data.channel = parseInt(data.channel.value);
    data.channelsegment = parseInt(data.channelsegment.value);
    data.provider_type = data.provider_type.id;
    try {
      setLoading(true);
      if (res) {
        if (action == "clone" || action == "edit") {
          const res = await sotApi.providers2.update(provider_id.current, data);
        } else {
          const res = await sotApi.providers2.add(data);
        }

        setSnackBarConfig({
          open: true,
          message: "Saved successfully.",
          severity: "success",
        });
        setIsBlocking(false);
        history.push({
          pathname: "/sourcedocs/providersData",
          state: {
            importSuccess: true,
          },
        });
      }
    } catch (error) {
      if (error.response.status == 400) {
        for (const field in error.response.data) {
          setError(field, {
            type: "custom",
            message: error.response.data[field][0],
          });
        }
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {isLoading && <LoadingPanel />}
      <form onSubmit={handleSubmit(onSubmit)} data-testid="form">
        <Stack direction="row" spacing={1}>
          <Button variant="outlined" className="button" type="submit">
            Save
          </Button>
        </Stack>

        <Box
          pt={3}
          className={`newgeography - wrapper ${styles.sourceDocsContainer}`}
        >
          <Grid container spacing={2}>
            <Grid item sm={12} md={6}>
              <div>
                <label className={`required font ${styles.labelText}`}>
                  RefId
                </label>
              </div>
              <div>
                <input
                  disabled={action == "edit"}
                  className={styles.inputText}
                  type="text"
                  name="refid"
                  id="refid"
                  {...register("refid")}
                />
                <label>(Largest currently used RefId: {largestRefId})</label>
                <div>
                  <label style={{ color: "red" }}>
                    {errors.refid?.message}
                  </label>
                </div>
              </div>
            </Grid>
            <Grid item sm={12} md={6}>
              <div>
                <label className={`required font ${styles.labelText}`}>
                  Channel
                </label>
              </div>
              <div>
                <Controller
                  name="channel"
                  control={control}
                  render={({ field }) => (
                    <DropDownList
                      name="channel"
                      id="channel"
                      className={styles.dropDownList}
                      data={channelData}
                      textField="text"
                      dataItemKey="value"
                      {...field}
                      onChange={(e) => {
                        handleChannelChange(e);
                        field.onChange(e);
                      }}
                      defaultItem={{ text: "Select Channel-----" }}
                    />
                  )}
                />
              </div>
              <div>
                <label style={{ color: "red" }}>
                  {errors.channel?.message}
                </label>
              </div>
            </Grid>
            <Grid item sm={12} md={6}>
              <div>
                <label className={`required font ${styles.labelText}`}>
                  Channel Segment
                </label>
              </div>
              <div>
                <Controller
                  name="channelsegment"
                  control={control}
                  render={({ field }) => (
                    <DropDownList
                      name="channelsegment"
                      id="channelsegment"
                      className={styles.dropDownList}
                      data={segmentData}
                      textField="text"
                      dataItemKey="value"
                      defaultItem={{ text: "Select Segment-----" }}
                      {...field}
                    />
                  )}
                />
              </div>
              <div>
                <label style={{ color: "red" }}>
                  {errors.channelsegment?.message}
                </label>
              </div>
            </Grid>
            <Grid item sm={12} md={6}>
              <div>
                <label className={`required font ${styles.labelText}`}>
                  Provider Name
                </label>
              </div>
              <div>
                <input
                  className={styles.inputText}
                  type="text"
                  name="provider_name"
                  id="provider_name"
                  {...register("provider_name")}
                />
              </div>
              <div>
                <label style={{ color: "red" }}>
                  {errors.provider_name?.message}
                </label>
              </div>
            </Grid>
            <Grid item sm={12} md={6}>
              <div>
                <label className={`font ${styles.labelText}`}>
                  Provider Code
                </label>
              </div>
              <div>
                <input
                  className={styles.inputText}
                  type="text"
                  name="provider_code"
                  id="provider_code"
                  {...register("provider_code")}
                />
              </div>
            </Grid>
            <Grid item sm={12} md={6}>
              <div>
                <label className={`required font ${styles.labelText}`}>
                  Provider Type
                </label>
              </div>
              <Controller
                name="provider_type"
                control={control}
                render={({ field }) => (
                  <DropDownList
                    name="provider_type"
                    id="provider_type"
                    className={styles.dropDownList}
                    data={[
                      { id: 1, text: 1 },
                      { id: 2, text: 2 },
                      { id: 3, text: 3 },
                      { id: 4, text: 4 },
                      { id: 5, text: 5 },
                      { id: 6, text: 6 },
                    ]}
                    textField="text"
                    dataItemKey="id"
                    defaultItem={{ text: "Select Provider Type----" }}
                    {...field}
                  />
                )}
              />
              <div>
                <label style={{ color: "red" }}>
                  {errors.provider_type?.message}
                </label>
              </div>
            </Grid>
            <Grid item sm={12} md={6}>
              <div>
                <label className={`required font ${styles.labelText}`}>
                  Fips
                </label>
              </div>
              <div>
                <input
                  className={styles.inputText}
                  type="text"
                  name="geography"
                  id="geography"
                  {...register("geography")}
                />
              </div>
              <div>
                <label style={{ color: "red" }}>
                  {errors.geography?.message}
                </label>
              </div>
            </Grid>
            <Grid item sm={12} md={6}>
            </Grid>
          {provider_id.current && action=="edit" &&  <Grid item sm={12} md={6}>
            <div>
              <label className={`font ${styles.labelText}`}>Last Updated By</label>
            </div>
            <div>
              <Typography variant="subtitle1">
                {providerObj?.modifyusername}
              </Typography>
            </div>
          </Grid>}
          {provider_id.current && action=="edit" && <Grid item sm={12} md={6}>
            <div>
              <label className={`font ${styles.labelText}`}>Last Updated Date</label>
            </div>
            <div>
              <Typography variant="subtitle1">
                {dayjs(providerObj?.modifydate).format("YYYY/MM/DD hh:mm:ss A")}
              </Typography>
            </div>
          </Grid>}
          </Grid>
          <PromptAlert isBlocking={isBlocking} />
        </Box>
      </form>
    </>
  );
};
export default Form;
