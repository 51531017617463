import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import {
  FormControl,
  Box,
  Toolbar,
  Breadcrumbs,
  Link,
  IconButton
} from "@mui/material";
import { ArrowBack } from "@mui/icons-material";

import Aside from "../sidebar/sidebar";
import { CustomDropdownSelect, CustomInputLabel } from "components/gtm/CustomSelectAndLabel";
import { hasAccessByPrivilegeName } from "utils";
import { PRIVILEGES } from "constant";
import gtmApi from "api/gtmApi";
import { getDropdownOptionsFromArray } from "components/common/utils";

const drawerWidth = 240;
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  boxShadow: "none",
  background: "white",
}));

export default function MiniDrawer(props) {
  const theme = useTheme();
  let history = useHistory();
  const { action } = useParams();

  const [hasSideBar, setHasSideBar] = useState(true);
  const [departmentList, setDepartmentList] = useState([]);

  useEffect(async () => {
    const res = await gtmApi.getDepartments();
    let deptArr = getDropdownOptionsFromArray(res.data?.departments);
    setDepartmentList(deptArr);
  }, []);

  const channelType = localStorage.getItem("channelType");

  useEffect(() => {
    // Show/Hide the side bar based on certain conditions
    const hasOneOfTheseUrls = [
      "/sourcedocs/creativeTypes/upload",
      "/sourcedocs/creativeTypes/new",
      "/sourcedocs/geography/new",
      "/sourcedocs/geography/upload",
      "/sourcedocs/snap/upload",
      "/sourcedocs/snap/new",
      "/sourcedocs/creativeData/new",
      "/sourcedocs/creativeData/upload",
      "/sourcedocs/productData/new",
      "/sourcedocs/productData/upload",
      "/documents/mbm/upload",
      "/sourcedocs/providersData/new",
      "/sourcedocs/providersData/upload",
      "/sourcedocs/marketingBullets/new",
      "/sourcedocs/marketingBulletsPublish",
      "/sourcedocs/disclaimersNeedToPublish",
      "/sourcedocs/valPropsNeedToPublish",
      "/sourcedocs/valProps/new",
      "/sourcedocs/disclaimers/new",
      "/seedLists/new",
      "/seedLists/upload",
      "/tfn/viewTFNs/upload"
    ].includes(window.location.pathname)
    if (hasOneOfTheseUrls || (window.location.href.indexOf("clone") > -1) || (window.location.href.indexOf("edit") > -1)
      || ((window.location.href.indexOf("details")) > -1) || (window.location.href.indexOf("new")) > -1) {
      setHasSideBar(false)
    } else {
      setHasSideBar(true)
    }
    // Show Department "Secondary Retention" only for /documents/gtm
    if (window.location.pathname === "/documents/gtm") {
      !departmentList?.map((d) => d.label)?.includes("Secondary Retention") &&
        setDepartmentList((prevState) => [
          ...prevState,
          ...getDropdownOptionsFromArray(["Secondary Retention"]),
        ]);
    } else {
      departmentList?.map((d) => d.label)?.includes("Secondary Retention") &&
        setDepartmentList((prevState) =>
          prevState.filter(
            (department) => department?.label !== "Secondary Retention"
          )
        );
    }
  }, [history.location, departmentList])

  return (
    <Box sx={{ display: "flex", minHeight: "100vh" }}>
      <AppBar
        position="absolute"
        open={!props.collapsed}
        style={{
          left: props.collapsed && hasSideBar ? "80px" : "0px",
          marginLeft: !hasSideBar ? '0' : props.collapsed ? "0px" : "220px",
          width: !hasSideBar ? '100%' : props.collapsed ? "calc(100% - 80px)" : "calc(100% - 220px)",
        }}
      >

        <Toolbar style={{ width: "100%" }}>
          {!hasSideBar && (
            <IconButton aria-label="delete" color={'primary'} onClick={() => history.goBack()}
              sx={{ margin: theme.spacing(0, 1, 0, -1) }}>
              <ArrowBack fontSize="inherit" />
            </IconButton>)}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            {props.breadcrumb && <Breadcrumbs aria-label="breadcrumb" style={{ marginTop: hasSideBar && "10px" }}>
              {/* <Link underline="none" color="inherit" href="/">
                                Toll-Free Numbers
                            </Link>
                            <Link underline="hover" color="inherit" href="/">
                                Toll-Free Numbersww
                            </Link>
                            <Typography color="text.primary">Dashboard</Typography> */}
              {props.breadcrumb.map((item) => {
                return (
                  // <Link underline="none" color="inherit" href={item.to}>
                  <Link underline="none" color="inherit" key={item} data-testid="button-click" onClick={() => { history.replace(item.to) }} sx={{ textTransform: "capitalize" }}>
                    {item.title || action}
                  </Link>
                );
              })}
            </Breadcrumbs>}
            {/* <p style={{color: '#754493', fontWeight:'700', border: '2px solid', paddingRight: '5px', paddingLeft: '5px', backgroundColor: '#ece3f2', borderRadius: '5px'}}>AEP Year</p> */}
            {hasAccessByPrivilegeName([PRIVILEGES.AcquisitionDepartment, PRIVILEGES.RetentionDepartment, PRIVILEGES.PDPDepartment, PRIVILEGES.NCPDepartment]) &&
              <FormControl variant="standard" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <CustomInputLabel shrink htmlFor="Department" style={{ position: 'initial', margin: '0 20px 0 0', transform: 'none', fontSize: '1rem', fontWeight: 'bold' }}>
                  Department:
                </CustomInputLabel>
                <CustomDropdownSelect
                  style={{
                    width: "300px",
                    margin: 0
                  }}
                  id="selectDepartment"
                  data={departmentList}
                  value={{
                    label: channelType,
                    value: channelType
                  }}
                  placeholder={departmentList?.length > 0 ? "Department" : "Loading.."}
                  name="Department"
                  textField="label"
                  dataItemKey="value"
                  onChange={(e) => {
                    localStorage.setItem("channelType", e.value.value)
                    window.location.reload();
                  }}
                />
              </FormControl>
            }
          </div>

        </Toolbar>

      </AppBar>
      {hasSideBar && <Aside
        image={props.image}
        collapsed={props.collapsed}
        rtl={props.rtl}
        toggled={props.toggled}
        handleToggleSidebar={props.handleToggleSidebar}
        handleCollapsedChange={props.handleCollapsedChange}
        user={props.user}
      />
      }
    </Box>
  );
}
