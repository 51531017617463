import axios from "axios";
import { axiosInstance, baseURL } from "../axios.js";

const sotApi = {
  loadUserPreferences: (category, subcategory) => {
    return axiosInstance.get(
      `/api/common/userpreference/preference_grid/?category=${category}&subcategory=${subcategory}`
    );
  },
  updateUserPreferences: (category, subcategory, payload) => {
    return axiosInstance.put(
      `/api/common/userpreference/update_usrprefernce_grid/?category=${category}&subcategory=${subcategory}`,
      payload
    );
  }, 
  testRule: (obj) => {
    return axiosInstance.post(`/api/sot/preview/testbullet/`, obj)
  },
  getDistinctFieldValues: (body) => {
    return axiosInstance.put(`/api/sot/distinctvalues/?no_page=True`, body);
  },
  creativeData: {
    getAll: (body) => {
      return axiosInstance.put(
        `/api/sot/creativedata/query/?limit=${body.limit}&offset=${body.offset}`,
        body.payload
      );
    },
    get: (id) => {
      return axiosInstance.get(`/api/sot/creativedata/${id}/`)
    },
    add: (obj) => {
      return axiosInstance.post(`/api/sot/creativedata/`, obj)
    },
    clone: (id) => {
      return axiosInstance.post(`/api/sot/creativedata/${id}/copy-creativedata/`)
    },
    update: (id, obj) => {
      return axiosInstance.patch(`/api/sot/creativedata/${id}/edit-creativedata/`, obj)
    },
    deleteAll: (obj) => {
      return axiosInstance.post(`/api/sot/creativedata/del-creativedata/`, obj)
    },
    download: (fileOption = "channel_cd", payload) => {
      return axiosInstance.put(`/api/sot/creativedata/download/?fileoption=${fileOption}`, payload, {
        responseType: 'blob'
      })
    },
    getRefId: () => {
      return axiosInstance.get('/api/sot/creativedata/max-refid/')
    }
  },
  creativeTypes: {
    getAll: (body) => {
      return axiosInstance.put(
        `/api/sot/creativetypes/query/?limit=${body.limit}&offset=${body.offset}`,
        body.payload
      );
    },
    deleteAll: (body) => {
      return axiosInstance.delete(`/api/sot/removecreative/delete-creative/`, { data: body })
    },
    add: (body) => {
      return axiosInstance.post(`/api/sot/creativetypes/add-creative/`, body)
    },
    update: (id, obj) => {
      return axiosInstance.patch(`/api/sot/creativetypes/${id}/patch-creative/`, obj)
    },
    clone: (id) => {
      return axiosInstance.post(`/api/sot/creativetypes/${id}/copy/`)
    },
    getRefId: () => {
      return axiosInstance.post('/api/sot/creativetypes/get_new/')
    },
    download: () => {
      return axiosInstance.get('/api/sot/creative/saveas/', {
        responseType: 'blob'
      })
    },
    copyBlocks: {
      get: (id) => {
        return axiosInstance.post(`/api/sot/creativetypes/${id}/getblk/`)
      },
      getAll: () => {
        return axiosInstance.get(`/api/sot/copyblock/lst-blk/`)
      },
      add: (id, body) => {
        return axiosInstance.post(`/api/sot/creativetypes/${id}/add/`, body)
      },
      delete: (id, body) => {
        return axiosInstance.delete(`/api/sot/creativetypes/${id}/remove/`, { data: body })
      }
    },
  },
  geography: {
    getAll: (body) => {
      return axiosInstance.put(
        `/api/common/geographys/query/?limit=${body.limit}&offset=${body.offset}`,
        body.payload
      );
    },
    get: (id) => {
      return axiosInstance.get(`/api/common/geographys/${id}/`)
    },
    add: (obj) => {
      return axiosInstance.post(`/api/common/geographys/`, obj)
    },
    update: (id, obj) => {
      return axiosInstance.patch(`/api/common/geographys/${id}/edit-geography/`, obj)
    },
    getRequiredFields: (body) => {
      return axiosInstance.put(`/api/common/lookups/query/?no_page=true`, body)
    },
    deleteAll: (obj) => {
      return axiosInstance.post(`/api/common/geographys/del-geography/`, obj)
    },
    download: () => {
      return axiosInstance.get(`/api/common/geographys/download-geography/`, {
        responseType: 'blob'
      })
    },
    upload: (dataFile) => {
      return axiosInstance.post(`/api/common/geographys/upload-geography/`, dataFile, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    },
    getRefId: () => {
      return axiosInstance.get('/api/common/geographys/max-refid/')
    }
  },
  snap: {
    snapfields: () => {
      return axiosInstance.put('/api/sot/snap/snapfields/')
    },
    loadChannels: () => {
      return axiosInstance.get('/api/common/channel')
    },
    loadChannelSegments: (body) => {
      return axiosInstance.post('/api/sot/snap/chanelsegment/', body)
    },
    getAll: (obj) => {
      return axiosInstance.put(
        `/api/sot/snap/query/?limit=${obj.limit}&offset=${obj.offset}`,
        obj.payload
      );
    },
    get: (id) => {
      return axiosInstance.post(`/api/sot/snap/${id}/getsnap/`)
    },
    add: (body) => {
      return axiosInstance.post('/api/sot/snap/addNew/', body)
    },
    deleteAll: (body) => {
      return axiosInstance.delete('/api/sot/snap/deletesnap/', { data: body })
    },
    download: () => {
      return axiosInstance.get('/api/sot/snap/saveas/', { responseType: 'blob' })
    },
    upload: (dataFile) => {
      return axiosInstance.post('/api/sot/uploadsnap/'
        , dataFile,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
    },
    update: (id, body) => {
      return axiosInstance.put(`/api/sot/snap/${id}/updatesnap/`, body)
    },
    clone: (id) => {
      return axiosInstance.post(`/api/sot/snap/${id}/copy/`)
    },
    getMaxRefId: () => {
      return axiosInstance.get(`/api/sot/snap/get_max_refid/`)
    },
    getSnapRefIdList: () => {
      return axiosInstance.get(`/api/sot/snap/get_snap_refid_list/`)
    }
  },
  marketingBullets: {
    getAll: (obj) => {
      return axiosInstance.put(`${baseURL}/api/sot/marketbullet/query/?limit=${obj.limit}&offset=${obj.offset}`,
        obj.payload)
    },
    add: (body) => {
      return axiosInstance.post('/api/sot/marketbullet/', body)
    },
    get: (id) => {
      return axiosInstance.get(`/api/sot/marketbullet/${id}/`)
    },
    clone: (id) => {
      return axiosInstance.post(`/api/sot/marketbullet/${id}/copy-mbdata/`)
    },
    update: (id, obj) => {
      return axiosInstance.patch(`/api/sot/marketbullet/${id}/edit-marketbullet/`, obj)
    },
    download: () => {
      return axiosInstance.get(`${baseURL}/api/sot/marketbullet/download/`, {
        responseType: 'blob'
      })
    },
    deleteAll: (obj) => {
      return axiosInstance.post(`${baseURL}/api/sot/marketbullet/del-mbdata/`, obj)
    },
    getRefId: () => {
    },
    getExpressionFields: (obj) => {
      return axiosInstance.post(`/api/sot/preview/loadfields/`, obj)
    }
  },
  marketingBullets_needtobe_pub: {
    getAll: () => {
      return axiosInstance.get(`/api/sot/marketbullet/ready-pub-mbdata/`)
    },
    add: (obj) => {
      return axiosInstance.post(`/api/sot/published_mbs/submit-pub-mbdata/`, obj)
    },

  },
  marketingBullets_pub: {
    getAll: (obj) => {
      return axiosInstance.put(`/api/sot/published_mbs/query/?limit=${obj.limit}&&offset=${obj.offset}`, obj.payload)
    },
    download: () => {
      return axiosInstance.get(`/api/sot/published_mbs/download/`, {
        responseType: 'blob'
      })
    },
    get: (id) => {
      return axiosInstance.get(`/api/sot/published_mbs/${id}/`)
    },
  },
  valProps: {
    getAll: (obj) => {
      return axiosInstance.put(`/api/sot/copyblock/query/?limit=${obj.limit}&&offset=${obj.offset}`, obj.payload)
    },
    deleteAll: (obj) => {
      return axiosInstance.post(`/api/sot/copyblock/del-cpksdata/`, obj)
    },
    download: () => {
      return axiosInstance.get(`/api/sot/copyblock/download`, {
        responseType: 'blob'
      })
    },
    add:(obj)=>{
      return axiosInstance.post(`/api/sot/copyblock/`,obj)
    },
    get:(id)=>{
      return axiosInstance.get(`/api/sot/copyblock/${id}/`)
    },
    update:(id,obj)=>{
      return axiosInstance.patch(`/api/sot/copyblock/${id}/edit-copyblock/`,obj)
    },
    clone: (id) => {
      return axiosInstance.post(`/api/sot/copyblock/${id}/copy_copyblock/`)
    },
    getCreativeTypes:(obj)=>{
      return axiosInstance.post(`/api/sot/preview/loadcreative/`,obj)
    }
  },
  valprops_pub: {
    getAll: (obj) => {
      return axiosInstance.put(`/api/sot/published_cbs/query/?limit=${obj.limit}&&offset=${obj.offset}`, obj.payload)
    },
    download: () => {
      return axiosInstance.get(`/api/sot/published_cbs/download`, {
        responseType: 'blob'
      })
    },
    get:(id)=>{
      return axiosInstance.get(`/api/sot/published_cbs/${id}/`)
    }
  },
  valProps_needtobe_pub: {
    getAll: () => {
      return axiosInstance.get(`/api/sot/copyblock/ready-pub-cpdata/`)
    },
    add: (obj) => {

      return axiosInstance.post(`/api/sot/published_cbs/submit-pub-cpdata/`, obj)

    },

  },
  providers2: {
    getAll: (body) => {
      return axiosInstance.put(
        `/api/sot/providers2/query/?limit=${body.limit}&offset=${body.offset}`,
        body.payload
      );
    },
    get: (id) => {
      return axiosInstance.get(`/api/sot/providers2/${id}/`)
    },
    add: (obj) => {
      return axiosInstance.post(`/api/sot/providers2/addNew/`, obj)
    },
    getChannels: () => {
      return axiosInstance.post(`/api/sot/providers2/channel/`)
    },
    getSegments: (obj) => {
      return axiosInstance.post(`/api/sot/providers2/chanelsegment/`, obj)
    },
    clone: (id) => {
      return axiosInstance.post(`/api/sot/providers2/${id}/clone-provider/`)
    },
    update: (id, obj) => {
      return axiosInstance.patch(`/api/sot/providers2/${id}/update_provider/`, obj)
    },
    deleteAll: (obj) => {
      return axiosInstance.delete(`/api/sot/providers2/delete-provider/`, { data: obj })
    },
    download: () => {
      return axiosInstance.get('/api/sot/providers2/saveas-provider/', { responseType: 'blob' })
    },
    getRefId: () => {
      return axiosInstance.get('/api/sot/providers2/max-refid/')
    },
    upload: (dataFile) => {
      return axiosInstance.post('/api/sot/providers2/upload-provider2/'
        , dataFile,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
    },
    add: (obj) => {
      return axiosInstance.post(`/api/sot/providers2/addNew/`, obj)
    },
    getChannel: () => {
      return axiosInstance.post(`/api/sot/providers2/channel/`)
    },
  },
  disclaimers: {
    getAll: (body) => {
      return axiosInstance.put(`/api/sot/disclaimers_sot/query/?limit=${body.limit}&offset=${body.offset}`,
        body.payload)
    },
    deleteAll: (obj) => {
      return axiosInstance.post(`/api/sot/disclaimers_sot/del-disclaimer/`, obj)
    },
    download: () => {
      return axiosInstance.get('/api/sot/disclaimers_sot/download/', { responseType: 'blob' })
    },
    add: (obj) => {
      return axiosInstance.post(`/api/sot/disclaimers_sot/`, obj)
    },
    get: (id) => {
      return axiosInstance.get(`/api/sot/disclaimers_sot/${id}/`)
    },
    update: (id, obj) => {
      return axiosInstance.patch(`/api/sot/disclaimers_sot/${id}/edit-disclaimer/`, obj)
    },
    clone: (id) => {
      return axiosInstance.post(`/api/sot/disclaimers_sot/${id}/copy_disclaimers/`)
    },
  },
  disclaimers_pub: {
    getAll: (body) => {
      return axiosInstance.put(`/api/sot/disclaimers_pub/query/?limit=${body.limit}&offset=${body.offset}`,
        body.payload)
    },
    download: () => {
      return axiosInstance.get(`/api/sot/disclaimers_pub/download/`, { responseType: 'blob' })
    },
    get: (id) => {
      return axiosInstance.get(`/api/sot/disclaimers_pub/${id}/`)
    }
  },
  disclaimers_gen: {
    getAll: (body) => {
      return axiosInstance.put(`/api/sot/disclaimers_gen/query/?limit=${body.limit}&offset=${body.offset}`,
        body.payload)
    },
    download: () => {
      return axiosInstance.get(`/api/sot/disclaimers_gen/download/`, { responseType: 'blob' })
    },
    deleteAll: (obj) => {
      return axiosInstance.post(`/api/sot/disclaimers_gen/del-disclaimergen/`, obj)
    },
    add: (obj) => {
      return axiosInstance.post(`/api/sot/disclaimers_gen/`, obj)
    },
    get: (id) => {
      return axiosInstance.get(`/api/sot/disclaimers_gen/${id}/`)
    },
    update: (id, obj) => {
      return axiosInstance.patch(`/api/sot/disclaimers_gen/${id}/`, obj)
    },

  },
  disclaimers_needtobe_pub: {
    getAll: () => {
      return axiosInstance.get(`/api/sot/disclaimers_sot/ready-pub-disclaimerdata/`)
    },
    add: (obj) => {
      return axiosInstance.post(`/api/sot/disclaimers_pub/submit-pub-disclaimerdata/`, obj)
    }
  },
  creativeHits: {
    getAll: () => {
      return axiosInstance.get(`/api/sot/hits/?no_page=true`)
    },

  },
  bulletPositioning: {
    upload: (dataFile) => {
      return axiosInstance.post(`/api/sot/bulletposition/upload-bullets/`, dataFile, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    },
  },
  publishSchedule: {
    getAll: (body) => {
      return axiosInstance.put(
        `/api/common/schedule/query/?limit=${body.limit}&offset=${body.offset}`,
        {
          order: body.payload?.order,
          filter: [
            ...body.payload?.filter,
            {
              field: "job_name",
              criteria: [{
                op: "contains",
                value: "publish_mbm"
              }]
            },
            {
              field: "isdeleted",
              criteria: [
                {
                  op: "is not",
                  value: true
                },
              ]
            },
          ]
        }
      );
    },
    get: (id) => {
      return axiosInstance.get(`/api/common/schedule/${id}/`)
    },
    add: (obj) => {
      return axiosInstance.post(`/api/common/schedule/`, obj)
    },
    update: (id, obj) => {
      return axiosInstance.put(`/api/common/schedule/${id}/`, obj)
    },
    deleteAll: (obj) => {
      return axiosInstance.delete(`/api/common/schedule/bulk_delete/`, {data: obj})
    },
  },
  publishSheduleExecution: {
    get: (body) => {
      let obj = {}
      if (body.payload.filter.length > 0) {
        obj = body.payload
      } else {
        obj = {
          filter: [
            {
              field: "schedule.schedule_id",
              criteria: [
                {
                  op: "is",
                  value: body.payload.scheduleId
                }
              ]
            }
          ]
        }
      }
      return axiosInstance.put(`/api/common/schedule_executions/query/?limit=${body.limit}&offset=${body.offset}`, obj)
    }
  }
}
export default sotApi;
