export const TFNbreadCrumbsData = {
  importTfn: [
    { title: "Toll-Free Numbers", to: "/tfn/tfnDashboard" },
    { title: "Import TFNs" },
  ],
  viewTfn: [
    { title: "Toll-Free Numbers", to: "/tfn/tfnDashboard" },
    { title: "View TFNs" },
  ],
  uploadViewTfn: [
    { title: "Toll-Free Numbers", to: "/tfn/tfnDashboard" },
    { title: "View TFNs" },
  ],
  requestPage: [
    { title: "Toll-Free Numbers", to: "/tfn/tfnDashboard" },
    { title: "Requests" },
  ],
  requestDetailsPage: [
    { title: "Toll-Free Numbers", to: "/tfn/tfnDashboard" },
    { title: "Requests", to: "/tfn/tfnRequests" },
    { title: "" },
  ],
  dashboard: [{ title: "Toll-Free Numbers" }],
  tfnapproval: [
    { title: "Approvals", to: "/tfn/tfnDashboard" },
    { title: "TFN Approvals" },
  ],
  tfnReports: [
    { title: "Reports", to: "/tfn/tfnDashboard" },
    { title: "TFN Reports" },
  ],
  sotReports: [
    { title: "Reports", to: "/tfn/tfnDashboard" },
    { title: "SOT Reports" },
  ],
  gtmReports: [
    { title: "Reports", to: "/tfn/tfnDashboard" },
    { title: "GTM Reports" },
  ],
  leadZipReports:[
    {title:"Reports", to:"/tfn/tfnDashboard"},
    {title:"LEAD ZIP Reports"}
  ],
    MbmReports:[
    {title:"Reports", to:"/tfn/tfnDashboard"},
    {title:"MBM Reports"}
  ],

  changePassword: [{ title: "Change Password" }],
  updateProfile: [{ title: "Update Profile" }],

  tfnInfoPage: [
    { title: "Approvals", to: "/tfn/tfnDashboard" },
    { title: "TFN Approvals", to: "/approvals" },
    { title: "" },
  ],
};
