const Purple = {
  50: '#E1D2EA',
  100: '#D5C0E2',
  200: '#BE9DD2',
  300: '#A77BC1',
  400: '#8F58B1',
  500: '#754493',
  600: '#593370',
  700: '#3D234D',
  800: '#21132A',
  900: '#050307',
}

export default Purple
