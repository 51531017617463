import { cloneDeep } from "lodash"

const varNamePrefix = "txt";

const getDefaultVariable = () => {
    return {
        "text": "",
        "value": "",
        "symbol": "",
        "fieldType": {
            "text": "Text",
            "value": "text"
        }
    }
}

const getDefaultCondition = () => {
    return {
        "type": "expr",
        "pln": "",
        "plnText": "",
        "mbr": "",
        "mbrText": "",
        "op": "",
        "opText": "",
        "val": "",
        "valText": "",
        "valComp": false,
        "editorType": 4,
        "fldComp": false
    }
}

const getDefaultExpression = () => {
    return {
        "type": "group",
        "op": "and",
        "opText": "",
        "isRoot": true,
        "exprs": [
            {
                ...getDefaultCondition()
            }
        ]
    }
}

const getDefaultBaseCase = () => {
    return {
        "id": '',
        "name": '',
        "incrCount": false,
        "exprs": [
            cloneDeep(getDefaultExpression())
        ]
    }
}
const getDefaultCase = () => {
    return {
        ...cloneDeep(getDefaultBaseCase()),
        actions: ""
    }
}

const getDefaultBlock = () => {
    return {
        "id": '',
        "name": '',
        "symbol": '',
        "variable": '',
        "isInvisible": false,
        "caseId": 0,
        "cases": [
            cloneDeep(getDefaultCase())
        ]
    }
}

const OPERATION = {
    ADD: "add",
    UPDATE: "update",
    DELETE: "delete"
}

const NODE_TYPE = {
    BLOCK: "block",
    EXPRESSION: "expression",
    CONDITION: "condition"
}

const LP = "LP"
const AP = "AP"
const PY0 = "PY0"
const PY1 = "PY-1"
const AP2 = "AP2"
const AP3= "AP3"
const AP4= "AP4"
const AP5 = "AP5"

const planChoices = [
    { label: "Lead Plan", value: LP },
    { label: "Alt Plan 1", value: AP },
    { label: "Alt Plan 2", value: AP2},
    { label: "Alt Plan 3", value: AP3},
    { label: "Alt Plan 4", value: AP4},
    { label: "Alt Plan 5", value: AP5},
    { label: "Prior Year Plan Previous AEP Year", value: PY1 },
    { label: "Prior Year Plan Current AEP Year", value: PY0 }
];
const stringOperatorChoices = [
    { label: "Is One of (=)", value: "eq" },
    { label: "Not One of (!=)", value: "neq" },
    { label: "Contains", value: "contains" },
    { label: "Does not Contain", value: "doesnotcontain" },
    { label: "Is Empty", value: "isempty" },
    { label: "Is Not Empty", value: "isnotempty" }
];

const numberOperatorChoices = [
    {
        "label": "Contains",
        "value": "contains"
    },
    {
        "label": "Does Not Contain",
        "value": "doesnotcontain"
    },
    {
        "label": "Equal To (=)",
        "value": "eq"
    },
    {
        "label": "Not Equal To (!=)",
        "value": "neq"
    },
    {
        "label": "Greater Than or Equal To (>=)",
        "value": "gte"
    },
    {
        "label": "Greater Than (>)",
        "value": "gt"
    },
    {
        "label": "Less Than or Equal To (<=)",
        "value": "lte"
    },
    {
        "label": "Less Than (<)",
        "value": "lt"
    }
]


const matchChoices = [
    { label: "Match All", value: "and" },
    { label: "Match Any", value: "or" },
]

const logicalBlockChoices = [
    { label: "IF", value: "if" },
    { label: "ELSE", value: "else" }
];

const productChoices = [
    { value: 'MA', label: 'MA' },
    { value: 'MAPD', label: 'MAPD' }
];

const operationChoices = [
    { value: 'and', label: 'AND' },
    { value: 'or', label: 'OR' }
];

const platformChoices = [
    { value: 'POS', label: 'POS' },
    { value: 'HMO', label: 'HMO' },
    { value: 'DSNP', label: 'DSNP' },
    { value: 'DSNP (FIDE)', label: 'DSNP (FIDE)' },
    { value: 'PPO', label: 'PPO' },
    { value: 'RPPO', label: 'RPPO' }
];

const groupingActions = {
    "combine": 1,
    "split": 2
}


const functionChoices = [
    { label: 'includes', value: 'includes(searchString)' },
    { label: 'indexOf', value: 'indexOf(searchString)' },
    { label: 'length', value: 'length' },
    { label: 'replace', value: 'replace(substr, newSubstr)' },
    { label: 'startsWith', value: 'startsWith(searchString)' },
    { label: 'endsWith', value: 'endsWith(searchString)' },
    { label: 'subString', value: 'substring(start, end)' }
]


export {
    getDefaultVariable,
    getDefaultBlock,
    getDefaultCase,
    getDefaultExpression,
    getDefaultCondition,
    planChoices,
    stringOperatorChoices,
    numberOperatorChoices,
    matchChoices,
    logicalBlockChoices,
    productChoices,
    operationChoices,
    platformChoices,
    groupingActions,
    functionChoices,
    varNamePrefix,
    OPERATION,
    NODE_TYPE,
    LP,
    AP,
    PY0,
    PY1
}
