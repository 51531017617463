import React from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import EditForm from "./EditForm";
import api from "Api";
import sotApi from "api/sotApi";
import {ApiParams} from "../constants";

const EditCloneSnap = () => {
  const [showForm, setShowForm] = React.useState(false);
  const [isCallEditApi, setIsCallEditApi] = React.useState(false);
  const [action, setAction] = React.useState();
  const [snapid, setsnapId] = React.useState();
  const [snapObject, setSnapObject] = React.useState({ refid: "" });
  const [stateArray, setStateArray] = React.useState([]);
  const [newObj, setNewObj] = React.useState({});
  const [cloneid, setcloneid] = React.useState();
  const [seminarArray, setSeminarArray] = React.useState([
    {
      text: "No",
      value: "N",
    },
    {
      text: "RSVP",
      value: "R",
    },
    {
      text: "Yes",
      value: "Y",
    },
  ]);

  React.useEffect(() => {
    if (newObj !== "undefined") {
      Object.keys(newObj).map((key) => {
        if (key === "state") {
          stateArray.forEach((item) => {
            if (newObj[key] === item.value) {
              return setSnapObject((prev) => ({ ...prev, state: item }));
            }
          });
        }
        if (key === "channel") {
          return setSnapObject((prev) => ({
            ...prev,
            channel: {
              text: newObj[key]?.shortname,
              value: newObj[key]?.channel_id,
            },
          }));
        }
        if (key === "segment") {
          return setSnapObject((prev) => ({
            ...prev,
            segment: {
              text: newObj[key]?.shortname,
              value: newObj[key]?.channelsegment_id,//need to check
            },
          }));
        }
        if (key === "seminarflag") {
          seminarArray.forEach((item) => {
            if (newObj[key] === item.value) {
              return setSnapObject((prev) => ({ ...prev, seminarflag: item }));
            }
          });
        }
      });
    }
  }, [newObj]);

  React.useEffect(() => {
    const id = window.location.pathname;
    const action = id.split("/");
    setsnapId(id.split("/").pop());
    setAction(action[action.length - 2]);
    const body = {
      filter: [
        {
          field: "category",
          criteria: [
            {
              op: "is",
              value: "General",
            },
          ],
        },
        {
          field: "lookupname",
          criteria: [
            {
              op: "is",
              value: "State",
            },
          ],
        },
      ],
    };
    if (stateArray.length === 0) {
      callGetStates(body);
    }
  }, []);
  const loadingPanel = (
    <div className="k-loading-mask">
      <span className="k-loading-text">Loading</span>
      <div className="k-loading-image"></div>
      <div className="k-loading-color"></div>
    </div>
  );
  React.useEffect(async () => {
    try {
      if (typeof cloneid != "undefined") {
        const res = await sotApi[ApiParams.moduleName].get(cloneid);
        setNewObj(res?.data.results[0]);
        setSnapObject(res?.data.results[0]);
      }
    } catch (e) {
      alert("Something went wrong!");
    }
  }, [cloneid]);
  React.useEffect(async () => {
    setIsCallEditApi(true);
    if(stateArray?.length > 0) {
      if (action === "edit") {
        try {
          const res = await sotApi[ApiParams.moduleName].get(snapid);
          setIsCallEditApi(true);
          setNewObj(res?.data?.results[0]);
          setSnapObject(res?.data?.results[0]);
        } catch (e) {
          alert("Something with wrong!");
        } finally {
          setIsCallEditApi(false);
        }
      }
      if (action === "clone") {
        try {
          const res = await sotApi[ApiParams.moduleName].clone(snapid);
          setcloneid(res?.data?.snapId); //readingcloneid
          setIsCallEditApi(true);
        } catch (e) {
          alert("something went wrong");
        } finally {
          setIsCallEditApi(false);
        }
      }
    }
  }, [snapid, stateArray]);
  const callGetStates = async (body) => {
    try {
      const res = await api.getRequiredFields(body);

      const data = res.data;
      let states = [];
      data.forEach((item) => {
        states.push({ text: item.displaytext, value: item.lookupvalue });
      });
      setStateArray(states);
    } catch (e) {
      alert("Something went wrong!");
    }
  };
  return (
    <>
      <h4 style={{ color: "#8F8A92", fontSize: "14px", margin: 0 }}>SNAP</h4>
      <h3 style={{ color: "#050307", fontSize: "29px", margin: 0 }}>
        {action === "clone"
          ? `${action?.charAt(0).toUpperCase()+action?.slice(1)}:${snapObject.refid}`
          : `${action?.charAt(0).toUpperCase()+action?.slice(1)}:${snapObject.refid}`}
      </h3>
      <div className="newgeography-wrapper">
        <div style={{ padding: 30 }}>
          {isCallEditApi && loadingPanel}
          {
          (
            <div>
              <EditForm
                snapObj={snapObject}
                action={action}
                disabled={action === "clone" ? false : true}
              />
            
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default EditCloneSnap;
