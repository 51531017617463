import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import styles from './InfoDialog.module.scss';
import {getErrors} from "../../../utils";

/**
 * To show Message
 * @param {*} openDialog
 * @param {*} setOpenDialog
 * @param {*} messageInfo
 * @param type
 * @returns
 */
const InfoDialog = ({openDialog, setOpenDialog, messageInfo, type,callContinueFileUploadApi=null}) => {
  let errors = []
  try {
    errors = getErrors(messageInfo.message)
  } catch {}
const handleContinueClick=()=>{
  callContinueFileUploadApi();
}
  return (
    <Dialog
      open={openDialog}
      data-testid="info"
      onClose={() => setOpenDialog(false)}
      PaperProps={{
        sx: {
          position: "fixed",
          top: 0,
          left: "auto",
          m: 0,
          width: 600,
        },
      }}
    >
      <div className={styles.dialogInfo}>
        <h2 className={type === 'info' && styles.info}>
          <InfoRoundedIcon/> {messageInfo.title || 'Something went wrong'}
        </h2>
          <ul>
              {errors.map((value, index) => {
                  return <li key={value + index}>{value}</li>
                })
              }
            </ul>
      </div>
      <DialogActions>
     { callContinueFileUploadApi && <Button
          variant="contained"
          data-testid="open"
          onClick={() => {
            setOpenDialog(false);
            callContinueFileUploadApi && callContinueFileUploadApi();
           // messageInfo?.handleClick && messageInfo?.handleClick();
          }}
        >
         Continue
        </Button>}
        <Button
          variant="contained"
          data-testid="close"
          onClick={() => {
            setOpenDialog(false);
            messageInfo?.handleClick && messageInfo?.handleClick();
          }}
        >
         {callContinueFileUploadApi?"Cancel":"OK"}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
InfoDialog.props = {
  openDialog: PropTypes.bool.isRequired,
  setOpenDialog: PropTypes.func.isRequired,
  messageInfo: PropTypes.object.isRequired,
  type: "error" | "info"
}
InfoDialog.defaultProps = {
  type: "error"
}
export default InfoDialog;
