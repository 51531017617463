import { useState, useContext, useRef, useEffect } from "react";

import * as yup from "yup";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useHistory, useParams } from "react-router-dom";

import sotApi from 'api/sotApi';
import PromptAlert from "components/PromptAlert";
import LoadingPanel from "components/common/TabPanel/LoadingPanel";
import RuleContainer from "../../RuleContainer/RuleContainer";
import useTextRuleJson from "components/sourcedocs/Rules/useTextRuleJson";
import BaseMarketingBulletForm from "./BaseMarketingBulletForm";
import { parseText } from "components/sourcedocs/Rules/utils";
import { SnackbarContext } from "context/SnackbarContext";
import { useSelectionRule } from "../hooks/useSelectionRule";
import { RulesContextProvider } from "../../Rules/context/RulesContext";
import { errorHandler } from "components/common/utils";

const schema = yup.object().shape({
    refid: yup.string().required("The Ref. id field is required.").max(7, "Ref ID cannot be more than 7 characters"),
    disclaimerrefid: yup.string(),
    category: yup.string(),
    icon: yup.string(),
    emailicon: yup.string().nullable(),
    channelsegment: yup.string().test('channelsegment', 'Segment is required', function (value) {
        return value !== "Select Segment"
    }).required("The Segment field is required."),
    type: yup.string().test('type', 'Copy Type is required', function (value) {
        return value !== "Select Bullet Type"
    }).required("The Type field is required."),
    leveltext: yup.string().test('leveltext', 'The Level field is required.', function (value) {
        return value !== "Select Bullet Level"
    }).required("The Level field is required."),
    bullettext: yup.string()
});

const MarketingBulletsForm = () => {
    const { id, action } = useParams()
    const bullet_id = useRef(id)
    const {
        selectionRuleJSON,
        setSelectionRuleJSON,
        baseSelectionRuleJSON,
        setBaseSelectionRuleJSON,
        getSelectionRuleToSend
    } = useSelectionRule()
    const [isLoading, setLoading] = useState(false);
    const [isBlocking, setIsBlocking] = useState(false);
    const [mbObj, setMbObj] = useState(null)
    const { textRulesJSON, setTextRulesJSON, handleVariableChange } = useTextRuleJson()
    const { handleSubmit, control, watch, setValue, setError, getValues, formState } = useForm({
        mode: 'all',
        resolver: yupResolver(schema),
        defaultValues: {
            bullettext: '',
            leveltext: 'Select Bullet Level',
            category: '',
            channelsegment: 'Select Segment',
            icon: '',
            disclaimerrefid: 'None',
            type: 'Select Bullet Type'
        },
    });
    const history = useHistory();
    const watchRefId = watch('refid');
    const { setSnackBarConfig } = useContext(SnackbarContext);

    const handleSelectionRuleJSONChange = (obj) => {
        setSelectionRuleJSON(obj);
        setIsBlocking(true)
    }

    const handleBaseSelectionRuleJSONChange = (obj) => {
        setBaseSelectionRuleJSON(obj);
        setIsBlocking(true)
    }

    const handleTextRulesJSONChange = (obj, isBlocking = true) => {
        setTextRulesJSON(obj)
        setIsBlocking(isBlocking)
    }


    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(async () => {
        if (bullet_id.current) {
            setLoading(true)
            let res;
            try {
                if (action === "clone") {
                    res = await sotApi.marketingBullets.clone(id)
                } else if (action === "details") {

                    res = await sotApi.marketingBullets_pub.get(bullet_id.current)
                } else {
                    res = await sotApi.marketingBullets.get(bullet_id.current)
                }
                setMbObj(res.data)
                bullet_id.current = res.data.marketingbulletid
                const marketingbulletData = {
                    refid: res.data.refid,
                    leveltext: res.data.leveltext,
                    category: res.data.category,
                    channelsegment: res.data.channelsegment,
                    icon: res.data.icon,
                    emailicon: res.data.emailicon,
                    disclaimerrefid: res.data.disclaimerrefid||"None",
                    type: res.data.type,
                    bullettext: res.data.bullettext
                }
                for (const field in schema.fields) {
                    setValue(field, marketingbulletData[field])
                }
                setIsBlocking(false)
                try {
                    if (typeof (res.data["selectionrule_json"]) === 'string') {
                        res.data["selectionrule_json"] = JSON.parse(res.data["selectionrule_json"])
                    }
                    if (res.data["selectionrule_json"].base_operator) {
                        let tempBaseSelectionRuleJSON = { ...baseSelectionRuleJSON }
                        for (const field in baseSelectionRuleJSON) {
                            tempBaseSelectionRuleJSON[field] = res.data["selectionrule_json"][field]
                        }
                        setBaseSelectionRuleJSON(
                            tempBaseSelectionRuleJSON
                        )
                        const exprsIndex = res.data["selectionrule_json"].exprs.findIndex(exprs => !exprs.baseRules)
                        if (exprsIndex >= 0) {
                            setSelectionRuleJSON({ ...res.data["selectionrule_json"].exprs[exprsIndex] })
                        }
                    }
                    else if (res.data["selectionrule_json"].type === "group") {
                        setSelectionRuleJSON({ ...selectionRuleJSON, ...res.data["selectionrule_json"] })
                    } else {
                        setSelectionRuleJSON({ ...selectionRuleJSON, ...res.data["selectionrule_json"].exprs })
                    }

                } catch (error) { }

                if (typeof (res.data["bullettextrule_json"]) === 'string') {
                    res.data["bullettextrule_json"] = JSON.parse(res.data["bullettextrule_json"])
                }
                setTextRulesJSON(res.data["bullettextrule_json"])
            } catch (error) {
                setLoading(false)
            }
            finally {
                setLoading(false)
            }
        }
    }, [])

    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            setIsBlocking(true)
        });
        return () => subscription.unsubscribe();
    }, [watch]);


    const submitMarketingBulletData = async (marketingBulletJSON) => {
        try {
            setLoading(true)
            if (bullet_id.current) {
                await sotApi.marketingBullets.update(bullet_id.current, marketingBulletJSON)
            }
            else {
                await sotApi.marketingBullets.add(marketingBulletJSON)
            }
            setSnackBarConfig({ open: true, message: 'Saved successfully.', severity: 'success' })
            setIsBlocking(false)
            history.push({
                pathname: "/sourcedocs/marketingBullets",
                state: {
                    importSuccess: true,
                },
            });
        } catch (error) {
            if (error.response.status === 400) {
                if (error.response.data?.refid) {
                    setError('refid', {
                        type: "custom",
                        message: error.response.data['refid'][0],
                    })
                }
            } else {
                alert('Something went wrong');
            }
        } finally {
            setLoading(false);
        }
    }

    const onSubmit = (data) => {
        const selectionRuleToSend = getSelectionRuleToSend()
        const marketingBulletJSON = {
            ...data,disclaimerrefid:data?.disclaimerrefid==="None"?null:data?.disclaimerrefid,
            selectionrule_json: JSON.stringify(selectionRuleToSend),
            bullettextrule_json: JSON.stringify(textRulesJSON)
        }
        submitMarketingBulletData(marketingBulletJSON);
    }

    const handleTextBlur = (e) => {
        const text = e.target.value;
        handleVariableChange(parseText(text, textRulesJSON.vars));
    };

    // Todo- move to custom hook
    const handleDeleteClone = async () => {
        try {
            setLoading(true);
            if (bullet_id.current) {
                const payload = {
                    marketingbullet_id_list: [bullet_id?.current]
                }
                await sotApi.marketingBullets.deleteAll(payload);
            }
            history.push({
                pathname: "/sourcedocs/marketingBullets",
            });
        } catch (error) {
            errorHandler(error);
        } finally {
            setLoading(false);
        }
      };

    return (
        <>
            <RulesContextProvider>
                {isLoading && <LoadingPanel />}
                <form onSubmit={handleSubmit(onSubmit)} data-testid="form">
                    <Stack direction="row" justifyContent="space-between">
                        <div>
                            <h4 style={{ color: '#8F8A92', fontSize: '14px', margin: 0 }}>
                                MARKETING BULLETS
                            </h4>
                            <h3 style={{ color: '#050307', fontSize: '29px', margin: 0, textTransform: "capitalize" }} >
                                {action}
                            </h3>
                        </div>
                    </Stack>
                    <div className="new-wrapper">
                        {action !== 'details' && <div style={{ padding: "30px 30px 0" }}>
                            {action === 'clone' && <Button variant="outlined" onClick={handleDeleteClone} style={{marginRight: 20}}>
                                Cancel{" "}
                            </Button>}
                            <Button variant="contained" type="submit">
                                Save Changes{" "}
                            </Button>
                        </div>}
                        <div style={{ padding: 30 }}>
                            <fieldset
                                disabled={action === "details"}
                                style={{
                                    border: 'none',
                                    margin: 0,
                                    padding: 0
                                }}
                            >
                                <BaseMarketingBulletForm
                                    dataObj={mbObj}
                                    action={action}
                                    control={control}
                                    onTextBlur={handleTextBlur}
                                    formState={formState}
                                    watch={watch}
                                />
                            </fieldset>
                        </div>
                    </div>
                    <div className="new-wrapper">
                        <div style={{ marginTop: 30, padding: "10px" }}>
                            <RuleContainer
                                control={control}
                                refText={watchRefId || "Reference ID"}
                                textRulesJSON={textRulesJSON}
                                setTextRulesJSON={handleTextRulesJSONChange}
                                selectionRuleJSON={selectionRuleJSON}
                                setSelectionRuleJSON={handleSelectionRuleJSONChange}
                                baseSelectionRuleJSON={baseSelectionRuleJSON}
                                setBaseSelectionRuleJSON={handleBaseSelectionRuleJSONChange}
                                isDisabled={action === "details"}
                            />
                        </div>
                    </div>
                    <PromptAlert isBlocking={isBlocking} />
                </form>
            </RulesContextProvider>
        </>
    )
};

export default MarketingBulletsForm;
