import React, {useEffect, useState} from "react";

import {DropDownList} from "@progress/kendo-react-dropdowns";
import {Dialog, DialogActionsBar} from "@progress/kendo-react-dialogs";
import {Button} from "@progress/kendo-react-buttons";
import sotApi from "api/sotApi";
import {compareStr, getDropdownOptionsFromObj} from "components/common/utils";

const AddCopyBlockGridDialog = ({
                                  setOpenCopyBlocksDialog,
                                  setCopyBlockRefIdTitle,
                                  setCopyBlockLocation,
                                  handleAddCopyBlock
                                }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const getCopyBlocks = async () => {
      try {
        const res = await sotApi.creativeTypes.copyBlocks.getAll()
        setData(getDropdownOptionsFromObj(res.data.copyblock, [{ key: 'id' }, { key: 'text' }]).sort((a, b) => compareStr(a.text, b.text)))
      } catch (e) {
        alert("Something went wrong!")
      }
    }
    getCopyBlocks();
  }, [])
  return (<Dialog
    title={<b>Add CopyBlock</b>}
    onClose={() => {
      setOpenCopyBlocksDialog(false)
    }}
  >
    <div>
      <DropDownList
        style={{width: "300px"}}
        label={<b>RefId-Title</b>}
        data={data}
        onChange={(e) => {
          setCopyBlockRefIdTitle(e.value.id)
        }}
        textField="text"
        dataItemKey="id"
        defaultItem={{text: "Select RefId Title...."}}
      />
      <div>
        <DropDownList
          style={{width: "300px"}}
          label={<b>Location Code</b>}
          data={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
          defaultItem={"Select Location-----"}
          onChange={(e) => {
            setCopyBlockLocation(e.target.value)
          }}
        />
      </div>
      <div>
        <DialogActionsBar>
          <Button
            onClick={() => {
              setOpenCopyBlocksDialog(false)
            }}>
            Close
          </Button>
          <Button
            className="k-button k-button-solid-base tabButtonSelected"
            onClick={() => {
              handleAddCopyBlock()
              setOpenCopyBlocksDialog(false)
            }}>
            Save
          </Button>
        </DialogActionsBar>
      </div>
    </div>
  </Dialog>)
}
export default AddCopyBlockGridDialog;
