import { useState, createContext, useEffect, useRef } from "react";
import sotApi from "api/sotApi";
import { PY0, PY1 } from "../RuleConstants";
const RulesContext = createContext({ getPlanFields: () => {} })

const RulesContextProvider = ({ children }) => {
    const fields = useRef([]);
    const [fieldsLoading, setFieldsLoading] = useState(false)
    const [filteredFields, setFilteredFields] = useState([])

    useEffect(async () => {
        setFieldsLoading(true)
        try {
            const res = await sotApi.marketingBullets.getExpressionFields()
            fields.current = res.data.mbm_fields?.filter(c => c.dbcolumn !== "PRODUCTMARKET")
            fields.current = [...new Map(fields.current.map(c => [c.displaytext, c])).values()] //get unique fields w.r.t. displaytext
        }
        finally {
            setFieldsLoading(false)
        }
    }, [])

    const getPlanFields = (plan) => {
        let tempFilteredFields = []
        const isPrevYearPlan = [PY0, PY1].includes(plan)
        if (plan && isPrevYearPlan) {
            tempFilteredFields = fields.current.filter(item => item.isprevious)
        }
        else if (plan) {
            tempFilteredFields = [...fields.current]
        }
        setFilteredFields(tempFilteredFields)
    }

    const value = {
        getPlanFields,
        fieldsLoading,
        filteredFields
    }
    return (
        <RulesContext.Provider value={value}>
            {children}
        </RulesContext.Provider>
    )
}

export {
    RulesContext,
    RulesContextProvider,
}
