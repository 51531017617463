import Box from "@mui/material/Box";
import Add from "@mui/icons-material/Add";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import IconButton from '@mui/material/IconButton';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { cloneDeep } from "lodash";

import styles from '../sourcedocs.module.scss';
import RuleExpression from "./RuleExpression";
import { getDefaultCase, groupingActions } from "./RuleConstants";


const RuleBlock = ({ initialBlockValue, onChange, onDelete, canDelete, canSetPredenceDown, onPrecedenceChange, isDisabled }) => {
    const block = { ...initialBlockValue }
    const addExpression = (isTextPostProcessing) => {
        let tempBlock = { ...block }
        tempBlock.cases.push({ ...cloneDeep(getDefaultCase()), isTextPostProcessing: isTextPostProcessing })
        onChange(tempBlock)
    }

    const deleteExpression = (index) => {
        let tempBlock = { ...block }
        tempBlock.cases.splice(index, 1)
        onChange(tempBlock)
    }

    const onExpressionChange = (caseIndex, expression) => {
        let tempBlock = { ...block }
        tempBlock.cases[caseIndex]["exprs"][0] = expression;
        onChange(tempBlock)
    }

    const handleActionChange = (caseIndex, actions) => {
        let tempBlock = { ...block }
        tempBlock.cases[caseIndex]["actions"] = actions;
        onChange(tempBlock)
    }

    return (
        <>
            {
                <Box sx={{ backgroundColor: '#F7F7F9', border: '1px solid #efebf1', borderRadius: "10px", p: 2, mb: 4 }} className={styles.sourceDocsContainer}>
                    {/* Block controls */}
                    <Stack direction="row" justifyContent="space-between">
                        <Stack direction="row" spacing={1}>
                            <IconButton onClick={() => canDelete && onPrecedenceChange("up")} disableRipple={!canDelete} sx={{ borderRadius: '20%', backgroundColor: canDelete ? '#827ac1' : '#b1adb5', color: '#fff' }} aria-label="arrow-up" variant="contained" disabled={isDisabled}>
                                <ArrowUpwardIcon />
                            </IconButton>
                            <IconButton onClick={() => canSetPredenceDown && onPrecedenceChange("down")} disableRipple={!canSetPredenceDown} sx={{ borderRadius: '20%', backgroundColor: canSetPredenceDown ? '#827ac1' : '#b1adb5', color: '#fff' }} aria-label="arrow-down" disabled={isDisabled}>
                                <ArrowDownwardIcon />
                            </IconButton>
                        </Stack>
                        {
                            canDelete && <IconButton
                                sx={{ borderRadius: '20%', backgroundColor: '#827ac1', color: '#fff' }}
                                aria-label="delete"
                                onClick={onDelete}
                                disabled={isDisabled}
                            >
                                <DeleteOutlineIcon />
                            </IconButton>
                        }

                    </Stack>

                    {/* --------- Expressions --------- */}
                    {
                        block.cases.length > 0 ? block.cases.map((caseItem, expressionIndex) => {
                            return (
                                <RuleExpression
                                    isTextPostProcessing={caseItem.isTextPostProcessing}
                                    initialValue={caseItem["exprs"][0]}
                                    actions={caseItem["actions"]}
                                    onActionChange={(actions) => handleActionChange(expressionIndex, actions)}
                                    groupAction={groupingActions.combine}
                                    onDelete={() => deleteExpression(expressionIndex)}
                                    onChange={(updatedExpression) => onExpressionChange(expressionIndex, updatedExpression)}
                                    canDelete={block.cases.length > 1}
                                    disableLogicalOperator={expressionIndex === 0}
                                    isRoot={true}
                                    isDisabled={isDisabled}
                                />
                            )
                        }) : <p>No Expressions are available.</p>
                    }

                    {!block.cases.some(item => item.isTextPostProcessing) && <Stack direction="row" justifyContent="space-between">
                        <Button
                            variant="outlined"
                            startIcon={<Add />}
                            size="large"
                            onClick={() => addExpression(false)}
                            onChange={onExpressionChange}
                            disabled={isDisabled}
                        >
                            Expression
                        </Button>
                        < Button
                            variant="outlined"
                            size="large"
                            onClick={() => addExpression(true)}
                            disabled={isDisabled}
                        >
                            Text Post-Processing
                        </Button>

                    </Stack>}
                </Box>
            }
        </>
    )
}

export default RuleBlock;