import React, { useState } from "react";
import { Route, Redirect } from "react-router-dom";
import auth from "../auth";
import MiniDrawer from "../sidebar/MUISideBar";
import "./style.scss";

export const ParentProtectedRoute = ({ component: Component,
                                   breadcrumb={},
                                   user,
                                   ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (auth.isAuthenticated()) {
          return (

                <Component user={user} {...props} />

          );
        } else {
          return (
            <Redirect
              to={{
                pathname: "/login",
                state: {
                  from: props.location,
                },
              }}
            />
          );
        }
      }}
    />
  );
};

