import React, {useState, useContext} from "react"
import {useHistory} from "react-router-dom";

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import CreativeTypeForm from "./CreativeTypeForm";
import CopyBlockGrid from './CopyBlockGrid';
import sotApi from 'api/sotApi';
import PromptAlert from "components/PromptAlert";
import styles from '../../sourcedocs.module.scss';
import {errorHandler} from "components/common/utils";
import {ApiParams} from '../constants';
import {editButtonList} from '../../constants';
import AddCopyBlockGridDialog from "./AddCopyBlockGridDialog";
import {useDelete} from "components/Administration/hooks";
import DeleteDialog from "components/Administration/DeleteDialog";
import GridHeaderButtons from "components/common/GridHeaderButtons";
import { SnackbarContext } from "context/SnackbarContext";

const EditCreativeType = () => {
  const [isBlocking, setBlocking] = useState(false)
  const [showForm, setShowForm] = React.useState(false)
  const [openCopyBlocksDialog, setOpenCopyBlocksDialog] = React.useState(false)
  const [refId, setRefId] = React.useState();
  const [refidSet, isrefidSet] = React.useState(false)
  const [updatedCreativeObject, setUpdatedCreativeObject] = React.useState({})
  const [copyBlockRefIdTitle, setCopyBlockRefIdTitle] = React.useState("")//dropdown refIdtitle
  const [copyblocklocation, setCopyBlockLocation] = React.useState(0);
  const [selectedRow, setSelectedRow] = React.useState();//to store the values of rows to be deleted--->form copyblock grid
  const [action, setAction] = React.useState()
  const [cloneError, setCloneError] = React.useState(false)
  const [apiCallInProgress, setApiCallInProgress] = React.useState(false)
  const [creativeRefId, setCreativeRefId] = React.useState('')
  /*Clone*/
  const [creativeDetails, setCreativeDetails] = React.useState({});
  const [clonedCreativeType, setClonedCreativeType] = React.useState()
  const [clonedRefId, setClonedRefId] = React.useState('')
  const [copyBlockDetails, setCopyBlockDetails] = React.useState()
  const [creativeError, setCreativeError] = React.useState({
    creativetypename: '',
    copyblockcount: ''
  })
  const {
    singleDeleteDialog,
    onRowDelete,
    resetAction
  } = 
  useDelete();
  const {setSnackBarConfig} = useContext(SnackbarContext); 
  const history = useHistory()

  React.useEffect(() => {
    //to get the refiD from url
    const id = window.location.pathname;
    const action = id.split("/")
    setAction(action[action.length - 2])
    setRefId(id.split("/").pop())
    if (typeof refId != "undefined") {
      isrefidSet(true)
    }
  }, [])
  React.useEffect(() => {
    if (typeof clonedCreativeType != "undefined" && action === "clone") {
      callGetDetailsOfCreativeType()
    }
    if (typeof refId != "undefined" && action === "edit") {
      callGetDetailsOfCreativeType()
    }
  }, [clonedCreativeType, refId])
  React.useEffect(() => {
    setApiCallInProgress(apiCallInProgress)
  }, [apiCallInProgress])
  React.useEffect(() => {
    if (action === "clone") {
      setShowForm(true)
      if (cloneError === false)
        callCloneApi()
    }
  }, [action])
  const callCloneApi = async () => {
    try {
      const res = await sotApi[ApiParams.moduleName].clone(refId)
      setClonedCreativeType(res?.data?.refId)
    } catch (e) {
      alert("Something went wrong")
    }
  }
  const loadingPanel = (
    <div className="k-loading-mask">
      <span className="k-loading-text">Loading</span>
      <div className="k-loading-image"></div>
      <div className="k-loading-color"></div>
    </div>
  );
  const callGetDetailsOfCreativeType = async () => {
    setApiCallInProgress(true)
    try {
      let res;
      if (action === "clone")
        res = await sotApi[ApiParams.moduleName].copyBlocks.get(clonedCreativeType)
      if (action === "edit")
        res = await sotApi[ApiParams.moduleName].copyBlocks.get(refId)
      const data = res.data.results;
      if (data.length > 0 && action === "clone") {
        setClonedRefId(data[0]?.refid)
        setCreativeDetails({
          copyblockcount: data[0].copyblockcount, creativetypename: data[0].creativetypename,
          refid: data[0].refid, modifyuser: data[0].modifyuser, creativetypeid: clonedCreativeType
        })
        setUpdatedCreativeObject({
          copyblockcount: data[0].copyblockcount, creativetypename: data[0].creativetypename,
          refid: data[0].refid, creativetypeid: clonedCreativeType
        })//initial object
      }
      if (data.length > 0 && action === "edit") {
        setCreativeRefId(data[0]?.refid)
        setCreativeDetails({
          copyblockcount: data[0].copyblockcount, creativetypename: data[0].creativetypename,
          refid: data[0].refid, modifyuser: data[0].modifyuser, creativetypeid: data[0].creativetypeid
        })
        setUpdatedCreativeObject({
          copyblockcount: data[0].copyblockcount, creativetypename: data[0].creativetypename,
          refid: data[0].refid, creativetypeid: data[0].creativetypeid
        })//iniitial obj
      }
      let copyBlocks = []
      data[0].copyblocks.filter(cb => Boolean(cb.copyblock)).forEach(item => {
          let record = {...item, copyblockrefid: item.copyblock?.refid, title: item.copyblock?.title}
          copyBlocks.push(record)
        }
      )
      setCopyBlockDetails(copyBlocks)
    } catch (e) {
    } finally {
      setApiCallInProgress(false);
    }
  }
  React.useEffect(() => {
    isrefidSet(true)
  }, [refId])
  const blockingStatus = (status) => {
    setBlocking(status)
  }
  const handleDataFromForm = (data) => {//data from creativetypefor--->data received from CreativeForm
    setBlocking(true)
    if (typeof data != "undefined") {
      Object.keys(data).forEach(key => {
        if (data[key]?.length > 0) {
          setCreativeError(prev => ({...prev, [key]: ''}))
        }
      })
    }
    setUpdatedCreativeObject(data)
  }
  const updateCreativeType = async () => {//update data received form creative form ie updating creative type
    let temp = [];
    setBlocking(false)
    Object.keys(updatedCreativeObject).forEach(key => {
      if (key === "creativetypename" && updatedCreativeObject[key].length > 100) {
        temp.push(`[${key}]`)
      }
      if (key === "creativetypename") {
        if (updatedCreativeObject[key]?.match(/ /g)?.length === updatedCreativeObject[key]?.length) {
          temp.push(`[${key}]`)
          setCreativeError(prevErr => ({...prevErr, [key]: `Creative Type Name is required`}))
        }
      }
      if (typeof updatedCreativeObject[key] === "undefined" || updatedCreativeObject[key].length === 0) {
        temp.push(`[${key}]`)
        if (key === "creativetypename") {
          setCreativeError(prevErr => ({...prevErr, [key]: `Creative Type Name is required`}))
        }
        if (key === "copyblockcount") {
          setCreativeError(prevErr => ({...prevErr, [key]: `Copy Block Count is required`}))
        }
        // setCreativeError(prevErr=>({...prevErr,[key]:`${key} is required`}))
      }
      if (updatedCreativeObject.copyblockcount < 0 || updatedCreativeObject.copyblockcount > 6) {
        temp.push(`[${key}]`)
        setCreativeError(prevErr => ({...prevErr, copyblockcount: `Copy Block Count value should be between 0...6 `}))
      }
      if (isNaN(+updatedCreativeObject.copyblockcount)) {
        temp.push(`[${key}]`)
      }
      const val = (updatedCreativeObject?.copyblockcount + "").split(".")[1] || 0;

      if (val !== 0 || ((updatedCreativeObject.copyblockcount)?.toString().indexOf('.') !== -1)) {
        temp.push(`[${key}]`);
        setCreativeError(prev => ({
          ...prev,
          copyblockcount: `${updatedCreativeObject.copyblockcount} value is not valid for Copy Block Count`
        }))
      }
    })
    if (temp.length === 0) {
      try {
        setBlocking(false)
        await sotApi[ApiParams.moduleName].update(updatedCreativeObject.creativetypeid,
          {
            refid: updatedCreativeObject.refid,
            creativetypename: updatedCreativeObject.creativetypename,
            copyblockcount: updatedCreativeObject.copyblockcount
          });
        setSnackBarConfig({open: true, message: 'Selected creative type updated successfully.', severity: 'success'})
        history.push({
          pathname: ApiParams.baseUrl,
        });
      } catch (e) {
        alert("Something went wrong!")
      }
    }
  }
  const handleAdd = () => {
    setOpenCopyBlocksDialog(true)
  }
  const handleRemove = () => {
    deleteCopyBlock();
  };
  const deleteCopyBlock = async () => {
    const id = Object.keys(selectedRow)
    try {
      const editid = parseInt(id[0])
      const obj = {copyblockid: editid}

      if (action === "clone") {
        await sotApi[ApiParams.moduleName].copyBlocks.delete(clonedCreativeType, obj)
      }
      if (action === "edit") {
        await sotApi[ApiParams.moduleName].copyBlocks.delete(Number(refId), obj)
      }
    } catch (e) {
      alert("Something went wrong!")
    } finally {
      callGetDetailsOfCreativeType()
    }
  }
  const handleAddCopyBlock = async () => {
    try {
        if (action === "clone"&&copyBlockRefIdTitle?.length)
        await sotApi[ApiParams.moduleName].copyBlocks.add(clonedCreativeType, {
          "copyblockid": copyBlockRefIdTitle,
          "copyblocklocation": copyblocklocation
        });
      if (action === "edit"&&copyBlockRefIdTitle?.length){
        
        await sotApi[ApiParams.moduleName].copyBlocks.add(refId, {
          "copyblockid": copyBlockRefIdTitle,
          "copyblocklocation": copyblocklocation
        });
      }
      getCopyBlocksOfCreativeTypes()
      setOpenCopyBlocksDialog(false)
     
    } catch (e) {
alert("Something went wrong!")
    }finally{
      setCopyBlockLocation(0);
      setCopyBlockRefIdTitle("")
    }
  }
  const getCopyBlocksOfCreativeTypes = async () => {
    setApiCallInProgress(true)
    try {
      let res
      if (action === "clone")
        res = await sotApi[ApiParams.moduleName].copyBlocks.get(clonedCreativeType)
      if (action === "edit")
        res = await sotApi[ApiParams.moduleName].copyBlocks.get(refId)
      const data = res.data.results;
      let copyBlocks = []
      data[0].copyblocks.filter(cb => Boolean(cb.copyblock)).forEach(item => {
          let record = {...item, copyblockrefid: item.copyblock?.refid, title: item.copyblock?.title}
          copyBlocks.push(record)
        }
      )
      setCopyBlockDetails(copyBlocks)
    } catch (error) {
      errorHandler(error)
    } finally {
      setApiCallInProgress(false)
    }
  }
  const handleCopyBlockDelete = (data) => {//receiving data from CopyBlockGrid
    setSelectedRow(data)
  }
  const handleCloneDelete = () => {
    setBlocking(false)
    onRowDelete(updatedCreativeObject.creativetypeid, creativeDetails.refid);
  }
  const deleteBulkCreativeTypes = async (ids) => {
    try {
      if (ids.length > 0) {
        await sotApi[ApiParams.moduleName].deleteAll({"creativetypeid": ids});
       
        history.push(ApiParams.baseUrl)
      }
    } catch (err) {
      setClonedRefId(clonedRefId)
      setCloneError(true)
      setAction("clone")
      alert(err.response?.status === 400 ? Object.values(err.response?.data[0])[0] : "Something went wrong");
    } finally {
      setApiCallInProgress(false)
    }
  };
  const handleButtonClick = (value) => {
    switch (value) {
      case 'save':
        setBlocking(false)
        updateCreativeType()
        break;
      case 'add':
        handleAdd();
        break;
      case 'remove':
        handleRemove();
        break;
      case 'deleteClone':
       deleteBulkCreativeTypes([clonedCreativeType])
        break;
      default:
    }
  }
  return <div>
    <PromptAlert isBlocking={isBlocking}/>
    {apiCallInProgress && loadingPanel}
    <h4 style={{color: '#8F8A92', fontSize: '14px', margin: 0}}>CREATIVE TYPES</h4>
    <h3 style={{
      color: '#050307',
      fontSize: '29px',
      margin: 0
    }}>{action === "clone" ? (`${action.charAt(0).toUpperCase() + action.slice(1)}:${clonedRefId}`) : (`${action?.charAt(0).toUpperCase() + action?.slice(1)}:${creativeRefId}`)}
    </h3>
    <div className="new-wrapper">
      <div style={{padding: 25}}>
        <GridHeaderButtons customButtonList={editButtonList.map(item => {
          item.visible = item.includeCloneOnly ? (action === 'clone') : item.visible
          return item;
        })} onButtonClick={handleButtonClick}/>
          {
           <div>
            <CreativeTypeForm
              object={creativeDetails}
              setCreativeDetails={setCreativeDetails}
              disabled={action === "clone" ? false : true}
              blockingStatus={blockingStatus}
              handleDataFromForm={handleDataFromForm}
              error={creativeError}>
            </CreativeTypeForm>
            
          </div>
          }
     
      </div>
    </div>
    {refidSet &&
    <CopyBlockGrid
      creativetyperefid={refId}
      copyBlockDetails={copyBlockDetails}
      handleCopyBlockDelete={handleCopyBlockDelete}
    >
    </CopyBlockGrid>}
    {openCopyBlocksDialog &&
    <AddCopyBlockGridDialog
      setOpenCopyBlocksDialog={setOpenCopyBlocksDialog}
      setCopyBlockRefIdTitle={setCopyBlockRefIdTitle}
      setCopyBlockLocation={setCopyBlockLocation}
      handleAddCopyBlock={handleAddCopyBlock}
    />}
  </div>
}
export default EditCreativeType
