import { MAP_PROD_HOSTNAME } from "constant";
import { axiosInstance } from "../axios";

const isProdEnv = window.location.hostname === MAP_PROD_HOSTNAME;

const supportApi = {
  upload: (dataFile) => {
    return axiosInstance.post(`/api/common/static/s3upload`, dataFile, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  getFiles: (type) => {
    return axiosInstance.get(`/api/common/support/s3files?type=${type}`);
  },
  deleteFile: (payload) => {
    return axiosInstance.delete(`/api/common/support/dels3file`, {
      data: { ...payload },
    });
  },
  streamFileVideo: (filename) => {
    return axiosInstance.get(
      `/api/common/support/videostreaming?bucket=${isProdEnv ? "amp20" : "amp20np"}&filename=${filename}`, {
        responseType: "blob"
      }
    );
  },
  downloadSampleFile: (filename) => {
    return axiosInstance.get(
      `/api/common/static/samples/${filename}`, {
        responseType: "blob"
      }
    );
  },
};

export default supportApi;
