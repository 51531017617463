import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Radio from "@mui/material/Radio";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";

import RuleExpression from '../Rules/RuleExpression';
import { planChoices, productChoices, platformChoices } from '../Rules/RuleConstants';
import { MAP_PROD_HOSTNAME } from "constant";

const isProdEnv = window.location.hostname === MAP_PROD_HOSTNAME;

const SelectionRule = (props) => {
  const {
    selectionRuleJSON,
    setSelectionRuleJSON,
    baseSelectionRuleJSON,
    setBaseSelectionRuleJSON,
    isDisabled
  } = props;
  const handleCheckboxChange = (event, key) => {
    let tempCheckedValues = [...baseSelectionRuleJSON[key]];

    if (event.target.checked) {
      tempCheckedValues.push(event.target.value)
    } else {
      tempCheckedValues = tempCheckedValues.filter(plan => plan !== event.target.value)
    }
    setBaseSelectionRuleJSON({
      ...baseSelectionRuleJSON,
      [key]: [...tempCheckedValues]
    })
  }

  const handleRadioButtonChange = (event, key) => {
    setBaseSelectionRuleJSON({
      ...baseSelectionRuleJSON,
      [key]: event.target.value
    })
  }

  const handleExpressionChange = (updatedExpression) => {
    const tempSelectionRule = { ...selectionRuleJSON }
    setSelectionRuleJSON({ ...tempSelectionRule, ...updatedExpression })
  }

  return (
    <>
      <Box sx={{ mt: 3 }}>
        <fieldset
            disabled={isDisabled}
            style={{
                border: 'none',
                margin: 0,
                padding: 0
            }}
        >
          <Typography variant="h6">
            What Plan, Product, Platform can access this Bullet ?
          </Typography>
          <Box sx={{ backgroundColor: '#F7F7F9', border: '1px solid #efebf1', borderRadius: "10px", p: 2, my: 3 }}>
            <Typography>
              Which plan can access this bullet? Check all that apply.
            </Typography>
            <Stack direction="row" sx={{ flexWrap: "wrap", mt: 1, mb: 4 }}>
              {planChoices?.filter(plan => isProdEnv ? !["AP2", "AP3", "AP4", "AP5"].includes(plan?.value) : true).map((plan) => {
                return (
                  <FormControlLabel
                    key={plan.value}
                    label={plan.label}
                    control={
                      <Checkbox
                        value={plan.value}
                        checked={baseSelectionRuleJSON.plan_choices?.includes(plan.value) || false}
                        onChange={(event) => handleCheckboxChange(event, 'plan_choices')}
                        isDisabled={isDisabled}
                      />
                    }
                  />
                )
              })}
            </Stack>

            <Typography>
              Which product can access this bullet?
            </Typography>
            <Stack sx={{ mb: 4 }}>
              <Stack direction="row" sx={{ mt: 1, mb: 2 }} spacing={0.3}>
                {productChoices.map((product) => (
                  <FormControlLabel
                    key={product.value}
                    label={product.label}
                    control={
                      <Checkbox
                        value={product.value}
                        checked={baseSelectionRuleJSON.products?.includes(product.value) || false}
                        onChange={(event) => handleCheckboxChange(event, 'products')}
                      />
                    }
                  />
                ))}
              </Stack>

              <RadioGroup
                row
                value={baseSelectionRuleJSON.base_operator || null}
                onChange={(event) => handleRadioButtonChange(event, 'base_operator')}
              >
                <FormControlLabel
                  value="and"
                  control={<Radio />}
                  label="AND"
                />
                <FormControlLabel
                  value="or"
                  control={<Radio />}
                  label="OR"
                />
              </RadioGroup>

            </Stack>


            <Typography>
              Which platform can access this bullet?
            </Typography>
            <Stack direction="row" sx={{ mt: 1 }}>
              {platformChoices.map((platform) => (
                <FormControlLabel
                  key={platform.value}
                  label={platform.label}
                  control={
                    <Checkbox
                      value={platform.value}
                      checked={baseSelectionRuleJSON.platform?.includes(platform.value) || false}
                      onChange={(event) => handleCheckboxChange(event, 'platform')}
                    />
                  }
                />
              ))}
            </Stack>
          </Box>
        </fieldset>
      </Box>

      <Box>
        <Typography variant="h6">
          Add any other Rules applicable below
        </Typography>
        <RuleExpression
          initialValue={selectionRuleJSON}
          groupAction={1}
          hideLogicalOperator={true}
          hideResult={true}
          isRoot={true}
          onChange={(updatedExpression) => handleExpressionChange(updatedExpression)}
          isDisabled={isDisabled}
        />
      </Box>
    </>
  )
};

export default SelectionRule;
