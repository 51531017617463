import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import dayjs from "dayjs";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormControl from "@mui/material/FormControl";
import { Controller } from "react-hook-form";

import api from "Api";
import styles from "../../sourcedocs.module.scss";
import useGetDropdowns from "../hooks/useGetDropdowns";
import LoadingPanel from "components/common/TabPanel/LoadingPanel";

const BaseMarketingBulletForm = (props) => {
  const { watch, action, control, dataObj, formState, onTextBlur } = props;

  const {
    disclaimerRefIDOptions,
    categoryOptions,
    segmentOptions,
    typeOptions,
    levelOptions,
    isLoading,
  } = useGetDropdowns(api);

  const isDetails = action === "details";

  const getDisclaimerRefIDInfo = (desclaimerId) => {
    const match = disclaimerRefIDOptions.find(
      (item) => item.id === desclaimerId
    );
    return match ? match.disclaimertext : "";
  };

  return (
    <>
      {isLoading && <LoadingPanel />}
      <Box>
        <Grid
          container
          rowSpacing={2}
          columnSpacing={{ sm: 0, md: 12 }}
          direction={{ sm: "column", md: "row" }}
        >
          <Grid item sm={12} md={6}>
            <div>
              <label className={`required font ${styles.labelText}`}>
                Ref Id
              </label>
            </div>
            <Controller
              name="refid"
              control={control}
              render={({
                field: { onChange, onBlur, value },
                fieldState: { error },
              }) => {
                return (
                  <TextField
                    margin="dense"
                    id="refid"
                    fullWidth
                    size="small"
                    variant="outlined"
                    name="refid"
                    onBlur={onBlur}
                    onChange={onChange}
                    error={error}
                    helperText={error ? error.message : undefined}
                    value={value}
                  />
                );
              }}
            />
          </Grid>
          <Grid item container sm={12} md={6}>
            <Grid item sm={11}>
            <FormControl size="small" fullWidth>
              <div>
                <label className={`font ${styles.labelText}`}>
                  Disclaimer Ref. ID
                </label>
              </div>
              <Controller
                name="disclaimerrefid"
                control={control}
                render={({
                  field: { onChange, onBlur, value },
                  fieldState: { error },
                }) => {
                  return (
                    <Select
                      id="disclaimerrefid"
                      name="disclaimerrefid"
                      input={<OutlinedInput />}
                      renderValue={(selected) => {
                        const match = disclaimerRefIDOptions.find((item) => {
                          if (item.id === selected) return item;
                        });
                        return selected !== "None"
                          ? match?.text
                          : "None";
                      }}
                      onBlur={onBlur}
                      onChange={onChange}
                      error={error}
                      value={value}
                      disabled={isDetails}
                    >
                      {disclaimerRefIDOptions &&
                        disclaimerRefIDOptions.length > 0 &&
                        disclaimerRefIDOptions.map((values) => {
                          return (
                            <MenuItem key={values.id} value={values.id}>
                              {values.text}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  );
                }}
              />
                 </FormControl>   
            </Grid>
            <Grid
              item
              sm={1}
              sx={{
                display: "flex",
                alignItems: formState.errors?.refid ? "center" : "flex-end",
                paddingTop:formState.errors?.refid?0:1,
                paddingBottom:formState.errors?.refid?2:1
              }}
            >
              <IconButton>
                <Tooltip
                  title={getDisclaimerRefIDInfo(watch("disclaimerrefid", ""))}
                >
                  <InfoOutlinedIcon fontSize="large" />
                </Tooltip>
              </IconButton>
            </Grid>
          </Grid>
          <Grid item sm={12} md={6}>
            <div>
              <label className={`font ${styles.labelText}`}>Category</label>
            </div>
            <Controller
              name="category"
              control={control}
              render={({
                field: { onChange, onBlur, value },
                fieldState: { error },
              }) => {
                return (
                  <TextField
                    margin="dense"
                    id="category"
                    fullWidth
                    select
                    size="small"
                    variant="outlined"
                    name="category"
                    onBlur={onBlur}
                    onChange={onChange}
                    error={error}
                    helperText={error ? error.message : undefined}
                    value={value}
                    disabled={isDetails}
                  >
                    {categoryOptions &&
                      categoryOptions.length > 0 &&
                      categoryOptions.map((values) => {
                        return (
                          <MenuItem key={values.id} value={values.id}>
                            {values.text}
                          </MenuItem>
                        );
                      })}
                  </TextField>
                );
              }}
            />
          </Grid>
          <Grid item sm={12} md={6}>
            <div>
              <label className={`font ${styles.labelText}`}>Icon</label>
            </div>
            <div>
              <Controller
                name="icon"
                control={control}
                render={({
                  field: { onChange, onBlur, value },
                  fieldState: { error },
                }) => {
                  return (
                    <TextField
                      disabled={false}
                      margin="dense"
                      id="icon"
                      fullWidth
                      size="small"
                      variant="outlined"
                      name="icon"
                      onBlur={onBlur}
                      onChange={onChange}
                      error={error}
                      helperText={error ? error.message : undefined}
                      value={value}
                    />
                  );
                }}
              />
            </div>
          </Grid>
          <Grid item sm={12} md={6}>
            <FormControl size="small" fullWidth>
              <div>
                <label className={`font required ${styles.labelText}`}>Segment</label>
              </div>
              <Controller
                name="channelsegment"
                control={control}
                render={({
                  field: { onChange, onBlur, value },
                  fieldState: { error },
                }) => {
                  return (
                    <Select
                        id="channelsegment"
                        name="channelsegment"
                        input={<OutlinedInput />}
                        renderValue={(selected) => {
                          const match = segmentOptions.find((item) => {
                            if (item.id === selected) return item;
                          });
                          return selected !== "Select Segment"
                            ? match?.text
                            : "Select Segment";
                        }}
                        onBlur={onBlur}
                        onChange={onChange}
                        error={error}
                        value={value}
                        disabled={isDetails}
                      >
                        {segmentOptions &&
                          segmentOptions.length > 0 &&
                          segmentOptions.map((values) => {
                            return (
                              <MenuItem key={values.id} value={values.id}>
                                {values.text}
                              </MenuItem>
                            );
                          })}
                      </Select>
                  );
                }}
              />
              {formState?.errors?.channelsegment?.message && (
                <label
                  style={{
                    color: "#d32f2f",
                    fontweight: "400",
                    fontSize: "0.75rem",
                  }}
                >
                  {formState?.errors?.channelsegment?.message}
                </label>
              )}
            </FormControl>
          </Grid>
          <Grid item sm={12} md={6}>
            <div>
              <label className={`font ${styles.labelText}`}>Email Icon</label>
            </div>
            <Controller
              name="emailicon"
              control={control}
              render={({
                field: { onChange, onBlur, value },
                fieldState: { error },
              }) => {
                return (
                  <TextField
                    disabled={false}
                    margin="dense"
                    id="emailicon"
                    fullWidth
                    size="small"
                    variant="outlined"
                    name="emailicon"
                    multiline
                    onBlur={onBlur}
                    onChange={onChange}
                    error={error}
                    helperText={error ? error.message : undefined}
                    value={value}
                  />
                );
              }}
            />
          </Grid>
          <Grid item sm={12} md={6}>
            <FormControl size="small" fullWidth>
              <div>
                <label className={`required font ${styles.labelText}`}>
                  Type
                </label>
              </div>
              <Controller
                name="type"
                control={control}
                render={({
                  field: { onChange, onBlur, value },
                  fieldState: { error },
                }) => {
                  return (
                    <Select
                      id="type"
                      name="type"
                      input={<OutlinedInput />}
                      renderValue={(selected) => {
                        const match = typeOptions.find((item) => {
                          if (item.id === selected) return item;
                        });
                        return selected !== "Select Bullet Type"
                          ? match?.text
                          : "Select Bullet Type";
                      }}
                      onBlur={onBlur}
                      onChange={onChange}
                      error={error}
                      value={value}
                      disabled={isDetails}
                    >
                      {typeOptions &&
                        typeOptions.length > 0 &&
                        typeOptions.map((values) => {
                          return (
                            <MenuItem key={values.id} value={values.id}>
                              {values.text}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  );
                }}
              />
             { formState?.errors?.type?.message&&<label
                style={{
                  color: "#d32f2f",
                  fontweight: "400",
                  fontSize: "0.75rem",
                }}
              >
                {
                   formState?.errors?.type?.message
                  }
              </label>}
            </FormControl>
          </Grid>
          <Grid item sm={12} md={6}>
            <FormControl size="small" fullWidth>
              <div>
                <label className={`required font ${styles.labelText}`}>
                  Level
                </label>
              </div>
              <Controller
                name="leveltext"
                control={control}
                render={({ field, fieldState: { error } }) => {
                  return (
                    <Select
                      id="leveltext"
                      name="leveltext"
                      input={<OutlinedInput />}
                      renderValue={(selected) => {
                        const match = levelOptions.find((item) => {
                          if (selected === item.id) {
                            return item;
                          }
                        });
                        return selected !== "Select Bullet Level"
                          ? match?.text
                          : "Select Bullet Level";
                      }}
                      error={error}
                      {...field}
                      disabled={isDetails}
                    >
                      {levelOptions &&
                        levelOptions.length > 0 &&
                        levelOptions.map((values) => {
                          return (
                            <MenuItem key={values.id} value={values.id}>
                              {values.text}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  );
                }}
              />
              {formState?.errors?.leveltext?.message && (
                <label
                  style={{
                    color: "#d32f2f",
                    fontweight: "400",
                    fontSize: "0.75rem",
                  }}
                >
                  {formState?.errors?.leveltext?.message}
                </label>
              )}
            </FormControl>
          </Grid>
          {dataObj && (
            <Grid item sm={12} md={6}>
              <div>
                <label className={`font ${styles.labelText}`}>
                  Last Updated By
                </label>
              </div>
              <div>
                <Typography variant="subtitle1">
                  {dataObj?.modifyusername}
                </Typography>
              </div>
            </Grid>
          )}
          {dataObj && (
            <Grid item sm={12} md={6}>
              <div>
                <label className={`font ${styles.labelText}`}>
                  Last Updated Date
                </label>
              </div>
              <div>
                <Typography variant="subtitle1">
                  {dayjs(
                    dataObj?.modifydatetime || dataObj?.createdatetime
                  ).format("YYYY/MM/DD hh:mm:ss A")}
                </Typography>
              </div>
            </Grid>
          )}
          <Grid item sm={12}>
            <div>
              <label className={`font ${styles.labelText}`}>Bullet text</label>
            </div>
            <Controller
              name="bullettext"
              control={control}
              render={({ field: { onBlur, onChange, value } }) => {
                return (
                  <TextField
                    id="bulletText"
                    fullWidth
                    size="small"
                    variant="outlined"
                    name="bulletText"
                    placeholder="Enter Bullet Text"
                    multiline
                    value={value}
                    onBlur={(e) => {
                      onBlur(e.target.value);
                      onTextBlur(e);
                    }}
                    onChange={(e) => {
                      onChange(e.target.value);
                    }}
                    disabled={isDetails}
                  />
                );
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default BaseMarketingBulletForm;
