import { useState } from "react"

import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";

import {
  Dialog as KendoDialog,
  DialogActionsBar,
} from "@progress/kendo-react-dialogs";
import Button from "@mui/material/Button"
import TextField from "@mui/material/TextField"

function DeleteDialog({ title, bodyText, onClose, onConfirm, isConfirm, refIds, errorText = null, atttrText= "Ref ID" }) {
  const [confirmText, setConfirmText] = useState("")

  return <KendoDialog
    title={<div className="k-window-title k-dialog-title">{title}</div>}
    onClose={onClose}
  >
    <div
      style={{ margin: "25px", textAlign: "center", fontSize: "20px", color: "black" }}
    >
      {bodyText}
      {!!refIds && <div style={{ marginTop: '10px' }}><b>{atttrText}: {refIds.join(', ')}</b></div>}
    </div>

    {
      isConfirm &&
      <TextField id="confirmDelete" data-testid="confirmDelete" placeholder="Write 'Delete'" size="small" fullWidth onChange={(e) => setConfirmText(e.target.value)} />
    }

    {
      errorText && errorText.map((error) => {
        return <Typography sx={{ color: 'red', mt: 2 }}>
          {error}
        </Typography>
      })
    }

    <DialogActionsBar layout="end">
      {
        <>
          <Button
            variant="outlined"
            data-testid="button-close"
            onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            data-testid="button-confirm"
            {...(isConfirm && { disabled: confirmText !== "Delete" })}
            onClick={onConfirm}
          >
            {`${!isConfirm ? "Confirm" : "Confirm Deletion"}`}
          </Button>
        </>
      }
    </DialogActionsBar>
  </KendoDialog>
}

DeleteDialog.propTypes = {
  title: PropTypes.string.isRequired,
  bodyText: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  isConfirm: PropTypes.bool,
  refIds: PropTypes.arrayOf(PropTypes.string)
}
export default DeleteDialog