import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import dayjs from "dayjs";

import EditForm from "./EditForm";
import api from "Api";
import { toTitleCase } from "components/common/utils";
import styles from "../../sourcedocs.module.scss";
import InfoDialog from "../../../common/Dialogs/InfoDialog";

const EditCreativeData = () => {
  const [showForm, setShowForm] = useState(false);
  const [isCallEditApi, setIsCallEditApi] = useState(false);
  const [action, setAction] = useState();
  const [id, setId] = useState();
  const [dataObject, setDataObject] = useState({ refid: "" });
  const [cloneid, setcloneid] = useState();
  const [cloningErrors, setCloningErrors] = useState(null);

  const history = useHistory();

  useEffect(() => {
    const id = window.location.pathname;
    const action = id.split("/");
    setId(id.split("/").pop());
    setAction(action[action.length - 2]);
  }, []);

  const loadingPanel = (
    <div className="k-loading-mask">
      <span className="k-loading-text">Loading</span>
      <div className="k-loading-image"></div>
      <div className="k-loading-color"></div>
    </div>
  );

  useEffect(async () => {
    if (typeof cloneid != "undefined") {
      api
        .getCreativeData(cloneid)
        .then((res) => {
          setDataObject(res?.data);
        })
        .catch((err) => {
          alert("Something went wrong!");
        });
    }
  }, [cloneid]);

  useEffect(async () => {
    setIsCallEditApi(true);
    if (action === "edit") {
      try {
        const res = await api.getCreativeData(id);
        setDataObject(res?.data);
        setIsCallEditApi(true);
      } catch (e) {
        alert("Something with wrong!");
      } finally {
        setIsCallEditApi(false);
      }
    }
    if (action === "clone") {
      api
        .cloneCreativeData(id)
        .then((res) => {
          setcloneid(res?.data?.creativedataid);
          setIsCallEditApi(true);
        })
        .catch((err) => {
          if (err.response?.status === 400 && err.response?.data) {
            const errorMsg = err.response?.data.join("; ");
            setCloningErrors(err.response?.data);
          } else {
            alert("Something went wrong");
          }
        })
        .finally(() => {
          setIsCallEditApi(false);
        });
    }
  }, [id]);
  const closeErrorDialoge = () => {
    setCloningErrors(null);
    history.push("/sourcedocs/creativeData");
  };
  return (
    <>
      <InfoDialog
        openDialog={Boolean(cloningErrors)}
        setOpenDialog={closeErrorDialoge}
        messageInfo={{ title: "Error", message: cloningErrors }}
      />
      <h4 style={{ color: "#8F8A92", fontSize: "14px", margin: 0 }}>
        Creative Data
      </h4>
      <h3 style={{ color: "#050307", fontSize: "29px", margin: 0 }}>
        {action && toTitleCase(action)}: {dataObject.refid}
      </h3>
      <div className={`newgeography-wrapper ${styles.sourceDocsContainer}`}>
        <div style={{ padding: 30 }}>
          {isCallEditApi && loadingPanel}  
            <div>
              <EditForm
                dataObj={dataObject}
                action={action}
                disabled={action === "clone" ? false : true}
              ></EditForm> 
        </div>
      </div>
      </div>
    </>
  );
};
export default EditCreativeData;
