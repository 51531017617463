const {PRIVILEGES} = require("../../constant");
export const  APPLICATION_URL_PRIVILEGE ={

    //APP SERVICE URL
    '/admin/appServices':[PRIVILEGES.SystemAdmin,PRIVILEGES.JobManager,PRIVILEGES.AppServiceAdmin],

    //DIGITAL TARGETING URL
    '/admin/digtalTargeting/dataModel':[PRIVILEGES.SystemAdmin,PRIVILEGES.DigitalContentAdmin],
    '/admin/digitalTargeting/manageFields':[PRIVILEGES.SystemAdmin,PRIVILEGES.DigitalContentAdmin],
    '/admin/digtalMarketing/viewDataModel':[PRIVILEGES.SystemAdmin,PRIVILEGES.DigitalContentAdmin],
    '/admin/digitalTargeting/publishDigitalOutput':[PRIVILEGES.SystemAdmin,PRIVILEGES.DigitalContentAdmin],

    //SECURITY URL
    '/admin/groups':[PRIVILEGES.SystemAdmin,PRIVILEGES.GroupAdmin],
    '/admin/groups/edit/:groupId':[PRIVILEGES.SystemAdmin,PRIVILEGES.GroupAdmin],
    '/admin/roles':[PRIVILEGES.SystemAdmin],
    '/admin/roles/edit/:roleId':[PRIVILEGES.SystemAdmin],
    '/admin/users':[PRIVILEGES.SystemAdmin,PRIVILEGES.SecurityAdmin],
    '/admin/users/edit/:userId':[PRIVILEGES.SystemAdmin,PRIVILEGES.SecurityAdmin],

    //MESSAGE BY MARKET
    '/admin/messageByMarket/prodOutputFields':[PRIVILEGES.SystemAdmin,PRIVILEGES.MbmManager],
    '/admin/messageByMarket/mbmfields':[PRIVILEGES.SystemAdmin,PRIVILEGES.MbmManager],
    '/admin/messageByMarket/publishMbm':[PRIVILEGES.SystemAdmin,PRIVILEGES.MbmPublisher],
    '/admin/messageByMarket/channelSegmentsUpload':[PRIVILEGES.InternalAdmin],

    //LOOKUPS
    '/admin/lookups/categories':[PRIVILEGES.SystemAdmin],
    '/admin/lookups/types':[PRIVILEGES.SystemAdmin],
    '/admin/lookups/values':[PRIVILEGES.SystemAdmin,PRIVILEGES.LookupManager],
    '/admin/lookups/notificationTypes':[PRIVILEGES.SystemAdmin,PRIVILEGES.LookupManager],
    '/admin/lookups/snippet':[PRIVILEGES.SystemAdmin,PRIVILEGES.LookupManager],
    '/admin/lookups/mediachannelimport': [PRIVILEGES.SystemAdmin],
    '/admin/lookups/mediachannelexport' : [PRIVILEGES.SystemAdmin],


    //TFN
    '/tfn/tfnDashboard':[PRIVILEGES.SystemAdmin,PRIVILEGES.TFNManager,PRIVILEGES.TFNMarketing,PRIVILEGES.TFNTelesales,
                 PRIVILEGES.TFNRequestor,PRIVILEGES.TFNRequestorUHHS,PRIVILEGES.TFNCallCenter],
    '/tfn/tfnRequests':[PRIVILEGES.SystemAdmin,PRIVILEGES.TFNRequestor,PRIVILEGES.TFNMarketing,PRIVILEGES.TFNCallCenter,PRIVILEGES.TFNTelesales,PRIVILEGES.TFNRequestorUHHS],

    '/tfn/viewTFN':[PRIVILEGES.SystemAdmin,PRIVILEGES.TFNManager],
    '/tfn/viewTFNs/upload':[PRIVILEGES.SystemAdmin,PRIVILEGES.TFNManager],
    '/import':[PRIVILEGES.SystemAdmin,PRIVILEGES.TFNManager],


    //REPORTS
    '/reports/tfn':[PRIVILEGES.SystemAdmin,PRIVILEGES.TFNReporter],
    '/reports/sot':[PRIVILEGES.SystemAdmin,PRIVILEGES.ReportViewer, PRIVILEGES.SOTReportViewer],
    '/reports/gtm':[PRIVILEGES.SystemAdmin,PRIVILEGES.ReportViewer, PRIVILEGES.GTMReportViewer],
    '/reports/leadZip':[PRIVILEGES.SystemAdmin,PRIVILEGES.ReportViewer, PRIVILEGES.LeadZipReportViewer],
    '/report/mbm':[PRIVILEGES.SystemAdmin,PRIVILEGES.ReportViewer, PRIVILEGES.MBMReportViewer],
    '/approvals':[PRIVILEGES.SystemAdmin,PRIVILEGES.ApprovalAdmin,PRIVILEGES.TFNTester,PRIVILEGES.ApprovalManager],


    //SOURCE DOCUMENT
    '/sourcedocs/creativeData':[PRIVILEGES.SystemAdmin,PRIVILEGES.SourceDocManager],
    '/sourcedocs/creativeTypes':[PRIVILEGES.SystemAdmin,PRIVILEGES.SourceDocManager],
    '/sourcedocs/geography':[PRIVILEGES.SystemAdmin,PRIVILEGES.SourceDocManager],
    '/sourcedocs/productData':[PRIVILEGES.SystemAdmin,PRIVILEGES.SourceDocManager], 
    '/sourcedocs/providersData':[PRIVILEGES.SystemAdmin,PRIVILEGES.SourceDocManager,PRIVILEGES.ProviderOperator,PRIVILEGES.TestAdmin],
    '/sourcedocs/marketingBullets':[PRIVILEGES.SystemAdmin,PRIVILEGES.SourceDocManager,PRIVILEGES.TestAdmin],
    '/sourcedocs/valProps':[PRIVILEGES.SystemAdmin,PRIVILEGES.SourceDocManager],
    '/sourcedocs/disclaimers':[PRIVILEGES.SystemAdmin,PRIVILEGES.SourceDocManager],
    '/sourcedocs/snap':[PRIVILEGES.SystemAdmin,PRIVILEGES.SourceDocManager],


    //Document

    '/documents':[PRIVILEGES.SystemAdmin,PRIVILEGES.MbmManager,PRIVILEGES.MbmEditor],
    '/documents/gtm':[PRIVILEGES.SystemAdmin,PRIVILEGES.MbmManager,PRIVILEGES.MbmEditor],
    '/documents/leadzip':[PRIVILEGES.SystemAdmin,PRIVILEGES.MbmManager,PRIVILEGES.MbmEditor],
    '/documents/mbm':[PRIVILEGES.SystemAdmin,PRIVILEGES.MbmManager,PRIVILEGES.MbmEditor],
    '/documents/mbm/upload':[PRIVILEGES.SystemAdmin,PRIVILEGES.MbmManager,PRIVILEGES.MbmEditor],
    '/documents/jobStatus': [PRIVILEGES.SystemAdmin, PRIVILEGES.MbmManager, PRIVILEGES.MbmEditor, PRIVILEGES.MbmPublisher, PRIVILEGES.MbmPublishScheduleAdmin, PRIVILEGES.SourceDocManager, PRIVILEGES.TFNManager, PRIVILEGES.ApprovalAdmin, PRIVILEGES.JobManager]

}
