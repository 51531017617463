import { axiosInstance } from "../axios.js";

const gtmApi = {
  getGTMSearchPlatforms: (channel_type_value) => {
    return channel_type_value
      ? axiosInstance.get(
          `/api/gtm/platforms?ct=${channel_type_value}`
        )
      : axiosInstance.get(`/api/gtm/platforms`);
  },
  getGTMSearchPlans: (body, channel_type_value) => {
    return axiosInstance.put(
      `/api/gtm/plans/query/?no_page=True`,
      body
    );
  },
  loadGTMProductTab: (body) => {
    return axiosInstance.put(
      `/api/gtm/gtmproducts/query/?limit=${body.limit}&offset=${body.offset}`,
      body.payload
    );
  },
  getGTMSearchProducts: (channel_type_value) => {
    return channel_type_value
      ? axiosInstance.get(
          `/api/gtm/products?ct=${channel_type_value}`
        )
      : axiosInstance.get(`/api/gtm/products`);
  },
  getGTMSearchPremiumIndicators: () => {
    return axiosInstance.get(`/api/gtm/premium_indicators`);
  },
  getGTMSearchChannel: (channel_type_value) => {
    return channel_type_value
      ? axiosInstance.get(
          `/api/common/channel?ct=${channel_type_value}`
        )
      : axiosInstance.get(`/api/common/channel`);
  },
  getGTMSearchSegment: (body) => {
    if(body && body.department)

      return axiosInstance.get(`/api/common/channel/segments?ct=${body.department}`);
    else
      return axiosInstance.get(`/api/common/channel/segments`);
  },
  getGTMSegmentByType: (body) => {
      return axiosInstance.get(`/api/common/channel/segmentsdept?ct=${body.department}&st=${body.segmentType}`);
  },
  getGTMSearchRegion: () => {
    return axiosInstance.get(`/api/common/geography/regions`);
  },
  getGTMSearchMarket: () => {
    return axiosInstance.get(`/api/common/geography/markets`);
  },
  getGTMSearchSubMarket: () => {
    return axiosInstance.get(`/api/common/geography/submarkets`);
  },
  getGTMSearchCounty: () => {
    return axiosInstance.get(`/api/common/geography/counties`);
  },
  getGTMSearchFilteredCounty:(body)=>{
    return axiosInstance.put(`/api/common/geography/counties`,body);
},
  loadGTMData: (body) => {
    return axiosInstance.put(
      `/api/gtm/gtm/query/?no_page`,
      body.payload
    );
  },
  loadGTMDataWithPagination: (body) => {
    return axiosInstance.put(
      `/api/gtm/gtm/query/?limit=${body.limit}&offset=${body.offset}`,
      body.payload
    );
  },
  loadLPGLookups: (body) => {
    return axiosInstance.put(
      `/api/common/lookups/query/?no_page=true`,
      body
    );
  },
  loadLeadcreativeData: () => {
    return axiosInstance.get(
      '/api/common/lookups/by-category-name/?category=CreativeData&lookupname=LeadCreative'
    )
  },
  loadPremiumIndicatorData: () => {
    return axiosInstance.get(
      '/api/gtm/premium_indicator_lookups'
    )
  },
  loadLPGRules: () => {
    return axiosInstance.get(
      `/api/common/rules/rules_json/?category=gtm&rule_name=lpg`
    );
  },
  saveGTMData: (body) => {
    return axiosInstance.put(`/api/gtm/gtm/gotomarket_crd/`, body);
  },
  bulkSaveGTMData: (body) => {
    return axiosInstance.put(`/api/gtm/gtm/bulk_crd/`, body);
  },
  deleteGTMData: (body) => {
    return axiosInstance.put(
      `/api/gtm/gtm/gotomarket_crd/?delete=true/`,
      body
    );
  },
  getChannelSegmentsRulesJson: () => {
    return axiosInstance.get(
      `/api/common/rules/rules_json/?category=gtm&rule_name=channel_segments`
    );
  },
  getFipsCodesByCounty: (body) => {
    return axiosInstance.put(`/api/common/geography/fips`, body);
  },
  getSupressCountyByFips: (body) => {
    return axiosInstance.put(`/api/common/channel/suppress`, body);
  },
  deleteAllGTMSelection: (body) => {
    return axiosInstance.put(
      `/api/gtm/gtm/gotomarket_delete_all/`,
      body
    );
  },
  loadPlans: (body) => {
    return axiosInstance.put(
      `/api/gtm/plans/search/`,
      body
    );
  },
  loadPremium: (body) => {
    return axiosInstance.put(
      `/api/gtm/plans/premium/query/`,
      body
    );
  },
  loadGeography: (body) => {
    return axiosInstance.put(
      `/api/gtm/plans/geography/query/`,
      body
    );
  },  
  fetchLeadzipSegments: () => {
    return axiosInstance.get(
      `/api/common/channel/leadzip_segments`
    )
  },
  providersQuery: (body) => {
    return axiosInstance.put(`/api/common/providers/query/?no_page`, body);
  },
  getFilteredState:(body)=>{
    return axiosInstance.put("/api/common/geography/states",body)
  },
  getMissingLeadPlanReport:(body)=>{
    return axiosInstance.post('/api/gtm/reports/missing-lead-plan',body,{
       responseType: "blob",
    })
  },
  getLeadPlanGraphicAuditReport: (body) => {
    return axiosInstance.post('/api/gtm/gtm-reports/lead-plan-graphic-audit-report/', body, {
      responseType: "blob",
    })
  },
  getVersionReport:(body)=>{
    return axiosInstance.post('/api/gtm/gtm-reports/gtm-versioning-report/',body,{
      responseType: "blob",
    })
  },
  getDepartments: ()=>{
    return axiosInstance.get('/api/account/departments')
  },
  getDepartmentsByPriv: ()=>{
    return axiosInstance.get('/api/common/channel/by_privs')
  },
};

export default gtmApi;
