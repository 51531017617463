import { useContext, useState, useRef } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { Button, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";

import theme from "theme";
import CircularProgress from "@mui/material/CircularProgress";
import { SnackbarContext } from "context/SnackbarContext";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import Grid from "@mui/material/Grid";
import supportApi from "api/supportApi";
import { errorHandler } from "components/common/utils";

const useStyles = makeStyles({
  label: {
    paddingRight: 20,
    wordBreak: "break-word",
  },
  button: {
    marginTop: 40,
    width: "fit-content"
  },
  grid: {
    textAlign: "left",
    width: "100%",
  },
  dropDownList: {
    width: 200,
    border: "1px solid #CCC",
    borderRadius: 5,
    height: 40,
    '& .k-input': {
      height: "100%"
    }
  },
  inputText: {
    width: 300,
    height: 45,
    paddingLeft: 5,
    borderRadius: 5,
    border: "1px solid #CCC",
    padding: "0 10px",
    fontSize: "14px"
  },
});

const Support = () => {
  const theming = theme();
  const classes = useStyles();
  const [fileName, setFileName] = useState("");
  const [fileType, setFileType] = useState(undefined);
  const [selectedFile, setSelectedFile] = useState(null);
  const [label, setLabel] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { setSnackBarConfig } = useContext(SnackbarContext);
  const uploadFile = useRef(null);

  const handleFileUpload = async (e) => {
    setIsLoading(true);
    setLabel(selectedFile?.name);
    const formData = new FormData();
    formData.append("File", selectedFile);
    formData.append("Title", fileName);
    formData.append("FileType", fileType?.value);
    try {
      await supportApi.upload(formData);
      setSnackBarConfig({
        open: true,
        message: "File uploaded successfully.",
        severity: "success",
      });
    } catch (error) {
      errorHandler(error);
    } finally {
      setLabel("");
      setSelectedFile(null);
      setFileName("");
      setFileType(undefined);
      setIsLoading(false);
    }
  };

  const handleChange = (e) => {
    setFileType(e.value);
  };

  return (
    <>
      <Box
        sx={{
          color: theming.palette.secondary.main,
          textTransform: "uppercase",
        }}
        my={theming.spacing(0)}
        padding={theming.spacing(0)}
      ></Box>
      <Box
        sx={{
          color: theming.palette.secondary.dark,
          fontWeight: 500,
          fontSize: "1.80rem",
        }}
        mb={theming.spacing(4)}
        padding={theming.spacing(0)}
      >
        Upload
      </Box>

      <Paper elevation={0}>
        {isLoading ? (
          <Box display={"flex"} justifyContent={"center"} pt={"10rem"}>
            <CircularProgress color={"primary"} />
            <h4>Uploading..</h4>
            <label>{label}</label>
          </Box>
        ) : (
          <Box textAlign="center" p={"2rem"}>
            <Grid
              container
              spacing={2}
              flexDirection="column"
              className={classes.grid}
            >
              <Grid item>
                <input
                  type="file"
                  id="fileUploadInput"
                  className="input-hide"
                  data-testid="upload"
                  onChange={(e) => setSelectedFile(e.target?.files[0])}
                  ref={uploadFile}
                />
                <button
                  style={{width: "auto", height: "35px", padding: "0 10px", display: "inline-flex", alignItems: "center", marginRight: "5px"}}
                  className="uploadfile-button"
                  id="uploadfile-button"
                  data-testid="button-click"
                  onClick={(e) => {
                    uploadFile.current.click();
                  }}
                >
                  <label style={{fontSize: "16px", marginLeft: "5px", cursor: "pointer"}}> Select File</label>
                </button>
                <label className={`required font ${classes.label}`}></label>
                <Typography style={{marginLeft: "10px", display: "inline-flex"}}>{selectedFile?.name}</Typography>
              </Grid>
              <Grid item>
                <label className={`required font ${classes.label}`}>
                  File Name:
                </label>
                <input
                  className={classes.inputText}
                  type="text"
                  name="refid"
                  id="refid"
                  data-testid="input"
                  value={fileName}
                  onChange={(e) => setFileName(e.target.value)}
                  placeholder="Avoid special characters (-_@#/\^*&.)"
                />
              </Grid>
              <Grid item>
                <label className={`required font ${classes.label}`}>
                  File Type:
                </label>
                <DropDownList
                  name="channel"
                  id="channel"
                  className={classes.dropDownList}
                  data={[
                    {
                      text: "Document",
                      value: "Document",
                    },
                    {
                      text: "Video",
                      value: "Video",
                    },
                  ]}
                  textField="text"
                  dataItemKey="value"
                  onChange={handleChange}
                  value={fileType}
                />
              </Grid>

              <Button
                variant="contained"
                data-testid="upload-button"
                onClick={handleFileUpload}
                color="primary"
                id="uploadFile"
                className={classes.button}
                disabled={!(fileType?.value && selectedFile && fileName)}
              >
                <FileUploadOutlinedIcon/>
                <label style={{fontSize: "16px", marginLeft: "5px", cursor: "pointer"}}> Upload</label>
              </Button>
            </Grid>
          </Box>
        )}
      </Paper>
    </>
  );
};

export default Support;
