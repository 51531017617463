import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';

const RuleSummary = ({ rulesJSON, isSelectionSummary = false }) => {

    const renderCondition = (condition) => {
        return (
            <Typography variant={!isSelectionSummary ? 'body1' : 'caption'} sx={{ marginBottom: !isSelectionSummary ? 2 : 0 }}>
                {
                    condition.pln + ': ' +
                    condition.mbrText + " " +
                    condition.opText + " " + condition.val
                }
            </Typography>
        )
    }

    const renderExpressions = (exprs, actions) => {
        return <Stack direction="row" justifyContent="space-between" sx={{ borderBottom: actions && exprs.exprs.length > 1 ? '1px solid #e0e3e0' : '' }}>
            <Stack sx={{ width: actions ? '50%' : '100%' }}>
                {
                    !isSelectionSummary && exprs.type === 'group' &&
                    <Typography variant='h6' gutterBottom sx={{ color: '#108512' }}>
                        {exprs.exprs.length > 0 ? 'IF' : 'ELSE'}
                    </Typography>
                }
                {
                    exprs.exprs?.map((expr, i) =>
                        <>
                            <Box sx={{ marginLeft: exprs?.baseRules ? 0 : (!isSelectionSummary ? 4 : 2) }}>
                                {expr.type === 'group' ? renderExpressions(expr) : renderCondition(expr)}
                                {
                                    i !== exprs.exprs.length - 1 &&
                                    <Box sx={{ marginBottom: !isSelectionSummary ? 2 : 0, marginLeft: exprs?.baseRules ? (!isSelectionSummary ? 4 : 2) : 0 }}>
                                        {exprs?.op?.toUpperCase()}
                                    </Box>
                                }
                            </Box>
                        </>
                    )
                }
            </Stack>
            {actions && <Stack sx={{ width: actions ? '50%' : '100%' }}>
                {
                    exprs.exprs.length > 0 &&
                    <Typography variant='h6' gutterBottom sx={{ color: '#108512' }}>
                        THEN
                    </Typography>
                }

                <Typography variant='body1' gutterBottom>
                    {actions}
                </Typography>
            </Stack>}
        </Stack>
    }

    return (
        !isSelectionSummary ?
        <>
            {
                rulesJSON?.vars?.map((item) => {
                    return (
                        <Box sx={{ mb: 4 }}>
                            <Typography variant='h4' gutterBottom>
                                {item.symbol.replace(/<\*|\*>/g, '')}
                            </Typography>
                            {
                                rulesJSON?.blocks?.filter((block) => block.variable === item.text).map(block =>
                                (
                                    <Box sx={{ mb: 2 }}>
                                        <Card>
                                            <CardContent>
                                                {
                                                    block.cases?.map((caseItem) => {
                                                        return (
                                                            <>
                                                                {
                                                                    caseItem?.isTextPostProcessing &&
                                                                    <Typography variant='h6'>+ Text Processing</Typography>
                                                                }
                                                                {renderExpressions(caseItem?.exprs[0], caseItem.actions)}
                                                            </>
                                                        );
                                                    })
                                                }
                                            </CardContent>
                                        </Card>
                                    </Box>
                                ))
                            }
                        </Box>
                    )
                })
            }
        </>
        :
        renderExpressions(rulesJSON)
    )
}

export default RuleSummary