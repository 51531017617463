export const reportDataList = [
  // Do not remove comments
  // {
  //   key: "usage-report",
  //   title: "TFN Usage Detail Report",
  //   subtitle: "Display TFN Usage details for specified time period",
  //   isDisabled: false
  // },
  // {
  //   key: "usage-summary-report",
  //   title: "TFN Usage Summary Report",
  //   subtitle: "Display Summary of TFN usage for specified time period",
  //   isDisabled: false
  // },
  // {
  //   key: "recycling-config-report",
  //   title: "TFN Recycling Configuration Report",
  //   subtitle: "Display TFN Recycling Configuration details",
  //   isDisabled: false
  // },
  {
    key: "sotexportreport/sotexporttfn",
    title: "TFN Requests Data (SOT) Export",
    subtitle: "Display TFN Data (SOT) export",
    isDisabled: false
  },
  {
    key: "tfn-reports/unused-tfn-report",
    title: "Unused Active TFNs Report",
    subtitle: "Display list of Active TFNs not in use",
    isDisabled: false
  },
  {
    key: "tfn-reports/shutdown-tfn-report",
    title: "TFN Shutdown Report",
    subtitle: "Display List of TFN's that have been shut down",
    isDisabled: false
  },
  {
    key: "tfn-reports/tfn-matrix-current",
    title: "TFN Matrix Current Report",
    subtitle: "Display Current Matrix Data for TFN",
    isDisabled: false
  },
  {
    key: "tfn-reports/marketing-tfn-report",
    title: "TFN Marketing Report",
    subtitle: "Display Marketing Report for TFN",
    isDisabled: false
  },
  // {
  //   key: "rolling-interval-report",
  //   title: "TFN Usage Rolling Interval Report",
  //   subtitle: "Display Summary of TFN Usage for specified Rolling Interval",
  //   isDisabled: true
  // },
  // {
  //   key: "dynamic-report",
  //   title: "TFN Dynamic Report",
  //   subtitle: "Display list of TFNs flagged as Dynamic TFNs",
  //   isDisabled: true
  // },
  // {
  //   key: "checklist-report",
  //   title: "TFN Checklist Report",
  //   subtitle: "Display list of TFNs awaiting verification",
  //   isDisabled: true
  // },
];

export const intervalType = [
  {
    name: "Weekly",
    isDisabled: false,
  },
  {
    name: "Monthly",
    isDisabled: true,
  },
  {
    name: "Advanced Criteria",
    isDisabled: true,
  },
];

export const intervalTypeDetails = [
  {
    name: "Daily",
    isDisabled: false,
  },
  {
    name: "Weekly",
    isDisabled: false,
  },
  {
    name: "Monthly",
    isDisabled: true,
  },
  {
    name: "Advanced Criteria",
    isDisabled: true,
  },
];

export const intervalTypeRecycling = [
  {
    name: "Daily",
    isDisabled: false,
  },
  {
    name: "Monthly",
    isDisabled: false,
  },
  {
    name: "Yearly",
    isDisabled: false,
  },
];

export const intervalTypeSOT = [
  {
    name: "SOT Export",
    value: "sotexporttfn",
    isDisabled: false,
  },
  {
    name: "Marketing SOT Export",
    value: "marketing-sot-export-report",
    isDisabled: false,
  },
  {
    name: "Telesales Active Export",
    value: "telesales-active-export",
    isDisabled: false,
  },
  {
    name: "TFN Historical Report",
    value: "tfn-historical-report",
    isDisabled: false,
  },
];

export const isActiveTypeSOT = [
  {
    name: "Active",
    value: 1,
    isDisabled: false,
  },
  {
    name: "Deleted",
    value: -1,
    isDisabled: false,
  },
  {
    name: "Inactive",
    value: 0,
    isDisabled: false,
  },
];

export const MenuProps = {
  PaperProps: {
    style: {
      background: "#FDFDFD",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.25)",
      borderRadius: 0
    }
  }
};

export const SelectDisplayProps = {
  style: {
    lineHeight: "19px",
    padding: "12px",
    minHeight: 0,
  }
};