import React, {useContext} from 'react'
import PropTypes from 'prop-types';
import {download, errorHandler} from "../utils";
import {SnackbarContext} from "context/SnackbarContext";

/**
 * Hook to download file via API
 * @param api // API
 * @param methodName // Method to CALL
 * @param fileName // FileName to download
 * @returns {[(fileOption: string, file: string) => void, boolean, ((value: (((prevState: boolean) => boolean) | boolean)) => void)]}
 */
const useDownload = (api, methodName, fileName = undefined) => {
  const [isDownloading, setIsDownloading] = React.useState(false);
  const {setSnackBarConfig} = useContext(SnackbarContext);

  /**
   * Method to download the file
   * @param fileOption // Optional parameter to pass API param
   * @param file // Override fileName to save
   */
  const handleDownload = (fileOption, file, payload = null) => {
    setIsDownloading(true);
    api[methodName](fileOption, payload).then(response => {
      download(response, file || fileName)
      setIsDownloading(false);
      let fname=file?file:fileName;
      let name=fname.replace('.xlsx', '')
      let messageFileName=name.toLowerCase().includes("report")?name.replace('Report',''):name
      setSnackBarConfig({open: true, message: `${messageFileName} report download succeeded.`, severity: 'success'})
    }).catch(e => {
      errorHandler(e);
    }).finally(() => {
      setIsDownloading(false);
    });
  }

  return [handleDownload, isDownloading, setIsDownloading];
};
useDownload.propTypes = {
  api: PropTypes.string.isRequired,
  methodName: PropTypes.string.isRequired,
  fileName: PropTypes.string
}
export default useDownload;
