import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import useDownload from 'components/common/hooks/useDownload';
import { compareStr } from 'components/common/utils';
import { MenuProps, SelectDisplayProps } from "components/tfn/report/tfnReport.constants";
import gtmApi from "api/gtmApi";
import sotApi from "api/sotApi";

let allSegments = [];

const DownloadWithFilters = (props) => {
  const {
    optionsList
  } = props;

  const [channel, setChannel] = useState([])
  const [segments, setSegments] = useState([])
  const [selectedSegments, setSelectedSegments] = useState([])
  const [selectedChannels, setSelectedChannels] = useState([])
  const [actualSegmentlist, setActualSegmentlist] = useState([])
  const [actualChannelList, setActualChannellist] = useState([])
  const [selectedFileOption, setSelectedFileOption] = useState("channel_cd");
  const [handleDownload, isDownloading] = useDownload(sotApi.creativeData, 'download');

  const department = localStorage.getItem("channelType");
  
  useEffect(() => {
    const fetchData = async () => {
      const response = await gtmApi.getGTMSearchChannel(
        department
      );
      response.data?.sort((a, b) => compareStr(a.shortname, b.shortname));

      setActualChannellist(response.data);
      let channelList = response.data?.filter(
        (channel) => !channel.channelname.includes("Email")
      ) || [];
      setChannel([
        {
          channelname: "Select All",
          shortname: "Select All",
        },
        ...channelList,
      ]);
      const res = await gtmApi.getGTMSearchSegment(
        department
      );
      allSegments = res.data;
    };
    fetchData();
  }, []);

  const handleSelectedChannel = async (event, value) => {
    let selectallFlag = value.some(ele => ele.shortname === "Select All")
    let values = selectallFlag ? channel.filter(channel => channel.shortname !== "Select All") : value.sort((a, b) => compareStr(a.shortname, b.shortname))
    setSelectedChannels(values)
    if (values.length > 0) {
      setSelectedSegments(selectedSegments.filter(segment => values.some(channelName => channelName.shortname === segment.channel?.shortname)))
      let datalist = allSegments
        ?.filter(segment => values.map(a => a.channel_id).indexOf(segment?.channel?.channel_id) > -1)
      if (department && department === 'Non-Core Provider') {
        datalist = datalist
          ?.filter((value) => value.shortname === 'CBNC' || value.shortname === 'CBNCDSNP' || value.shortname === 'DSNPCBNCPA' || value.shortname === 'MAPDCBNCPA' || value.shortname === 'CBNCPORTAL')
      } else {
        datalist = datalist
          ?.filter((value) => value.shortname !== 'CBNC' && value.shortname !== 'CBNCDSNP' && value.shortname !== 'DSNPCBNCPA' && value.shortname !== 'MAPDCBNCPA' && value.shortname !== 'CBNCPORTAL')
      }
      datalist = datalist?.sort((a, b) => compareStr(a.shortname, b.shortname))?.sort((a, b) => compareStr(a.channel.shortname, b.channel.shortname))
      setActualSegmentlist(datalist)
      getAllSegmentList(datalist)
    } else {
      setSegments([])
      setSelectedSegments([])
    }
  }

  const handleSelectedSegment = async (event, values) => {
    let datalist = []
    let selectallFlag = values.some(ele => ele.channel.shortname == "Select All")
    if (selectallFlag) {
      setSelectedSegments(actualSegmentlist)

    } else {
      datalist = values.sort((a, b) => compareStr(a.shortname, b.shortname)).sort((a, b) => compareStr(a.channel.shortname, b.channel.shortname))
      setSelectedSegments(datalist)
    }

  }

  const handleButtonClick = (e) => {
    e.preventDefault();
    const payload = {
      channel_id: selectedChannels.map(a => a.channel_id),
      channel_segment_ids: selectedSegments.map(a => a.channelsegment_id)
    };
    const fileName = optionsList.find(option => option.value === selectedFileOption)?.customFileName;
    handleDownload(selectedFileOption, fileName, payload);
  };
  const getAllSegmentList = async (segmentlist) => {
    segmentlist.sort((a, b) => compareStr(a.shortname, b.shortname)).sort((a, b) => compareStr(a.channel.shortname, b.channel.shortname))
    let listdata = {
      data: [
        {
          channel: { shortname: "Select All" }
        },
        ...segmentlist,
      ],
      loading: false,
    };
    setSegments(listdata.data)
  };

  const handleFileOptionChange = (value) => {
    setSelectedFileOption(value);
    let channelList = [];
    if (value === "email_cd") {
      channelList = actualChannelList.filter((channel) =>
        channel.channelname.includes("Email")
      );
    } else
      channelList = actualChannelList.filter(
        (channel) => !channel.channelname.includes("Email")
      );

    setSelectedChannels([]);
    setSelectedSegments([]);
    setSegments([]);
    setChannel([
      {
        channelname: "Select All",
        shortname: "Select All",
      },
      ...channelList,
    ]);
  };

  return (
    <>
      <form onSubmit={handleButtonClick} style={{ width: "500px", padding: "20px" }}>
        <FormControl sx={{ width: "100%" }}>
        <label className="input-label">File Option</label>
          <Select
            value={selectedFileOption}
            onChange={e => handleFileOptionChange(e.target.value)}
            inputProps={{ "aria-label": "File Option", "data-testid": "fileOption-dropdown" }}
            MenuProps={MenuProps}
            SelectDisplayProps={SelectDisplayProps}
            sx={{ marginBottom: 2 }}
          >
            {optionsList.map((option) => (
              <MenuItem
                key={option.value}
                value={option.value}
                style={{
                  lineHeight: "19px",
                  padding: "4px 14px"
                }}
              >
                {option.label}
              </MenuItem>
            ))}
          </Select>
          <label className="input-label">Channels</label>
          <Autocomplete
            multiple
            size='small'
            onChange={handleSelectedChannel}
            id="multiple-select-channel"
            options={channel}
            value={selectedChannels}
            data-testid="button-search"
            getOptionLabel={(option) => option.shortname}
            renderInput={(params) => (
              <TextField {...params} placeholder="All Channels" />
            )}
            sx={{ marginBottom: 2 }}
          />
          <label className="input-label">Segments</label>
          <Autocomplete
            multiple
            size='small'
            onChange={handleSelectedSegment}
            data-testid="button-segment"
            id="multiple-select-segment"
            options={segments}
            value={selectedSegments}
            getOptionLabel={(option) => `${option?.channel?.shortname} ${option?.shortname ? '| ' + option.shortname : ''} `}
            renderInput={(params) => (
              <TextField {...params} placeholder="All Segments" />
            )}
            sx={{ marginBottom: 2 }}
          />
        </FormControl>
        <Button
          id="sot-reports-run"
          variant="contained"
          className="custom-button"
          data-testid="button-click"
          type={'submit'}
          disabled={isDownloading}
        >{isDownloading ? "Downloading..." : "Excel"}</Button>
      </form>
    </>
  );
};

export default DownloadWithFilters;
