import { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import dayjs from "dayjs";

import api from "Api";
import PromptAlert from "components/PromptAlert";
import { toTitleCase } from "components/common/utils";
import styles from "../../sourcedocs.module.scss";
import { booleanOptions } from "../../constants";
import { ApiParams } from "../constants";
import sotApi from "api/sotApi";
import { SnackbarContext } from "context/SnackbarContext";
import useGetDropdowns from "../hooks/useGetDropdowns";
import LoadingPanel from "components/common/TabPanel/LoadingPanel";
import { MAP_PROD_HOSTNAME } from "constant";

const EditForm = (props) => {
  const { dataObj, action, disabled } = props;
  const {
    channelOptions,
    segmentOptions,
    brandOptions,
    LPGOptions,
    hitOptions,
    creativeTypeRefIDOptions,
    platformOptions,
    languageOptions,
    leadCreativeOptions,
    isLoading,
    populateSegments,
  } = useGetDropdowns(api, dataObj.channelsegment?.channel?.channel_id);
  const [isBlocking, setBlocking] = useState(false);

  const [dataObject, setDataObject] = useState(dataObj);
  const { setSnackBarConfig } = useContext(SnackbarContext);
  const [error, setError] = useState({
    refid: "",
    channel: "",
    segment: "",
    creativenm: "",
    brand: "",
    provider: "",
    hit: "",
    tfn_ref_id: "",
    cms1: "",
    seminarflag: "",
    creativetyperefid: "",
    language: "",
  });

  const history = useHistory();
  const isProdEnv = window.location.hostname === MAP_PROD_HOSTNAME;

  useEffect(() => {
    setError({
      refid: "",
      channel: "",
      segment: "",
      creativenm: "",
      brand: "",
      provider: "",
      hit: "",
      tfn_ref_id: "",
      cms1: "",
      seminarflag: "",
      creativetyperefid: "",
      language: "",
    });
  }, []);
  useEffect(() => {
    const obj = dataObject.refid ? dataObject : dataObj;
    setDataObject({
      ...obj,
      refid: dataObject.refid || dataObj.refid,
      provider: booleanOptions.find((c) => c.id === dataObj?.provider),
      seminarflag: booleanOptions.find((c) => c.id === dataObj?.seminarflag),
      isgiveback: booleanOptions.find(
        (c) => c.id === (dataObj?.isgiveback ? "Y" : "N")
      ),
      channel: {
        id: dataObj?.channelsegment?.channel?.channel_id,
        text: dataObj?.channelsegment?.channel?.shortname,
      },
      segment: {
        id: dataObj?.channelsegment?.channelsegment_id,
        text: dataObj?.channelsegment?.shortname,
      },
      brand: brandOptions.find((c) => c.id == dataObj?.brand),
      leadplangraphic: LPGOptions.find(
        (c) => c.id?.toLowerCase() == dataObj?.leadplangraphic?.toLowerCase()
      ),
      hit: hitOptions.find((c) => c.id == dataObj?.hit),
      creativetyperefid: creativeTypeRefIDOptions.find(
        (c) => c.id == dataObj.creativetyperefid
      ),
      platform: platformOptions.find((c) => c.text == dataObj?.platform),
      language: languageOptions.find((c) => c.text == dataObj?.language),
      leadcreative: leadCreativeOptions.find( c => c.id?.toLowerCase() === dataObj?.leadcreative?.toLowerCase())
    });
  }, [
    brandOptions,
    LPGOptions,
    hitOptions,
    platformOptions,
    languageOptions,
    creativeTypeRefIDOptions,
    leadCreativeOptions,
    dataObj,
  ]);

  const handleChange = (e, key) => {
    setBlocking(true);
    setDataObject({
      ...dataObject,
      [key]: e.target.value,
      ...(key === "channel" &&
        dataObject.channel?.text !== e.target.value?.text && {
          segment: { text: "Select Segment" },
        }),
    });
    if (
      key === "channel" &&
      dataObject.channel?.text !== e.target.value?.text
    ) {
      populateSegments(e.target.value?.id);
    }
    setError({
      ...error,
      [key]: "",
    });
  };
  const handleSave = () => {
    
    const mandatoryFields = [
      "refid",
      "channel",
      "segment",
      "creativenm",
      "brand",
      "provider",
      "hit",
      "tfn_ref_id",
      "cms1",
      "seminarflag",
      "creativetyperefid",
      "language",
      "leadcreative"
    ];
    setBlocking(false);
    let temp = [];
    if (action === "edit"||action==="clone") {
      Object.keys(dataObject).forEach((key) => {
        if (
          mandatoryFields.includes(key) &&
          (dataObject[key]?.length === 0 ||
            (key === "segment" && !dataObject[key]?.id))
        ) {
          temp.push(`${key}`);
          setError({
            ...error,
            [key]: `${toTitleCase(key)} is required`,
          });
        }
      });
    }

    if (
      temp?.length === 0 
      // && Object.values(error).filter(value => value !== "").length === 0
    ) {
      const res=window.confirm("Updating this item may affect existing MBM items . Continue ?")
      res&&callEditAPI();
    }
  };

  const callEditAPI = async () => {
    let arr = [];
    try {
      action === "edit" && delete dataObject.refid;
      let payload = {
        ...dataObject,
        brand: dataObject.brand?.id,
        channelsegment_id: dataObject.segment?.id,
        hit: dataObject.hit?.id,
        isgiveback: dataObject.isgiveback?.id === "Y",
        language: dataObject.language?.text,
        leadplangraphic: dataObject.leadplangraphic?.id,
        platform: dataObject.platform?.id ? dataObject.platform?.text : "",
        provider: dataObject.provider?.id,
        seminarflag: dataObject.seminarflag?.id,
        creativetyperefid: dataObject.creativetyperefid?.id,
        leadcreative: dataObject?.leadcreative?.id || ''
      };
      if (dataObject.segment?.department?.indexOf("Email") === -1) {
        payload.agegrouping = "";
        payload.splitpercent = "";
        payload.birthdatereq = "";
        payload.dynamic_tfn_ref_id = "";
      }
      delete payload.channel;
      delete payload.segment;
      payload = Object.fromEntries(
        Object.entries(payload).filter(([key, value]) => value != dataObj[key])
      );
      const res = await api.updateCreativeData(
        dataObject.creativedataid,
        payload
      );
      if (action === "edit") {
        setSnackBarConfig({
          open: true,
          message: "Selected creative data updated successfully.",
          severity: "success",
        });
        history.push(ApiParams.baseUrl);
      } else {
        setSnackBarConfig({
          open: true,
          message: "Creative data cloned successfully.",
          severity: "success",
        });
        history.push(ApiParams.baseUrl);
      }
    } catch (e) {
      if (e.response?.status === 400) {
        Object.entries(e.response?.data).forEach(([key, value]) => {
          setError((prevState) => ({
            ...prevState,
            [key]: typeof value === "string" ? value : value.join("; "),
          }));
        });
      } else {
        alert("Something went wrong");
      }
    }
  };

  const handleDelete = async (ids) => {
    try {
      const obj = { creativedata_id_list: ids };
      await sotApi[ApiParams.moduleName].deleteAll(obj);

      history.push(ApiParams.baseUrl);
    } catch (e) {
      alert("something went wrong!");
    }
  };

  return (
    <>
      <Stack direction="row" spacing={1}>
        <Button variant="outlined" className="button" data-testid="button-save" onClick={handleSave}>
          Save
        </Button>
        {action === "clone" && (
          <Button
            variant="outlined"
            className="button"
            data-testid="button-delete"
            onClick={() => {
              handleDelete([dataObj.creativedataid.toString()]);
            }}
          >
            Cancel
          </Button>
        )}
      </Stack>
      {isLoading && <LoadingPanel />}
      <Box pt={3} className="newgeography-wrapper">
        <Grid
          container
          rowSpacing={2}
          columnSpacing={{ sm: 0, md: 12 }}
          direction={{ sm: "column", md: "row" }}
        >
          <Grid item sm={12} md={6}>
            <div>
              <label className={`required font ${styles.labelText}`}>
                Ref Id
              </label>
            </div>
            <div>
              <input
                className={styles.inputText}
                type="text"
                name="refid"
                id="refid"
                disabled={disabled}
                value={dataObject?.refid}
                data-testid="button-change"
                onChange={(e) => handleChange(e, "refid")}
              />
              <div>
                <label style={{ color: "red" }}>{error.refid}</label>
              </div>
            </div>
          </Grid>
          <Grid item sm={12} md={6}>
            <div>
              <label className={`required font ${styles.labelText}`}>
                Channel
              </label>
            </div>
            <div>
              <DropDownList
                name="channel"
                id="channel"
                className={styles.dropDownList}
                value={dataObject.channel}
                data={channelOptions}
                textField="text"
                dataItemKey="id"
                onChange={(e) => handleChange(e, "channel")}
              />
            </div>
            <div>
              <label style={{ color: "red" }}>{error.channel}</label>{" "}
            </div>
          </Grid>
          <Grid item sm={12} md={6}>
            <div>
              <label className={`required font ${styles.labelText}`}>
                Segment
              </label>
            </div>
            <div>
              <DropDownList
                name="segment"
                id="segment"
                className={styles.dropDownList}
                value={dataObject.segment}
                data={segmentOptions}
                textField="text"
                dataItemKey="id"
                onChange={(e) => handleChange(e, "segment")}
              />
            </div>
            <div>
              <label style={{ color: "red" }}>{error.segment}</label>{" "}
            </div>
          </Grid>
          <Grid item sm={12} md={6}>
            <div>
              <label className={`required font ${styles.labelText}`}>
                Creative Name
              </label>
            </div>
            <div>
              <input
                className={styles.inputText}
                type="text"
                name="creativenm"
                id="creativenm"
                data-testid="button-change2"
                value={dataObject?.creativenm}
                onChange={(e) => handleChange(e, "creativenm")}
              />
            </div>
            <div>
              <label style={{ color: "red" }}>{error.creativenm}</label>
            </div>
          </Grid>
          <Grid item sm={12} md={6}>
            <div>
              <label className={`required font ${styles.labelText}`}>
                Brand
              </label>
            </div>
            <div>
              <DropDownList
                name="brand"
                id="brand"
                className={styles.dropDownList}
                value={dataObject.brand}
                data={brandOptions}
                textField="text"
                dataItemKey="id"
                onChange={(e) => handleChange(e, "brand")}
              />
            </div>
            <div>
              <label style={{ color: "red" }}>{error.brand}</label>{" "}
            </div>
          </Grid>
          <Grid item sm={12} md={6}>
            <div>
              <label className={`required font ${styles.labelText}`}>
                Provider
              </label>
            </div>
            <div>
              <DropDownList
                name="provider"
                id="provider"
                className={styles.dropDownList}
                value={dataObject.provider}
                data={booleanOptions}
                textField="text"
                dataItemKey="id"
                onChange={(e) => handleChange(e, "provider")}
              />
            </div>
            <div>
              <label style={{ color: "red" }}>{error.provider}</label>{" "}
            </div>
          </Grid>
          <Grid item sm={12} md={6}>
            <div>
              <label className={`font ${styles.labelText}`}>
                Lead Plan Graphic
              </label>
            </div>
            <div>
              <DropDownList
                name="leadplangraphic"
                id="leadplangraphic"
                className={styles.dropDownList}
                value={dataObject.leadplangraphic}
                data={LPGOptions}
                textField="text"
                dataItemKey="id"
                data-testid="button-change3"
                defaultItem={{ text: "Select LPG", id: null }}
                onChange={(e) => handleChange(e, "leadplangraphic")}
              />
            </div>
          </Grid>
          <Grid item sm={12} md={6}>
            <div>
              <label className={`required font ${styles.labelText}`}>
                Hit #
              </label>
            </div>
            <div>
              <DropDownList
                name="hit"
                id="hit"
                className={styles.dropDownList}
                value={dataObject.hit}
                data={hitOptions}
                textField="text"
                dataItemKey="id"
                onChange={(e) => handleChange(e, "hit")}
              />
            </div>
            <div>
              <label style={{ color: "red" }}>{error.hit}</label>{" "}
            </div>
          </Grid>
          {!isProdEnv && <Grid item sm={12} md={6}>
            <div>
              <label className={` font ${styles.labelText}`}>
                Lead Creative
              </label>
            </div>
            <div>
              <DropDownList
                name="leadcreative"
                id="leadcreative"
                className={styles.dropDownList}
                value={dataObject.leadcreative}
                data={leadCreativeOptions}
                textField="text"
                dataItemKey="id"
                onChange={(e) => handleChange(e, "leadcreative")}
                defaultItem={{text: "Select Lead Creative", id: null}}
              />
            </div>
          </Grid>}
          <Grid item sm={12} md={6}>
            <div>
              <label className={`font ${styles.labelText}`}>
                Provider Code
              </label>
            </div>
            <div>
              <input
                className={styles.inputText}
                type="text"
                name="providercode"
                id="providercode"
                value={dataObject?.providercode}
                data-testid="button-change4"
                onChange={(e) => handleChange(e, "providercode")}
              />
            </div>
            <div>
              <label style={{ color: "red" }}>{error.providercode}</label>{" "} 
            </div>
          </Grid>
          <Grid item sm={12} md={6}>
            <div>
              <label className={`required font ${styles.labelText}`}>
                TFN Ref ID
              </label>
            </div>
            <div>
              <input
                className={styles.inputText}
                type="text"
                name="tfn_ref_id"
                id="tfn_ref_id"
                value={dataObject?.tfn_ref_id}
                onChange={(e) => handleChange(e, "tfn_ref_id")}
              />
            </div>
            <div>
              <label style={{ color: "red" }}>{error.tfn_ref_id}</label>
            </div>
          </Grid>
          {dataObject?.channel?.text?.toLowerCase().indexOf("email") > -1 && (
            <Grid item sm={12} md={6}>
              <div>
                <label className={`font ${styles.labelText}`}>
                  DynamicTfnRefId
                </label>
              </div>
              <div>
                <input
                  className={styles.inputText}
                  type="text"
                  name="dynamic_tfn_ref_id"
                  id="dynamic_tfn_ref_id"
                  value={dataObject?.dynamic_tfn_ref_id}
                  onChange={(e) => handleChange(e, "dynamic_tfn_ref_id")}
                />
              </div>
              <div>
                <label style={{ color: "red" }}>
                  {error.dynamic_tfn_ref_id}
                </label>
              </div>
            </Grid>
          )}
          <Grid item sm={12} md={6}>
            <div>
              <label className={`required font ${styles.labelText}`}>
                CMS1
              </label>
            </div>
            <div>
              <input
                className={styles.inputText}
                type="text"
                name="cms1"
                id="cms1"
                value={dataObject?.cms1}
                onChange={(e) => handleChange(e, "cms1")}
              />
            </div>
            <div>
              <label style={{ color: "red" }}>{error.cms1}</label>
            </div>
          </Grid>
          <Grid item sm={12} md={6}>
            <div>
              <label className={`required font ${styles.labelText}`}>
                Seminar
              </label>
            </div>
            <div>
              <DropDownList
                name="seminarflag"
                id="seminarflag"
                className={styles.dropDownList}
                value={dataObject.seminarflag}
                data={booleanOptions}
                textField="text"
                dataItemKey="id"
                onChange={(e) => handleChange(e, "seminarflag")}
              />
            </div>
            <div>
              <label style={{ color: "red" }}>{error.seminarflag}</label>{" "}
            </div>
          </Grid>
          <Grid item sm={12} md={6}>
            <div>
              <label className={`required font ${styles.labelText}`}>
                Creative Type Ref ID
              </label>
            </div>
            <div>
              <DropDownList
                name="creativetyperefid"
                id="creativetyperefid"
                className={styles.dropDownList}
                value={dataObject.creativetyperefid}
                data={creativeTypeRefIDOptions}
                textField="text"
                dataItemKey="id"
                onChange={(e) => handleChange(e, "creativetyperefid")}
              />
            </div>
            <div>
              <label style={{ color: "red" }}>{error.creativetyperefid}</label>
            </div>
          </Grid>
          <Grid item sm={12} md={6}>
            <div>
              <label className={`font ${styles.labelText}`}>Phase</label>
            </div>
            <div>
              <input
                className={styles.inputText}
                type="text"
                name="phase"
                id="phase"
                value={dataObject?.phase}
                onChange={(e) => handleChange(e, "phase")}
              />
            </div>
            <div>
              <label style={{ color: "red" }}>{error.phase}</label>
            </div>
          </Grid>
          <Grid item sm={12} md={6}>
            <div>
              <label className={`font ${styles.labelText}`}>
                Creative Code
              </label>
            </div>
            <div>
              <input
                className={styles.inputText}
                type="text"
                name="creativecode"
                id="creativecode"
                value={dataObject?.creativecode}
                onChange={(e) => handleChange(e, "creativecode")}
              />
            </div>
            <div>
              <label style={{ color: "red" }}>{error.creativecode}</label>
            </div>
          </Grid>
          <Grid item sm={12} md={6}>
            <div>
              <label className={`font ${styles.labelText}`}>
                Template Code
              </label>
            </div>
            <div>
              <input
                className={styles.inputText}
                type="text"
                name="templatecode"
                id="templatecode"
                value={dataObject?.templatecode}
                onChange={(e) => handleChange(e, "templatecode")}
              />
            </div>
            <div>
              <label style={{ color: "red" }}>{error.templatecode}</label>
            </div>
          </Grid>
          <Grid item sm={12} md={6}>
            <div>
              <label className={`font ${styles.labelText}`}>Is Give Back</label>
            </div>
            <div>
              <DropDownList
                name="isgiveback"
                id="isgiveback"
                className={styles.dropDownList}
                value={dataObject.isgiveback}
                data={booleanOptions}
                textField="text"
                dataItemKey="id"
                onChange={(e) => handleChange(e, "isgiveback")}
              />
            </div>
            <div>
              <label style={{ color: "red" }}>{error.isgiveback}</label>
            </div>
          </Grid>
          <Grid item sm={12} md={6}>
            <div>
              <label className={`font ${styles.labelText}`}>Platform</label>
            </div>
            <div>
              <DropDownList
                name="platform"
                id="platform"
                className={styles.dropDownList}
                value={dataObject.platform}
                data={platformOptions}
                defaultItem={{ text: "Select Platform", id: null }}
                textField="text"
                dataItemKey="id"
                onChange={(e) => handleChange(e, "platform")}
              />
            </div>
          </Grid>
          <Grid item sm={12} md={6}>
            <div>
              <label className={`required font ${styles.labelText}`}>
                Language
              </label>
            </div>
            <div>
              <DropDownList
                name="language"
                id="language"
                className={styles.dropDownList}
                value={dataObject.language}
                data={languageOptions}
                textField="text"
                dataItemKey="id"
                onChange={(e) => handleChange(e, "language")}
              />
            </div>
            <div>
              <label style={{ color: "red" }}>{error.language}</label>{" "}
            </div>
          </Grid>
          <Grid item sm={12} md={6}>
            <div>
              <label className={`font ${styles.labelText}`}>Size</label>
            </div>
            <div>
              <input
                className={styles.inputText}
                type="text"
                name="size"
                id="size"
                value={dataObject?.size}
                onChange={(e) => handleChange(e, "size")}
              />
            </div>
            <div>
              <label style={{ color: "red" }}>{error.size}</label>
            </div>
          </Grid>
          <Grid item sm={12} md={6}>
            <div>
              <label className={`font ${styles.labelText}`}>Url</label>
            </div>
            <div>
              <input
                className={styles.inputText}
                type="text"
                name="url"
                id="url"
                value={dataObject?.url}
                onChange={(e) => handleChange(e, "url")}
              />
            </div>
            <div>
              <label style={{ color: "red" }}>{error.url}</label>
            </div>
          </Grid>
          
          <Grid item sm={12} md={6}>
            <div>
              <label className={`font ${styles.labelText}`}>
                Marketing Bullets
              </label>
            </div>
            <div>
              <input
                className={styles.inputText}
                type="text"
                name="marketingbullets"
                id="marketingbullets"
                value={dataObject?.marketingbullets}
                onChange={(e) => handleChange(e, "marketingbullets")}
              />
            </div>
            <div>
              <label style={{ color: "red" }}>{error.marketingbullets}</label>
            </div>
          </Grid>
          {dataObject.channel?.text?.toLowerCase().indexOf("email") > -1 && (
            <>
              <Grid item sm={12} md={6}>
                <div>
                  <label className={`font ${styles.labelText}`}>
                    Age Grouping
                  </label>
                </div>
                <div>
                  <input
                    className={styles.inputText}
                    type="text"
                    name="agegrouping"
                    id="agegrouping"
                    value={dataObject?.agegrouping}
                    onChange={(e) => handleChange(e, "agegrouping")}
                  />
                </div>
                <div>
                  <label style={{ color: "red" }}>{error.agegrouping}</label>
                </div>
              </Grid>
              <Grid item sm={12} md={6}>
                <div>
                  <label className={`font ${styles.labelText}`}>
                    Birthdate Request
                  </label>
                </div>
                <div>
                  <input
                    className={styles.inputText}
                    type="text"
                    name="birthdatereq"
                    id="birthdatereq"
                    value={dataObject?.birthdatereq}
                    onChange={(e) => handleChange(e, "birthdatereq")}
                  />
                </div>
                <div>
                  <label style={{ color: "red" }}>{error.birthdatereq}</label>
                </div>
              </Grid>
              <Grid item sm={12} md={6}>
                <div>
                  <label className={`font ${styles.labelText}`}>
                    Split Percent
                  </label>
                </div>
                <div>
                  <input
                    className={styles.inputText}
                    type="text"
                    name="splitpercent"
                    id="splitpercent"
                    value={dataObject?.splitpercent}
                    onChange={(e) => handleChange(e, "splitpercent")}
                  />
                </div>
                <div>
                  <label style={{ color: "red" }}>{error.splitpercent}</label>
                </div>
              </Grid>
            </>
          )}
          <Grid item sm={12} md={6}>
            <div>
              <label className={`font ${styles.labelText}`}>
                Last Updated By
              </label>
            </div>
            <div>
              <input
                className={styles.inputText}
                type="text"
                name="modifyusername"
                id="modifyusername"
                value={dataObject?.modifyusername}
                disabled
              />
            </div>
          </Grid>
          <Grid item sm={12} md={6}>
            <div>
              <label className={`font ${styles.labelText}`}>
                Last Updated Date
              </label>
            </div>
            <div>
              <input
                className={styles.inputText}
                type="text"
                name="modifydatetime"
                id="modifydatetime"
                value={dayjs(dataObject?.modifydatetime).format(
                  "YYYY/MM/DD hh:mm:ss A"
                )}
                disabled
              />
            </div>
          </Grid>
        </Grid>
      </Box>
      <PromptAlert isBlocking={isBlocking} />
    </>
  );
};

EditForm.propTypes = {
  dataObj: PropTypes.object.isRequired,
  action: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

EditForm.defaultProps = {
  disabled: false,
};

export default EditForm;
