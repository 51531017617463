import { useEffect, useState } from 'react';

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import DialogTitle from '@mui/material/DialogTitle';
import Autocomplete from '@mui/material/Autocomplete';
import OutlinedInput from '@mui/material/OutlinedInput';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import CircularProgress from '@mui/material/CircularProgress';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';

import useFieldChoices from './useFieldChoices';
import { planChoices, functionChoices } from './RuleConstants';
import { MAP_PROD_HOSTNAME } from 'constant';

const isProdEnv = window.location.hostname === MAP_PROD_HOSTNAME;

const ResultConfigModal = ({ openResultConfigModal, setOpenResultConfigModal, actions, updateValue }) => {
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const [plan, setPlan] = useState(null);
    const [field, setField] = useState(null);
    const [insertFunction, setInsertFunction] = useState(null);
    const [insertSnippet, setInsertSnippet] = useState(null);
    const [configuredResult, setConfiguredResult] = useState(actions ?? '')
    const { fieldChoices, getFieldChoices, fieldsLoading, snippetChoices, getSnippetChoices, snippetsLoading } = useFieldChoices()

    const configureResult = (key) => {
        let tempConfiguredResult = configuredResult;
        let planField = ''

        if (key === 'plan' && plan) {
            planField = '<#' + plan + '.' + (field !== '' && field !== undefined ? field.dbcolumn : '') + '#>';
            tempConfiguredResult = tempConfiguredResult + planField;
        }

        if (key === 'function' && insertFunction) {
            tempConfiguredResult = tempConfiguredResult + '.' + insertFunction;
        }
        if (key === 'snippet' && insertSnippet) {
            tempConfiguredResult = tempConfiguredResult + insertSnippet;
        }
        setConfiguredResult(tempConfiguredResult)
    };

    const saveConfiguredResult = () => {
        updateValue(configuredResult);
        setOpenResultConfigModal(false);
    };

    useEffect(() => {
        getSnippetChoices();
    }, []);

    return (
        <Dialog
            fullWidth={true}
            maxWidth="lg"
            open={openResultConfigModal}
            data-testid="dialog"
            onClose={() => setOpenResultConfigModal(false)}
        >
            <DialogTitle>Configure Result</DialogTitle>
            <DialogContent>
                <Stack spacing={4}>
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <FormControl size="small" fullWidth>
                            <Select
                                displayEmpty
                                input={<OutlinedInput />}
                                renderValue={(selected) => {
                                    return selected ? selected : "Plan";
                                }}
                                MenuProps={MenuProps}
                                inputProps={{ 'aria-label': 'Without label' }}
                                value={plan}
                                onChange={(event) => { setPlan(event.target.value); setField(""); getFieldChoices({ plan_type: event.target.value }) }}
                            >
                                {planChoices && planChoices.length > 0 && planChoices
                                    ?.filter(plan => isProdEnv ? !["AP2", "AP3", "AP4", "AP5"].includes(plan?.value) : true)
                                    ?.map((choice) => {
                                    return (
                                        <MenuItem key={choice.value} value={choice.value}>
                                            {choice.label}
                                        </MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>

                        <FormControl size="small" fullWidth>
                            <Autocomplete
                                size="small"
                                options={(fieldChoices || []).map(fieldChoice => {
                                    return {
                                        dbcolumn: fieldChoice.dbcolumn,
                                        displaytext: fieldChoice.displaytext
                                    }
                                })}
                                value={{
                                    dbcolumn: field?.dbcolumn,
                                    displaytext: field?.displaytext
                                }}
                                onChange={(event, newValue) => { setField(newValue) }}
                                getOptionLabel={(option) => option?.displaytext || ""}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        placeholder="Search Field"
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <>
                                                    {fieldsLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                    {params.InputProps.endAdornment}
                                                </>
                                            ),
                                        }}
                                    />}
                            />
                        </FormControl>
                        <IconButton size="small" aria-label="apply"
                            sx={{ borderRadius: '10%', color: '#827ac1', border: '1px solid #827ac1' }}
                            data-testid="button-click"
                            onClick={() => configureResult('plan')}
                        >
                            <AddCircleOutlinedIcon />
                        </IconButton>

                        <FormControl size="small" fullWidth>
                            <Select
                                displayEmpty
                                input={<OutlinedInput />}
                                renderValue={(selected) => {
                                    return selected ? selected : "Insert Function";
                                }}
                                MenuProps={MenuProps}
                                inputProps={{ 'aria-label': 'Without label' }}
                                value={insertFunction}
                                onChange={(event) => { setInsertFunction(event.target.value); }}
                            >
                                {functionChoices && functionChoices.length > 0 && functionChoices.map((choice) => {
                                    return (
                                        <MenuItem key={choice.value} value={choice.value}>
                                            {choice.label}
                                        </MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                        <IconButton size="small" aria-label="apply"
                            sx={{ borderRadius: '10%', color: '#827ac1', border: '1px solid #827ac1' }}
                            data-testid="button-config"
                            onClick={() => configureResult('function')}
                        >
                            <AddCircleOutlinedIcon />
                        </IconButton>

                        <FormControl size="small" fullWidth>
                            <Select
                                displayEmpty
                                input={<OutlinedInput />}
                                renderValue={(selected) => {
                                    return selected ? selected : "Insert Snippet";
                                }}
                                endAdornment={(
                                    <>
                                        {snippetsLoading ? <CircularProgress color="inherit" size={20} style={{marginRight: "20px"}} /> : null}
                                    </>
                                )}
                                MenuProps={MenuProps}
                                inputProps={{ 'aria-label': 'Without label' }}
                                value={insertSnippet}
                                onChange={(event) => { setInsertSnippet(event.target.value); }}
                            >
                                {snippetChoices && snippetChoices.length > 0 && snippetChoices.map((choice) => {
                                    return (
                                        <MenuItem key={choice.value} value={choice.value}>
                                            {choice.label}
                                        </MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                        <IconButton size="small" aria-label="apply"
                            sx={{ borderRadius: '10%', color: '#827ac1', border: '1px solid #827ac1' }}
                            data-testid="button-snipt"
                            onClick={() => configureResult('snippet')}
                        >
                            <AddCircleOutlinedIcon />
                        </IconButton>
                    </Stack>

                    <Stack>
                        <FormControl size="small" variant="outlined">
                            <OutlinedInput
                                id="result"
                                data-testid="input"
                                placeholder="Result"
                                onChange={(event) => setConfiguredResult(event.target.value)}
                                value={configuredResult}
                            />
                        </FormControl>
                    </Stack>
                </Stack>

                <DialogActions>
                    <Button  data-testid="button-save" onClick={saveConfiguredResult} variant="contained">Save</Button>
                    <Button  data-testid="button-close" onClick={() => setOpenResultConfigModal(false)} variant="outlined">Close</Button>
                </DialogActions>
            </DialogContent>
        </Dialog >
    )
}

export default ResultConfigModal