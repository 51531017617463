import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import InfoDialog from "components/common/Dialogs/InfoDialog";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { format } from "date-fns";
import api from "Api";
import sotApi from "api/sotApi";
import { ApiParams } from "../constants";
import PromptAlert from "components/PromptAlert";
import styles from "../../sourcedocs.module.scss";
import { NumericTextBox } from "@progress/kendo-react-inputs";
import { SnackbarContext } from "../../../../context/SnackbarContext";
import { compareStr } from "components/common/utils";
import { CustomPopup } from "./CustomPopUp";

const EditForm = (props) => {
  const [isBlocking, setBlocking] = useState(false);
  const [hideLabel, setHideLabel] = React.useState(true);
  const [channelArray, setChannelArray] = React.useState(undefined);
  const [segmentArray, setSegmentArray] = React.useState(undefined);
  const [segmentApiResponse, setSegmentApiResponse] = React.useState();
  const [selectedChannel, setSelectedChannel] = React.useState();
  const [stateArray, setStateArray] = React.useState();
  const [modifiedObj, setModifiedObj] = React.useState(); //new snap
  const [isLoading, setLoading] = React.useState(false);
  const [snapObject, setSnapObject] = React.useState(props?.snapObj || {}); //edit
  const { setSnackBarConfig } = useContext(SnackbarContext);
  const [errorCount, setErrorCount] = React.useState([]); //to capture errors on onChange(not morethan x characters)
  const [exceptionError, setexceptionError] = React.useState([]);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [action, setAction] = React.useState(props?.action); //e//dit
  const [error, setError] = React.useState({
    refid: "",
    channel: "",
    segment: "",
    hit: "",
  });

  React.useEffect(async () => {
    if (Object.keys(snapObject).length <= 1) setLoading(true);
    else setLoading(false);
    if (snapObject) {
      const res = await sotApi[ApiParams.moduleName].loadChannelSegments({
        channel_id: snapObject?.channel?.value,
      });
      if (res?.data) {
        setSegmentApiResponse(res.data);
      }
    }
  }, [snapObject?.channel?.value]);
  const history = useHistory();

  React.useEffect(() => {
    setSnapObject(props?.snapObj);
  }, [props.snapObj]);

  React.useEffect(() => {
    setAction(props?.action);
  }, [props.action]);

  React.useEffect(() => {
    const fields = [
      "channel",
      "segment",
      "state",
      "seminarflag",
      "material_due_date",
      "insertion_date",
    ];

    setModifiedObj({ ...snapObject });

    Object.keys(snapObject).forEach((key) => {
      if (fields.includes(key)) {
        if (key === "channel" || key === "segment" || key === "hit") {
          setModifiedObj((prev) => ({
            ...prev,
            [key]: Number(prev[key].value),
          }));
        } else if (key === "insertion_date" || key === "material_due_date") {
          if (snapObject[key] != "" || snapObject[key] != null) {
            const d = format(new Date((snapObject[key])), "yyyy-MM-dd");
            setModifiedObj((prev) => ({
              ...prev,
              [key]: d === 'Invalid Date' ? '' : d,
            }));
          }
          if (snapObject[key] === null) {
            setModifiedObj((prev) => ({ ...prev, [key]: "" }));
          }
        } else setModifiedObj((prev) => ({ ...prev, [key]: prev[key].value }));
      }
    });
  }, [snapObject]);
  React.useEffect(async () => {
    let temp = [];
    let data;
    if (selectedChannel) setSnapObject({ ...snapObject, segment: "" }); //to clear the channelsegmentid when channel is changed
    try {
      if (typeof selectedChannel != "undefined") {
        const res = await sotApi[ApiParams.moduleName].loadChannelSegments({
          channel_id: selectedChannel,
        });
        if (res?.data) {
          setSegmentApiResponse(res.data);
        }
      }
    } catch (e) {
      alert("Something went wrong");
    }
  }, [selectedChannel]);
  React.useEffect(() => {
    let temp = [];
    let data;
    if (typeof segmentApiResponse != "undefined") {
      data = Object.keys(segmentApiResponse);
      data.forEach((item) => {
        temp.push({ text: segmentApiResponse[item], value: item });
      });
    }

    setSegmentArray(temp?.sort((a, b) => compareStr(a.text, b.text)));
  }, [segmentApiResponse]);

  React.useEffect(async () => {
    const body = {
      filter: [
        {
          field: "category",
          criteria: [
            {
              op: "is",
              value: "General",
            },
          ],
        },
        {
          field: "lookupname",
          criteria: [
            {
              op: "is",
              value: "State",
            },
          ],
        },
      ],
    };
    callGetStates(body);
    try {
      const res = await sotApi[ApiParams.moduleName].loadChannels();
      setChannelArray(res?.data.map(item => ({
        text: item.shortname,
        value: item.channel_id
      })).sort((a, b) => compareStr(a.text, b.text)));
    } catch (e) {
      alert("Something went wrong!");
    }
  }, []);
  const handleChange = (e) => {
    const countFields = {
      fips: 5,
      nucleusndxcode: 7,
      insertion_status: 9,
      day_of_week: 9,
      publication_days: 7,
      material_deadline_range: 3,
      insertion_format: 4,
      circulation: 10,
      total_events: 4,
    };
    const keys = Object.keys(countFields);
    setBlocking(true);
    const temp = [];
    if (["refid", "channel", "segment"].includes(e.target.name)) {
      if (
        (e.target.name === "channel" || e.target.name === "segment") &&
        e.target?.value?.value?.length > 0
      )
        setError((prev) => ({ ...prev, [e.target.name]: "" }));
      if (e.target.name === "refid" && e.target.value.length > 0)
        setError((prev) => ({ ...prev, [e.target.name]: "" }));
    }
    if (e.target.name === "hit") {
      setError((prev) => ({ ...prev, [e.target.name]: "" }));
    }
    if (keys.includes(e.target.name)) {
      if (e.target.value.length > countFields[e.target.name]) {
        temp.push(e.target.name);
        setError((prev) => ({
          ...prev,
          [e.target.name]: `${e.target.name.charAt(0).toUpperCase() + e.target.name.slice(1)
            } can't be more than ${countFields[e.target.name]} characters`,
        }));
      } else {
        setError((prev) => ({ ...prev, [e.target.name]: "" }));
      }
      setErrorCount(temp);
    }
    if (action === "edit" || action == "clone") {
      if (
        e.target.name === "newspaper_specs" &&
        snapObject.channel.text === "Acquisition Newspaper"
      ) {
        setHideLabel(false);
      } else {
        setHideLabel(true);
      }
      if (e.target.name === "channel") {
        setSelectedChannel(e.target.value.value);
        if (
          e.target.value.text === "Acquisition Newspaper" &&
          snapObject?.newspaper_specs.length === 0
        ) {
          setHideLabel(false);
        } else {
          setHideLabel(true);
        }
      }
      if (e.target.name === "newspaper_specs" && e.target.value.length > 0) {
        setHideLabel(true);
      }
      if (
        e.target?.name === "insertion_date" ||
        e.target?.name === "material_due_date"
      ) {
        setSnapObject({
          ...snapObject,
          [e.target.name]:
            e.target.value === null ? "" : new Date(e.target.value).toLocaleDateString("en-US"),
        });
      } else {
        setSnapObject({ ...snapObject, [e.target.name]: e.target.value });
      }
    }
  };
  const handleSaveSnap = () => {
    const madatoryFields = ["refid", "channel", "segment"];
    setBlocking(false);
    let temp = [];
    if (action === "edit" || action === "clone") {
      if (
        snapObject?.channel?.text === "Acquisition Newspaper" &&
        snapObject.newspaper_specs.length === 0
      ) {
        temp.push("newspaper_specs");
      }
      Object.keys(snapObject).forEach((key) => {
        if (key === "hit") {
          const val = (snapObject[key] + "").split(".")[1] || 0;

          if (val != 0) {
            temp.push(`${key}`);
            setError((prev) => ({
              ...prev,
              [key]: `The value '${snapObject[key]}' is not valid for Hit`,
            }));
          }
        }
        if (madatoryFields.includes(key) && snapObject[key].length === 0) {
          temp.push(`${key}`);
          setError((prevState) => ({
            ...prevState,
            [key]: `${key.charAt(0).toUpperCase() + key.slice(1)} is required`,
          }));
        }
      });
    }
    if (temp?.length === 0 && errorCount?.length === 0) {
      callEditSnapAPI();
    }
  };
  const callEditSnapAPI = async () => {
    try {
      await sotApi[ApiParams.moduleName].update(
        modifiedObj.snap_id,
        modifiedObj
      );
      if (action === "edit") {
        setSnackBarConfig({
          open: true,
          message: "Selected snap updated successfully.",
          severity: "success",
        });
        history.push("/sourcedocs/snap");
      } else {
        setSnackBarConfig({
          open: true,
          message: "Selected snap cloned successfully.",
          severity: "success",
        });
        history.push("/sourcedocs/snap");
      }
    } catch (error) {
      if (error?.response?.status === 500) {
        setexceptionError(error?.response?.data);
      } else if (error?.response?.status === 400) {
        setexceptionError((error.response.data.error));
      } else {
        setexceptionError("Something went wrong!");
      }

      setOpenDialog(true);
    }
  };
  const handleDeleteSnap = async () => {
    try {
      await sotApi[ApiParams.moduleName].deleteAll({
        snap_id: [snapObject.snap_id],
      });

      history.push(`/sourcedocs/snap`);
    } catch (e) {
      alert("something went wrong!");
    }
  };
  const callGetStates = async (body) => {
    try {
      const res = await api.getRequiredFields(body);

      const data = res.data;
      let states = [];
      data.forEach((item) => {
        states.push({ text: item.displaytext, value: item.lookupvalue });
      });
      setStateArray(states);
    } catch (e) {
      alert("Something went wrong!");
    }
  };
  const loadingPanel = (
    <div className="k-loading-mask">
      <span className="k-loading-text">Loading</span>
      <div className="k-loading-image"></div>
      <div className="k-loading-color"></div>
    </div>
  );

  return (
    <>
      <Stack direction="row" spacing={1}>
        <Button variant="outlined" className="button" data-testid="button-click" onClick={handleSaveSnap}>
          Save
        </Button>
        {action === "clone" && (
          <Button
            variant="outlined"
            className="button"
            onClick={() => {
              handleDeleteSnap();
            }}
          >
            Cancel
          </Button>
        )}
      </Stack>
      {isLoading && loadingPanel}
      <Box
        pt={3}
        className={`newgeography - wrapper ${styles.sourceDocsContainer}`}
      >
        <Grid container spacing={2}>
          <Grid item sm={12} md={6}>
            <div>
              <label className={`required font ${styles.labelText}`}>
                RefId
              </label>
            </div>
            <div>
              <input
                className={styles.inputText}
                type="text"
                name="refid"
                id="refid"
                disabled={props.disabled}
                value={snapObject?.refid}
                onChange={handleChange}
              />
              <div>
                <label style={{ color: "red" }}>{error.refid}</label>
              </div>
            </div>
          </Grid>
          <Grid item sm={12} md={6}>
            <div>
              <label className={` font ${styles.labelText}`}>
                Nucleusndx Code
              </label>
            </div>
            <div>
              <input
                className={styles.inputText}
                type="text"
                name="nucleusndxcode"
                id="nucleusndxcode"
                value={snapObject?.nucleusndxcode}
                onChange={handleChange}
              />
            </div>
            <div>
              <label style={{ color: "red" }}>{error.nucleusndxcode}</label>
            </div>
          </Grid>
          <Grid item sm={12} md={6}>
            <div>
              <label className={`required font ${styles.labelText}`}>
                Channel
              </label>
            </div>
            <div>
              <DropDownList
                name="channel"
                id="channel"
                className={styles.inputText}
                value={snapObject.channel || null}
                data={channelArray}
                textField="text"
                dataItemKey="value"
                onChange={handleChange}
              //defaultItem={{text:"Select Channel-----"}}
              />
            </div>
            <div>
              <label style={{ color: "red" }}>{error.channel}</label>
            </div>
          </Grid>
          <Grid item sm={12} md={6}>
            <div>
              <label className="font">Insertion Date</label>
            </div>
            <div>
              <DatePicker
                className={styles.inputText}
                name="insertion_date"
                id="insertion_date"
                format="yyyy-MM-dd"
                value={
                  snapObject?.insertion_date
                    ? new Date(snapObject?.insertion_date?.replace(/-/g, '\/').replace(/T.+/, ''))
                    : null
                }
                onChange={handleChange}
                popup={CustomPopup}
              />
            </div>
          </Grid>
          <Grid item sm={12} md={6}>
            <div>
              <label className=" required font">Segment</label>
            </div>
            <div>
              <DropDownList
                name="segment"
                id="segment"
                className={styles.inputText}
                value={snapObject?.segment || null}
                onChange={handleChange}
                data={segmentArray}
                textField="text"
                dataItemKey="value"
              //defaultItem={{ text: "Select Segment-----" }}
              />
            </div>
            <div>
              <label style={{ color: "red" }}>{error.segment}</label>
            </div>
          </Grid>
          <Grid item sm={12} md={6}>
            <div>
              <label className="font">Day of Week</label>
            </div>
            <div>
              <input
                className={styles.inputText}
                type="text"
                name="day_of_week"
                id="day_of_week"
                value={snapObject?.day_of_week}
                onChange={handleChange}
              />
            </div>
            <div>
              <label style={{ color: "red" }}>{error.day_of_week}</label>
            </div>
          </Grid>
          <Grid item sm={12} md={6}>
            <div>
              <label className="font">Hit</label>
            </div>
            <div>
              <NumericTextBox
                className={styles.inputText}
                step={1}
                min={0}
                name="hit"
                id="hit"
                value={snapObject.hit}
                onChange={handleChange}
                format="n2"
              />
            </div>
            <div>
              <label style={{ color: "red" }}>{error.hit}</label>
            </div>
          </Grid>
          <Grid item sm={12} md={6}>
            <div>
              <label className={` font ${styles.labelText}`}>
                Publication Days
              </label>
            </div>
            <div>
              <input
                className={styles.inputText}
                type="text"
                name="publication_days"
                id="publication_days"
                value={snapObject?.publication_days}
                onChange={handleChange}
              />
            </div>
            <div>
              <label style={{ color: "red" }}>{error.publication_days}</label>
            </div>
          </Grid>
          <Grid item sm={12} md={6}>
            <div>
              <label className={` font ${styles.labelText}`}>State</label>
            </div>
            <div>
              <DropDownList
                name="state"
                id="state"
                className={styles.inputText}
                value={snapObject?.state || null}
                data={stateArray}
                onChange={handleChange}
                textField="text"
                dataItemKey="value"
                defaultItem={{ text: "Select State-----" }}
              />
            </div>
          </Grid>
          <Grid item sm={12} md={6}>
            <div>
              <label className="font">Material Deadline Range</label>
            </div>
            <div>
              <input
                className={styles.inputText}
                type="text"
                name="material_deadline_range"
                id="material_deadline_range"
                value={snapObject?.material_deadline_range}
                onChange={handleChange}
              />
            </div>
            <div>
              <label style={{ color: "red" }}>
                {error.material_deadline_range}
              </label>
            </div>
          </Grid>
          <Grid item sm={12} md={6}>
            <div>
              <label className={` font ${styles.labelText}`}>County</label>
            </div>
            <div>
              <input
                className={styles.inputText}
                type="text"
                name="county"
                id="county"
                value={snapObject?.county}
                onChange={handleChange}
              />
            </div>
          </Grid>
          <Grid item sm={12} md={6}>
            <div>
              <label className={` font ${styles.labelText}`}>
                Material Due Date
              </label>
            </div>
            <div>
              <DatePicker
                className={styles.inputText}
                name="material_due_date"
                id="material_due_date"
                format="yyyy-MM-dd"
                value={
                  snapObject?.material_due_date
                    ? new Date(snapObject.material_due_date?.replace(/-/g, '\/').replace(/T.+/, ''))
                    : null
                }
                onChange={handleChange}
                popup={CustomPopup}
              />
            </div>
          </Grid>
          <Grid item sm={12} md={6}>
            <div>
              <label className="font">FIPS</label>
            </div>
            <div>
              <input
                className={styles.inputText}
                type="text"
                name="fips"
                id="fips"
                value={snapObject?.fips}
                onChange={handleChange}
              />
            </div>
            <div>
              <label style={{ color: "red" }}>{error.fips}</label>
            </div>
          </Grid>
          <Grid item sm={12} md={6}>
            <div>
              <label className={` font ${styles.labelText}`}>
                Insertion Format
              </label>
            </div>
            <div>
              <input
                className={styles.inputText}
                type="text"
                name="insertion_format"
                id="insertion_format"
                value={snapObject?.insertion_format}
                onChange={handleChange}
              />
            </div>
            <div>
              <label style={{ color: "red" }}>{error.insertion_format}</label>
            </div>
          </Grid>
          <Grid item sm={12} md={6}>
            <div>
              <label className="font">Dominant St.County</label>
            </div>
            <div>
              <input
                className={styles.inputText}
                type="text"
                name="dominant_st_county"
                id="dominant_st_county"
                value={snapObject?.dominant_st_county}
                onChange={handleChange}
              />
            </div>
          </Grid>
          <Grid item sm={12} md={6}>
            <div>
              <label className={` font ${styles.labelText}`}>Paper Type</label>
            </div>
            <div>
              <input
                className={styles.inputText}
                type="text"
                name="paper_type"
                id="paper_type"
                value={snapObject?.paper_type}
                onChange={handleChange}
              />
            </div>
          </Grid>
          <Grid item sm={12} md={6}>
            <div>
              <label className={` font ${styles.labelText}`}>
                Dominant FIPS
              </label>
            </div>
            <div>
              <input
                className={styles.inputText}
                type="text"
                name="dominant_fips"
                id="dominant_fips"
                value={snapObject?.dominant_fips}
                onChange={handleChange}
              />
            </div>
          </Grid>
          <Grid item sm={12} md={6}>
            <div>
              <label className="font">Circulation</label>
            </div>
            <div>
              <input
                className={styles.inputText}
                type="text"
                name="circulation"
                id="circulation"
                value={snapObject?.circulation}
                onChange={handleChange}
              />
            </div>
            <div>
              <label style={{ color: "red" }}>{error.circulation}</label>
            </div>
          </Grid>
          <Grid item sm={12} md={6}>
            <div>
              <label className={` font ${styles.labelText}`}>
                Seminar Flag
              </label>
            </div>
            <div>
              <DropDownList
                name="seminarflag"
                id="seminarflag"
                className={styles.inputText}
                value={snapObject?.seminarflag || null}
                data={[
                  {
                    text: "No",
                    value: "N",
                  },
                  {
                    text: "RSVP",
                    value: "R",
                  },
                  {
                    text: "Yes",
                    value: "Y",
                  },
                ]}
                textField="text"
                onChange={handleChange}
                defaultItem={{ text: "Select-----" }}
              />
            </div>
          </Grid>
          <Grid item sm={12} md={6}>
            <div>
              <label className={` font ${styles.labelText}`}>
                Total Events
              </label>
            </div>
            <div>
              <input
                className={styles.inputText}
                type="text"
                name="total_events"
                id="total_events"
                value={snapObject?.total_events}
                onChange={handleChange}
              />
            </div>
            <div>
              <label style={{ color: "red" }}>{error.total_events}</label>
            </div>
          </Grid>
          <Grid item sm={12} md={6}>
            <div>
              <label className="font">Insertion Status</label>
            </div>
            <div>
              <input
                className={styles.inputText}
                type="text"
                name="insertion_status"
                id="insertion_status"
                value={snapObject?.insertion_status}
                onChange={handleChange}
              />
            </div>
            <div>
              <label style={{ color: "red" }}>{error.insertion_status}</label>
            </div>
          </Grid>
          <Grid item sm={12} md={6}>
            <div>
              <label
                className={`${snapObject?.channel?.text === "Acquisition Newspaper" &&
                  snapObject?.newspaper_specs.length === 0
                  ? "font required"
                  : "font"
                  }`}
              >
                Newspaper Specs
              </label>
            </div>
            <div>
              <input
                className={styles.inputText}
                type="text"
                name="newspaper_specs"
                id="newspaper_specs"
                value={snapObject.newspaper_specs}
                onChange={handleChange}
              />
            </div>
            {!hideLabel && (
              <div>
                <label style={{ color: "red" }}>
                  Newspaper Specs is required if Channel is "NewsPaper"
                </label>
              </div>
            )}
          </Grid>
          <Grid item sm={12} md={6}>
            <div>
              <label className={` font ${styles.labelText}`}>
                Budget Source
              </label>
            </div>
            <div>
              <input
                className={styles.inputText}
                type="text"
                name="budget_source"
                id="budget_source"
                value={snapObject?.budget_source}
                onChange={handleChange}
              />
            </div>
          </Grid>
          <Grid item sm={12} md={6}>
            <div>
              <label className={` font ${styles.labelText}`}>
                Provider Code
              </label>
            </div>
            <div>
              <input
                className={styles.inputText}
                type="text"
                name="provider_code"
                id="provider_code"
                value={snapObject?.provider_code}
                onChange={handleChange}
              />
            </div>
          </Grid>
          <Grid item sm={12} md={6}>
            <div>
              <label className={` font ${styles.labelText}`}>Brand</label>
            </div>
            <div>
              <input
                className={styles.inputText}
                type="text"
                name="brand"
                id="brand"
                value={snapObject?.brand}
                onChange={handleChange}
              />
            </div>
          </Grid>
          <Grid item sm={12} md={6}>
            <div>
              <label className={` font ${styles.labelText}`}>
                Publication Language
              </label>
            </div>
            <div>
              <input
                className={styles.inputText}
                type="text"
                name="publication_language"
                id="publication_language"
                value={snapObject?.publication_language}
                onChange={handleChange}
              />
            </div>
          </Grid>
          <Grid item sm={12} md={6}>
            <div>
              <label className={` font ${styles.labelText}`}>Publication</label>
            </div>
            <div>
              <input
                className={styles.inputText}
                type="text"
                name="publication"
                id="publication"
                value={snapObject?.publication}
                onChange={handleChange}
              />
            </div>
          </Grid>
          <Grid item sm={12} md={6}>
            <div>
              <label className={` font ${styles.labelText}`}>
                Template Code
              </label>
            </div>
            <div>
              <input
                className={styles.inputText}
                type="text"
                name="template_code"
                id="template_code"
                value={snapObject?.template_code}
                onChange={handleChange}
              />
            </div>
          </Grid>
          <Grid item sm={12} md={6}>
            <div>
              <label className={` font ${styles.labelText}`}>
                Publication Abbrevation
              </label>
            </div>
            <div>
              <input
                className={styles.inputText}
                type="text"
                name="publication_abbreviation"
                id="publication_abbreviation"
                value={snapObject?.publication_abbreviation}
                onChange={handleChange}
              />
            </div>
          </Grid>
        </Grid>
      </Box>
      <PromptAlert isBlocking={isBlocking} />
      {openDialog && (<InfoDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        messageInfo={
          {
            title: "ERROR",
            message: exceptionError
          }
        }
      />)}
    </>
  );
};
export default EditForm;
