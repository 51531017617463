import {checkMethodCalledOnClick} from './testUtils'
import {hasAccessByPrivilegeName, pathRedirectionForReportViewers, isReportViewer} from "./utils";

const getErrors = (data, errors = []) => {
  if (data.constructor === Array) {
    for (let element of data) {
      getErrors(element, errors);
    }
  } else if (data.constructor === Object) {
    for (let value of Object.values(data)) {
      getErrors(value, errors);
    }
  } else {
    errors.push(data);
  }
  return errors
};

export {
    checkMethodCalledOnClick,
    getErrors,
    hasAccessByPrivilegeName,
    isReportViewer,
    pathRedirectionForReportViewers
}

