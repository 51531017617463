import { useState } from "react";
import { cloneDeep } from "lodash";

import { getDefaultBlock, getDefaultVariable } from "./RuleConstants";

const useTextRuleJson = () => {
    const [textRulesJSON, setTextRulesJSON] = useState({ vars: [], blocks: [] })

    const handleVariableChange = (variables) => {
        variables = variables || []
        let tempTextRulesJSON = { ...textRulesJSON }
        const varNames = (variables || []).map(item => item.text)
        for (const variable of variables) {
            if (!textRulesJSON.blocks.some(item => item.variable === variable.text)) {
                tempTextRulesJSON.blocks.push({ ...cloneDeep(getDefaultBlock()), variable: variable.text })
            }
            if (!tempTextRulesJSON.vars.some(item => item.text === variable.text)) {
                tempTextRulesJSON.vars.push({ ...getDefaultVariable(), ...variable })
            }
        }
        tempTextRulesJSON.blocks = tempTextRulesJSON.blocks.filter(block => varNames.includes(block.variable))
        tempTextRulesJSON.vars = tempTextRulesJSON.vars.filter(item => varNames.includes(item.text))
        setTextRulesJSON(tempTextRulesJSON, false)
    }

    return {
        textRulesJSON,
        setTextRulesJSON,
        handleVariableChange
    }
}

export default useTextRuleJson;