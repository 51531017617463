const {fireEvent, screen} = require("@testing-library/react");


/**
 *This functiona accept the id of the element and checks no of times method is called on click of element.
 *
 * @param id id of the element you want to click
 * @param method method name that you want to check
 * @param noOfTimeCalled Expected no of times method called.
 */
export const checkMethodCalledOnClick = (id, method,noOfTimeCalled) => {
    const element = screen.getByTestId(id)
    expect(element).toBeInTheDocument()
    fireEvent.click(element)
    expect(method).toBeCalledTimes(noOfTimeCalled)

}
