import { useState } from "react";

const useDelete = () => {
  const [deleteDialog, setDeleteDialog] = useState(false)
  const [idsToBeDeleted, setIdsToBeDeleted] = useState([])
  const [singleDeleteDialog, setSingleDeleteDialog] = useState({flag: false, id: '', refid: ""})

  /**
   * Single / Group deletes
   */
  const resetAction = () => {
    setSingleDeleteDialog({flag: false, id: "", refid: ''})
    setDeleteDialog(false)
  }
  /**
     * Set singleDelete confirmation from grid row
     * @param {*} id 
     * @param {*} refId
     */
    const onRowDelete = async (id, refId) => {
      setSingleDeleteDialog({flag: true, id: id, refid: refId || id})
    };
  return {deleteDialog, setDeleteDialog, idsToBeDeleted, setIdsToBeDeleted, singleDeleteDialog, onRowDelete, resetAction};
}

export default useDelete
