import { useState } from "react";

import Add from "@mui/icons-material/Add";
import Stack from "@mui/material/Stack";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

import RuleCondition from "./RuleCondition";
import ResultConfigModal from "./ResultConfigModal";
import { matchChoices, logicalBlockChoices, getDefaultCondition, groupingActions, getDefaultExpression } from "./RuleConstants";

const RuleExpression = ({
    initialValue,
    onChange,
    onDelete,
    isTextPostProcessing,
    hideLogicalOperator,
    hideResult,
    groupAction,
    onSpiltUp,
    actions,
    onActionChange,
    canDelete,
    disableLogicalOperator,
    loadFieldsForTesting,
    isRoot,
    isDisabled
}) => {

    const expression = { ...initialValue }
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };
    const [selectedConditions, setSelectedConditions] = useState([])
    const [openResultConfigModal, setOpenResultConfigModal] = useState(false)
    const logicalOperator = expression.exprs?.length ? 'if' : 'else'

    const addCondition = () => {
        let tempExpression = { ...expression }
        tempExpression["exprs"].push({ ...getDefaultCondition() })
        onChange(tempExpression)
    }

    const deleteCondition = (index) => {
        let tempExpression = { ...expression }
        if (tempExpression["exprs"]?.length > 1) {
            tempExpression["exprs"].splice(index, 1)
        } else {
            tempExpression = { ...getDefaultExpression() }
        }
        onChange(tempExpression)
    }

    const onConditionChange = (index, condition) => {
        let tempExpression = { ...expression }
        tempExpression["exprs"][index] = condition
        onChange(tempExpression)
    }

    const updateValue = (field, value) => {
        onChange({ ...expression, [field]: value })
    }

    const handleChange = (event) => {
        let tempExpression = { ...expression }
        if (event.target.value === 'if') {
            tempExpression["exprs"] = [{ ...getDefaultCondition() }]
            onChange(tempExpression)
        }
        else if (event.target.value === 'else') {
            tempExpression["exprs"] = []
            onChange(tempExpression)
        }
    }

    const combineConditions = () => {
        let tempExpression = { ...expression }
        let selectedConditionObjs = []
        for (const conditionIndex of selectedConditions) {
            selectedConditionObjs.push(tempExpression["exprs"][conditionIndex])
        }
        tempExpression["exprs"].splice(selectedConditions[0], selectedConditions.length, getDefaultExpression())
        tempExpression["exprs"][selectedConditions[0]]["exprs"] = selectedConditionObjs
        onChange(tempExpression)
        setSelectedConditions([])
    }

    const handleConditionSelectionChange = (conditionIndex, condition, checked) => {
        const tempSelectedConditions = [...selectedConditions]
        if (checked) {
            tempSelectedConditions.push(conditionIndex)
        }
        else {
            const selectedConditionsIndex = selectedConditions.indexOf(conditionIndex)
            tempSelectedConditions.splice(selectedConditionsIndex, 1)
        }
        tempSelectedConditions.sort()
        setSelectedConditions(tempSelectedConditions)

    }

    return (
        <Stack spacing={2} sx={{ border: '2px solid #d6c0e3', borderRadius: "10px", p: 2, my: 2 }}>
            {
                isTextPostProcessing && <Typography variant="h6" sx={{ mb: 2 }}>Text Post-Processing</Typography>
            }
            <fieldset
                disabled={isDisabled}
                style={{
                    border: 'none',
                    margin: 0,
                    padding: 0
                }}
            >
                <Stack direction="row" spacing={2} justifyContent="space-between">
                    <Stack direction="row" spacing={1} sx={{ mb: 2 }}>

                        {
                            !hideLogicalOperator &&
                            <FormControl size="small" disabled={disableLogicalOperator}>
                                <Select
                                    displayEmpty
                                    value={logicalOperator}
                                    onChange={handleChange}
                                    input={<OutlinedInput />}
                                    renderValue={(selected) => {
                                        return logicalBlockChoices.find(item => item.value === selected).label ?? 'logical choice'
                                    }}
                                    MenuProps={MenuProps}
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    disabled={isDisabled}
                                >
                                    {logicalBlockChoices.map((choice) => (
                                        <MenuItem
                                            key={choice.value}
                                            value={choice.value}
                                        >
                                            {choice.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        }
                        {
                            logicalOperator !== 'else'
                            &&
                            <>
                                <FormControl size="small"  >
                                    <Select
                                        displayEmpty
                                        value={expression.op}
                                        onChange={(event) => updateValue('op', event.target.value)}
                                        input={<OutlinedInput />}
                                        renderValue={(selected) => {
                                            return selected ? matchChoices.find((item) => item.value === selected).label : "Match"
                                        }}
                                        MenuProps={MenuProps}
                                        inputProps={{ 'aria-label': 'Without label' }}
                                        disabled={isDisabled}
                                    >
                                        {matchChoices.map((choice) => (
                                            <MenuItem
                                                key={choice.value}
                                                value={choice.value}

                                            >
                                                {choice.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                {selectedConditions.length > 1 && <Button variant="outlined" startIcon={<Add />} size="small" onClick={combineConditions}>Combine</Button>}
                                {expression.type == "group" && expression.exprs.length > 0 && !isRoot && <Button variant="outlined" size="small" onClick={onSpiltUp}>Split Up</Button>}
                            </>
                        }
                    </Stack>
                    {
                        canDelete && <IconButton
                            aria-label="delete" size="small"
                            sx={{ borderRadius: '20%', color: '#827ac1', border: '1px solid #827ac1', alignSelf: "flex-start" }}
                            onClick={onDelete}
                            disabled={isDisabled}
                        >
                            <DeleteOutlineIcon />
                        </IconButton>
                    }

                </Stack>
            </fieldset>


            {/* --------- Conditions --------- */}
            {
                logicalOperator !== 'else'
                &&
                <>
                    {
                        expression["exprs"]?.map((condition, conditionIndex) => {
                            if (condition.type === "expr") {
                                return (<RuleCondition
                                    key={conditionIndex}
                                    checked={selectedConditions.includes(conditionIndex)}
                                    intialConditionValue={condition}
                                    onDelete={() => deleteCondition(conditionIndex)}
                                    onChange={(updatedCondition) => onConditionChange(conditionIndex, updatedCondition)}
                                    onSelect={(condition, checked) => handleConditionSelectionChange(conditionIndex, condition, checked)}
                                    canDelete={true}
                                    loadFieldsForTesting={loadFieldsForTesting}
                                    isDisabled={isDisabled}
                                />

                                )
                            }
                            else {
                                return (<RuleExpression
                                    key={conditionIndex}
                                    initialValue={condition}
                                    groupAction={2}
                                    hideResult={true}
                                    hideLogicalOperator={true}
                                    onDelete={() => deleteCondition(conditionIndex)}
                                    onChange={(updatedCondition) => onConditionChange(conditionIndex, updatedCondition)}
                                    onSpiltUp={() => {
                                        let tempExpression = { ...expression }
                                        tempExpression["exprs"]?.splice(conditionIndex, 1, ...tempExpression["exprs"][conditionIndex]["exprs"])
                                        onChange(tempExpression)
                                    }}
                                    canDelete={expression["exprs"]?.length > 1}
                                    loadFieldsForTesting={loadFieldsForTesting}
                                    isDisabled={isDisabled}
                                />)
                            }
                        })
                    }
                    <Button sx={{ alignSelf: 'flex-start' }} variant="outlined" startIcon={<Add />} size="large" onClick={addCondition} disabled={isDisabled} >Condition</Button>
                </>
            }

            {
                !hideResult
                &&
                <Stack sx={{ my: 2 }}>
                    {
                        logicalOperator !== 'else'
                        && <Typography variant="h6">THEN</Typography>
                    }

                    <Typography variant="subtitle1">Result</Typography>

                    <Stack direction="row" alignItems="center" spacing={1}>
                        <FormControl size="small" variant="outlined" fullWidth={true}>
                            <OutlinedInput
                                id="outlined-adornment-weight"
                                aria-describedby="outlined-weight-helper-text"
                                placeholder="Result"
                                value={actions}
                                onChange={(event) => onActionChange(event.target.value)}
                                disabled={isDisabled}
                            />
                        </FormControl>
                        <IconButton aria-label="delete"
                            sx={{ borderRadius: '20%', color: '#827ac1', border: '1px solid #827ac1' }}
                            size="small"
                            onClick={() => setOpenResultConfigModal(true)}
                            disabled={isDisabled}
                        >
                            <EditOutlinedIcon />
                        </IconButton>
                    </Stack>
                </Stack>
            }
            {
                openResultConfigModal && <ResultConfigModal openResultConfigModal={openResultConfigModal} setOpenResultConfigModal={setOpenResultConfigModal} actions={actions} updateValue={onActionChange} />
            }

        </Stack >)

}

export default RuleExpression;