import {styled} from "@mui/material/styles";
import Tab from "@mui/material/Tab";
import React from "react";
import Purple from "../../../theme/colors/purple";

export const StyledTab = styled((props) => <Tab {...props} />)(
  ({theme}) => ({
    textTransform: "none",
    minWidth: 0,
    letterSpacing: 0,
    color: Purple[900],
    "&:hover": {
      color: theme.palette.primary.main,
      opacity: 1,
    },
    "&.Mui-selected": {
      color: Purple[900],
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&.Mui-focusVisible": {
      backgroundColor: Purple[900],
    },
  })
);
