import { useState } from 'react'

import { cloneDeep } from "lodash";
import { getDefaultExpression } from 'components/sourcedocs/Rules/RuleConstants';
import { getValidExpr } from 'components/sourcedocs/Rules/utils';

export const useSelectionRule = () => {
    const [selectionRuleJSON, setSelectionRuleJSON] = useState(
        {
            ...cloneDeep(getDefaultExpression())
        }
    )
    const [baseSelectionRuleJSON, setBaseSelectionRuleJSON] = useState(
        {
            products: [],
            plan_choices: [],
            platform: [],
            base_operator: "and"
        }
    )

    const getBaseSelectionRuleJSON = (baseSelectionRuleJSON) => {
        const productIndex = 0
        let platformIndex = 0
        let baseJson = {
            "baseRules": true,
            "type": "group",
            "op": baseSelectionRuleJSON.base_operator,
            "opText": "",
            "isRoot": false,
            "exprs": [
            ]
        }
        if (baseSelectionRuleJSON.plan_choices?.length) {
            if (baseSelectionRuleJSON.products?.length) {
                platformIndex = 1
                baseJson.exprs.push(
                    {
                        "type": "group",
                        "op": "or",
                        "opText": "",
                        "isRoot": false,
                        "exprs": []
                    }
                )
            }
            if (baseSelectionRuleJSON.platform?.length) {
                baseJson.exprs.push(
                    {
                        "type": "group",
                        "op": "or",
                        "opText": "",
                        "isRoot": false,
                        "exprs": []
                    }
                )
            }
            for (const plan of baseSelectionRuleJSON.plan_choices) {
                if (baseSelectionRuleJSON.products?.length) {
                    baseJson.exprs[productIndex].exprs.push({
                        "type": "expr",
                        "pln": plan,
                        "mbr": "product",
                        "mbrText": `${plan}.product`,
                        "op": "eq",
                        "opText": "Is One of (=)",
                        "val": baseSelectionRuleJSON.products.join(","),
                        "valText": baseSelectionRuleJSON.products.join(","),
                        "valComp": false,
                        "editorType": 1,
                        "fldComp": false
                    })
                }
                if (baseSelectionRuleJSON.platform?.length) {
                    baseJson.exprs[platformIndex].exprs.push({
                        "type": "expr",
                        "pln": plan,
                        "mbr": "platform",
                        "mbrText": `${plan}.platform`,
                        "op": "eq",
                        "opText": "Is One of (=)",
                        "val": baseSelectionRuleJSON.platform.join(","),
                        "valText": baseSelectionRuleJSON.platform.join(","),
                        "valComp": false,
                        "editorType": 1,
                        "fldComp": false
                    })
                }
            }
        }
        return baseJson
    }

    const getOptionalSelectionRuleJSON = (selectionRuleJSON) => {
        const tempSelectionRuleJSON = JSON.parse(JSON.stringify(selectionRuleJSON))
        getValidExpr(tempSelectionRuleJSON)
        if (tempSelectionRuleJSON.exprs?.length) {
            return tempSelectionRuleJSON
        }
        return null
    }


    const getSelectionRuleToSend = () => {
        const selectionRuleToSend = {
            "type": "group",
            "op": "and",
            "opText": "",
            "isRoot": true,
            "products": baseSelectionRuleJSON.products,
            "platform": baseSelectionRuleJSON.platform,
            "plan_choices": baseSelectionRuleJSON.plan_choices,
            "base_operator": baseSelectionRuleJSON.base_operator,
            "exprs": []
        }
        if (baseSelectionRuleJSON.plan_choices?.length && (baseSelectionRuleJSON.products?.length || baseSelectionRuleJSON.platform?.length)) {
            const baseJson = getBaseSelectionRuleJSON(baseSelectionRuleJSON)
            selectionRuleToSend.exprs.push(baseJson)
        }
        const optionalSelectionRuleToSend = getOptionalSelectionRuleJSON(selectionRuleJSON)
        if (optionalSelectionRuleToSend) {
            selectionRuleToSend.exprs.push(optionalSelectionRuleToSend)
        }
        return selectionRuleToSend
    }

    return {
        selectionRuleJSON,
        setSelectionRuleJSON,
        baseSelectionRuleJSON,
        setBaseSelectionRuleJSON,
        getSelectionRuleToSend
    }
}
