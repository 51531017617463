import {
  Grid,
  GridColumn as Column,
  getSelectedState
} from "@progress/kendo-react-grid";
import {getter} from '@progress/kendo-react-common';

import React, {useState, useEffect} from "react"

const DATA_ITEM_KEY = 'creativecopyblockid';
const SELECTED_FIELD = 'selected';
const idGetter = getter(DATA_ITEM_KEY);

const CopyBlockGrid = (props) => {
  const {copyBlockDetails, handleCopyBlockDelete} = props;
  const [dataset, setDataSet] = useState(props.copyblockdetails || [])
  const [selectedState, setSelectedState] = React.useState({});
  useEffect(() => {
    setDataSet(copyBlockDetails)
  }, [copyBlockDetails])
  useEffect(() => {
    handleCopyBlockDelete(selectedState)
  }, [selectedState, handleCopyBlockDelete])
  const onSelectionChange = (event) => {
    const newSelectedState = getSelectedState({
      event,
      selectedState: selectedState,
      dataItemKey: DATA_ITEM_KEY,
    });
    setSelectedState(newSelectedState);
  };

  return <div className="copyblock-wrapper">
    <Grid data={
      dataset?.map(item => ({
        ...item,
        [SELECTED_FIELD]: selectedState[idGetter(item)]
      }))
    }
    dataItemKey={DATA_ITEM_KEY} selectedField={SELECTED_FIELD}
    className="kendoGrid"
    selectable={{
      enabled: true,
      drag: false,
      cell: false,
      mode: "single",
    }}
    onSelectionChange={onSelectionChange}>
      <Column width="300" field="copyblockrefid" title="Copy Block RefID"/>
      <Column field="title" title="Title"/>
      <Column width="300" field="copyblocklocation" title="Copy Block Location"/>
    </Grid>
  </div>
}
export default CopyBlockGrid;
