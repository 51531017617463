export const columnDef = [
  {
    dbcolumn: 'channel.shortname',
    colname: 'Channel',
    visible: 1,
    order: 1,
    mandatory: 1,
    width: '100px'
  },
  {
    dbcolumn: 'channelsegment.shortname',
    colname: 'Segment',
    visible: 1,
    order: 2,
    mandatory: 1,
    width: '100px'
  },
  {
    dbcolumn: 'product.geography.state', // Merge state and county in a single field via backend
    colname: 'State',
    visible: 1,
    order: 3,
    mandatory: 0,
    width: '100px'
  },
  {
    dbcolumn: 'product.geography.county',
    colname: 'County',
    visible: 1,
    order: 4,
    mandatory: 0,
    width: '100px'
  },
  {
    dbcolumn: 'product.contract', // Merge contract and PBP in a single field via backend
    colname: 'Contract',
    visible: 1,
    order: 5,
    mandatory: 0,
    width: '100px'
  },
  {
    dbcolumn: 'product.pbp',
    colname: 'PBP',
    visible: 1,
    order: 6,
    mandatory: 0,
    width: '100px'
  },
  {
    dbcolumn: 'product.pbpname',
    colname: 'Pbp Name',
    visible: 1,
    order: 7,
    mandatory: 0,
    width: '250px'
  },
  {
    dbcolumn: 'product.platform',
    colname: 'Platform',
    visible: 1,
    order: 8,
    mandatory: 0,
    width: '100px'
  },
  {
    dbcolumn: 'product.contractyear',
    colname: 'Year',
    visible: 1,
    order: 9,
    mandatory: 0,
    width: '100px'
  },
  {
    dbcolumn: 'product.ext.access',
    colname: 'Access',
    visible: 1,
    order: 10,
    mandatory: 0,
    width: '150px'
  },
  {
    dbcolumn: 'product.ext.brand',
    colname: 'Brand',
    visible: 1,
    order: 11,
    mandatory: 0,
    width: '100px'
  },
  {
    dbcolumn: 'product.product',
    colname: 'Product',
    visible: 1,
    order: 12,
    mandatory: 0,
    width: '100px'
  },
  {
    dbcolumn: 'product.premium',
    colname: 'Premium',
    visible: 1,
    order: 13,
    mandatory: 0,
    width: '100px'
  },
  {
    dbcolumn: 'product.ext.pcp',
    colname: 'Primary Care Physican',
    visible: 1,
    order: 14,
    mandatory: 0,
    width: '100px'
  },
  {
    dbcolumn: 'product.ext.partbgive',
    colname: 'Part B Give',
    visible: 1,
    order: 15,
    mandatory: 0,
    width: '100px'
  },
  {
    dbcolumn: 'product.ext.deductiblesvc',
    colname: 'Deductible',
    visible: 1,
    order: 16,
    mandatory: 0,
    width: '100px'
  },
  {
    dbcolumn: 'product.ext.max_oop',
    colname: 'Max Oop',
    visible: 1,
    order: 17,
    mandatory: 0,
    width: '200px'
  }
];

export const filterOpMap = {
  eq: "in",
  neq: "not in",
  contains: "contains",
  doesnotcontain: "does not contain",
  isempty: "is empty",
  isnotempty: "is not empty"
};

export const SOTType = {
  MARKETINGBULLETS: "marketingBullets",
  VALPROPS: "valProps"
};