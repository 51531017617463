import Purple from "../colors/purple";
import { WARM_WHITE } from "../colors";

const MuiButton = {
  styleOverrides: {
    root: {
      fontWeight: 400,
      textTransform: 'capitalize',
      borderRadius: '0.5rem',
      '&.MuiButton-contained:not(.Mui-disabled)': {
        backgroundImage: 'linear-gradient(180deg, #8279C2 24.48%, rgba(76, 66, 147, 0.83) 100%, rgba(76, 66, 147, 0.83) 100%)',
        color: WARM_WHITE,
      },
      '&.MuiButton-outlined:not(.Mui-disabled)': {
        borderColor: Purple[400],
      }
    },
  },
}

export default MuiButton
