export const filtersMap = {
  eq: "is",
  neq: "is not",
  startswith: "starts_with",
  endswith: "ends_with",
  contains: "contains",
  doesnotcontain: "does not contain",
  isnull: "is null",
  isnotnull: "is not null",
  isempty: "is empty",
  isnotempty: "is not empty",
  gt: "gt",
  gte: "gte",
  lt: "lt",
  lte: "lte",
  all: "and",
  any: "or",
  and: "and",
  or: "or",
  in: "in",
  "not in": "not in",
};
export const DEFAULT_NUMBER_OF_RECORDS = 100
