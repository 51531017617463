import { useEffect, useState } from "react";

import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import { useParams } from "react-router-dom";

import sotApi from "api/sotApi";

const CreativeTypesGrid = (props) => {
  const { copyblockid } = props;
  const { id, action } = useParams();
  const [dataList, setDataList] = useState([])

  useEffect(async () => {
    try {
      const res = await sotApi.valProps.getCreativeTypes({ copyblock_Id: copyblockid })
      setDataList(res?.data?.creative)
    } catch (e) {
      console.log(e)
    }
  }, [copyblockid])

  return <TableContainer component={Paper} sx={{ height: 350 }}>
    <Table sx={{ minWidth: 650, height: "max-content" }} aria-label="simple table" >
      <TableHead>
        <TableRow>
          <TableCell style={{ fontWeight: "bold" }} >Creative Type Ref Id</TableCell>
          <TableCell style={{ fontWeight: "bold" }} >Creative Type Name</TableCell>
          {action === "details" && <TableCell style={{ fontWeight: "bold" }} >Copy Block Location</TableCell>}

        </TableRow>
      </TableHead>
      <TableBody>
        {dataList.map(row => {
          return <TableRow>
            <TableCell>{row.refid}</TableCell>
            <TableCell>{row.name}</TableCell>
            {action === "details" && <TableCell>{row.copyblocklocation}</TableCell>}
          </TableRow>
        })}
      </TableBody>
    </Table>
  </TableContainer>
};

export default CreativeTypesGrid;
