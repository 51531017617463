import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import dayjs from "dayjs";
import Tooltip from '@mui/material/Tooltip';
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import IconButton from '@mui/material/IconButton';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import { Controller } from "react-hook-form";

import api from "Api";
import styles from "../../sourcedocs.module.scss";
import useGetDropdowns from "../../MarketingBullets/hooks/useGetDropdowns";
import { OutlinedInput } from "@mui/material";
import { compareStr } from "components/common/utils";

const BaseValPropsForm = ({ dataObj, control, onTextBlur, action, formState, watch }) => {

  const {
    disclaimerRefIDOptions,
    copyBlocksTypeOptions,
  } = useGetDropdowns(api);

  const getDisclaimerRefIDInfo = (desclaimerId) => {
    const match = disclaimerRefIDOptions.find(item => item.id === desclaimerId)
    return match ? match.disclaimertext : ""
  }

  const isDetails = action === "details";

  return (
    <Box>
      <Grid
        container
        rowSpacing={2}
        columnSpacing={{ sm: 0, md: 12 }}
        direction={{ sm: "column", md: "row" }}
      >
        <Grid item sm={12} md={6}>
          <div>
            <label className={`required font ${styles.labelText}`}>
              Ref Id
            </label>
          </div>
          <Controller
            name="refid"
            control={control}
            render={({
              field: { onChange, onBlur, value },
              fieldState: { error },
            }) => {
              return (
                <TextField
                  margin="dense"
                  id="refid"
                  fullWidth
                  size="small"
                  variant="outlined"
                  name="refid"
                  onBlur={onBlur}
                  onChange={onChange}
                  error={error}
                  helperText={error ? error.message : undefined}
                  value={value}
                />
              );
            }}
          />
          {/* <div>
            <label>(largest currently used Ref.Id:{ } )</label>
          </div> */}
        </Grid>
        <Grid item container sm={12} md={6}>
          <Grid item sm={11}>
            <div>
              <label className={`font ${styles.labelText}`}>Disclaimer Ref. ID</label>
            </div>
            <Controller
              name="disclaimerrefid"
              control={control}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
                return <TextField
                  margin="dense"
                  id="disclaimerrefid"
                  fullWidth
                  select
                  size='small'
                  variant="outlined"
                  name="disclaimerrefid"
                  onBlur={onBlur}
                  onChange={onChange}
                  error={error}
                  helperText={error ? error.message : undefined}
                  value={value}
                  disabled={isDetails}
                >
                  {disclaimerRefIDOptions && disclaimerRefIDOptions.length > 0 && disclaimerRefIDOptions.map((values) => {
                    return (
                      <MenuItem key={values.id}
                        value={values.id}>{values.text}</MenuItem>
                    )
                  })}
                </TextField>
              }}
            />
          </Grid>
          <Grid item sm={1} sx={{ display: "flex", alignItems: formState.errors?.refid ? "center" : "flex-end" }}>
            <IconButton>
              <Tooltip title={getDisclaimerRefIDInfo(watch("disclaimerrefid", ''))}>
                <InfoOutlinedIcon fontSize="large" />
              </Tooltip>
            </IconButton>
          </Grid>
        </Grid>

        <Grid item sm={12} md={6}>
          <div>
            <label className={`required font ${styles.labelText}`}>Title</label>
          </div>
          <Controller
            name="title"
            control={control}
            render={({
              field: { onChange, onBlur, value },
              fieldState: { error },
            }) => {

              return (
                <TextField
                  margin="dense"
                  id="title"
                  fullWidth
                  size="small"
                  variant="outlined"
                  name="title"
                  onBlur={onBlur}
                  onChange={onChange}
                  error={error}
                  helperText={error ? error.message : undefined}
                  value={value}
                />
              );
            }}
          />
        </Grid>
        <Grid item sm={12} md={6}>
          <div>
            <label className={`font ${styles.labelText}`}>
              Copy Text Append
            </label>
          </div>
          <Controller
            name="copytext_add"
            control={control}
            render={({
              field: { onChange, onBlur, value },
              fieldState: { error },
            }) => {
              return (
                <TextField
                  margin="dense"
                  id="copytext_add"
                  fullWidth
                  size="small"
                  variant="outlined"
                  name="copytext_add"
                  multiline
                  onBlur={onBlur}
                  onChange={onChange}
                  error={error}
                  helperText={error ? error.message : undefined}
                  value={value}
                />
              );
            }}
          />
        </Grid>
        <Grid item sm={12} md={6}>
          <FormControl size="small" fullWidth>
          <div>
            <label className={`required font ${styles.labelText}`}>Type</label>
          </div>
          <Controller
            name="copytype"
            control={control}
            render={({
              field: { onChange, onBlur, value },
              fieldState: { error },
            }) => {
              return (
                <Select
                 input={<OutlinedInput/>}
                  name="copytype"
                  renderValue={selected=>{
                    const match=copyBlocksTypeOptions.find(item=>{
                      if(selected===item.id)
                      return item
                    })
                    return selected!=="Select Type"?match?.text:"Select Type"
                  }}
                  onBlur={onBlur}
                  onChange={onChange}
                  error={error}
                  value={value}
                  disabled={isDetails}
                >
                  {copyBlocksTypeOptions &&
                    copyBlocksTypeOptions.length > 0 &&
                    copyBlocksTypeOptions.sort((a, b) => compareStr(a.text.toLowerCase(), b.text.toLowerCase())).map((values) => {
                      return (
                        <MenuItem key={values.id} value={values.id}>
                          {values.text}
                        </MenuItem>
                      );
                    })}
                </Select>
              );
            }}
          />
          </FormControl>
         {formState?.errors?.copytype?.message&& <label style={{color:"#d32f2f",fontweight:"400",
    fontSize:"0.75rem"}}>{formState ?.errors ?.copytype ?.message}</label>}
        </Grid>
        {dataObj && <Grid item sm={12} md={6}>
          <div>
            <label className={`font ${styles.labelText}`}>{action === "details" ? "Published By" : "Last Updated By"}</label>
          </div>
          <div>
            <Typography variant="subtitle1">
              {action === "details" ? dataObj?.publishusername : dataObj?.modifyusername}
            </Typography>
          </div>
        </Grid>}
        {dataObj && <Grid item sm={12} md={6}>
          <div>
            <label className={`font ${styles.labelText}`}>{action === "details" ? "Published Date" : "Last Updated Date"}</label>
          </div>
          <div>
            <Typography variant="subtitle1">
              {action === "details" ? dayjs(dataObj?.publishdatetime).format("YYYY/MM/DD hh:mm:ss A") :
                ((dataObj.modifydatetime) ? dayjs(dataObj?.modifydatetime).format("YYYY/MM/DD hh:mm:ss A") : dayjs(dataObj?.createdatetime).format("YYYY/MM/DD hh:mm:ss A"))}
            </Typography>
          </div>
        </Grid>}
        <Grid item sm={12}>
          <div>
            <label className={`required font ${styles.labelText}`}>Copy Text</label>
          </div>
          <Controller
            name="copytext"
            control={control}
            render={({
              field: { onChange, onBlur, value },
              fieldState: { error },
            }) => {
              return (
                <TextField
                  id="copytext"
                  fullWidth
                  size='small'
                  variant="outlined"
                  name="copytext"
                  placeholder="Enter Copy Text"
                  multiline
                  value={value}
                  onBlur={(e) => { onBlur(e.target.value); onTextBlur(e) }}
                  onChange={(e) => { onChange(e.target.value); }}
                  error={error}
                  helperText={error ? error.message : undefined}
                />
              )
            }
            }
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default BaseValPropsForm;
