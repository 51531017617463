import React, {useContext} from "react";
import {useHistory} from "react-router-dom";

import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";

import sotApi from "api/sotApi";
import {ApiParams} from "../constants";
import InfoDialog from "components/common/Dialogs/InfoDialog";
import { SnackbarContext } from "context/SnackbarContext";
import SampleDownload from "components/common/SampleDownload";

const UploadSnap = () => {
  const [isUploading, setIsUploading] = React.useState(false);
  const uploadInput = React.useRef();
  const [preValidationError, setpreValidationError] = React.useState([]);
  const [exceptionError, setexceptionError] = React.useState([]);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [label, setLabel] = React.useState();
  const {setSnackBarConfig} = useContext(SnackbarContext); 
  const history = useHistory();

  
  const changeHandler = async (e) => {
    setIsUploading(true);
    const file = e.target.files[0];
    setLabel(e.target.files[0].name);
    const formData = new FormData();
    formData.append("File", file);
    try {
      const res = await sotApi[ApiParams.moduleName].upload(formData);
      // alert(res?.data?.status);
      setSnackBarConfig({open: true, message: 'File uploaded successfully.', severity: 'success'})
      history.push({
        pathname: ApiParams.baseUrl,
        state: {
          importSuccess: true,
        },
      });
    } catch (error) {
      if (error?.response?.status === 412) {
        setpreValidationError(error.response.data);
      } else if (error?.response?.status === 500) {
        setexceptionError(error?.response?.data);
      } else if (error?.response?.status === 400) {
        setexceptionError((error.response.data.Error));
      } else {
        setexceptionError("Something went wrong!");
      }
      setIsUploading(false)
      setOpenDialog(true);
      setLabel("")
    }
  };
  return (
    <>
      <h4 style={{color: "#8F8A92", fontSize: "14px", margin: 0}}>
        SOURCE DOCUMENTS
      </h4>
      <h3 style={{color: "#050307", fontSize: "29px", margin: 0}}>
        SNAP:Upload
      </h3>
      <div className="dnd-wrapper">
        {isUploading ? (
          <>
            <p className="uploading">Uploading....</p>
            <label>{label}</label>
          </>
        ) : (
          <>
            <div className="dnd-title"></div>
            <input
              id="upload-input"
              type="file"
              accept=".xls,.xlsx"
              className="input-hide"
              ref={uploadInput}
              data-testid="button-change"
              onChange={changeHandler}
              onClick={(e) => {
                e.target.value = null;
              }}
            />
            <button
              style={{width: "350px", height: "100px"}}
              className="uploadfile-button"
              id="uploadfile-button"
              data-testid="button-click"
              onClick={(e) => {
                uploadInput.current.click();
              }}
            >
              <FileUploadOutlinedIcon/>
              <label style={{fontSize: "20px"}}> Upload File</label>
            </button>
            <div style={{marginTop: '50px'}}>
              <SampleDownload fileName="SNAPImport_sample" />
            </div>
            <label>{label}</label>
          </>
        )}
        {openDialog && (<InfoDialog
          openDialog={openDialog}
          setOpenDialog={setOpenDialog}
          messageInfo={
            {
              message: preValidationError.length > 0 ? preValidationError: exceptionError
            }
          }
        />)}
      </div>
    </>
  );
};
export default UploadSnap;
