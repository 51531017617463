import React, {useEffect, useState, useContext} from "react"
import {useHistory, withRouter} from "react-router-dom";

import {DropDownList} from "@progress/kendo-react-dropdowns";
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import PromptAlert from "components/PromptAlert";
import api from "Api";
import styles from '../../sourcedocs.module.scss';
import {booleanOptions} from 'components/sourcedocs/constants';
import {SnackbarContext} from 'context/SnackbarContext'
import useGetDropdowns from "../hooks/useGetDropdowns";
import LoadingPanel from 'components/common/TabPanel/LoadingPanel';
import { MAP_PROD_HOSTNAME } from "constant";

const initialState = {
  refid: "",
  channel: null, //dropdown
  segment: null, // dropdown
  creativenm: "",
  brand: null, // dropdown
  provider: null, // Y/N
  leadplangraphic: null, // dropdown
  hit: null,
  providercode: "",
  tfn_ref_id: "",
  dynamic_tfn_ref_id: "",
  cms1: "",
  seminarflag: null, // Y/N
  creativetyperefid: null, //dropdown
  phase: "",
  creativecode: "",
  templatecode: "",
  isgiveback: {id: "N", text: "No"}, //Y/N
  platform: null, // dropdown
  language: null, // dropdown
  size: "",
  marketingbullets: "",
  agegrouping: "",
  birthdatereq: "",
  splitpercent: "",
  url:"",
};

const NewCreativeData = () => {
  const [dataState, setDataState] = useState(initialState);
  const [errorState, setErrorState] = useState({
    refid: "",
    channel: "",
    segment: "",
    creativenm: "",
    brand: "",
    provider: "",
    hit: null,
    cms1: "",
    seminarflag: "",
    creativetyperefid: "",
    language: "",
    tfn_ref_id: ""
  });
  const [callingApi, setCallingApi] = useState(false);
  const [largestRefId, setLargestRefId] = useState();
  const [isBlocking, setBlocking] = useState(false);
  const {setSnackBarConfig} = useContext(SnackbarContext); 
  const history = useHistory();
  const isProdEnv = window.location.hostname === MAP_PROD_HOSTNAME;
  const {
    channelOptions,
    segmentOptions,
    brandOptions,
    LPGOptions,
    hitOptions,
    creativeTypeRefIDOptions,
    platformOptions,
    languageOptions,
    leadCreativeOptions,
    isLoading,
    populateSegments
  } = useGetDropdowns(api, dataState?.channelsegment?.channel?.channel_id);

  
useEffect(()=>{
getMaxRefId()
},[])
  useEffect(() => {
    if (!!callingApi) {
      setBlocking(false);
      createData();
    }
  }, [callingApi]);
const getMaxRefId=async()=>{
  try{
const response=await api.getRefIdCreativeData();
setLargestRefId(response?.data?.maxrefid)
  }catch(e)
  {
        console.log(e)
  }
}
  const createData = async () => {
    try {
      const payload = {
        ...dataState,
        brand: dataState.brand?.id,
        channelsegment_id: dataState.segment?.id,
        hit: dataState.hit?.id,
        isgiveback: dataState.isgiveback?.id === "Y",
        language: dataState.language?.text,
        leadplangraphic: dataState.leadplangraphic?.id || "",
        platform: dataState.platform?.id ? dataState.platform?.text : "",
        provider: dataState.provider?.id,
        seminarflag: dataState.seminarflag?.id,
        creativetyperefid: dataState.creativetyperefid?.id,
        leadcreative: dataState?.leadcreative?.id || ""
      };
      if (dataState.segment?.department?.indexOf("Email") === -1) {
        payload.agegrouping = "";
        payload.splitpercent = "";
        payload.birthdatereq = "";
      }
      delete payload.segment;
      delete payload.channel;
      await api.createCreativeData(payload);
      setDataState(initialState);
      setSnackBarConfig({open: true, message: 'New creative data created successfully.', severity: 'success'})
      history.push('/sourcedocs/creativeData');
    } catch (e) {
      if (e.response?.status === 400) {
        Object.entries(e.response?.data).forEach(([key, value]) => {
          setErrorState(prevState => ({
            ...prevState,
            [key]: typeof value === "string" ? value : value.join('; ')
          }));
        })
      } else {
        alert("Something went wrong");
      }
    } finally {
      setCallingApi(false);
    }
  };

  const handleChange = (e) => {
   
    e.preventDefault();
    setCallingApi(false);
    const newErrorState = {
      refid: dataState.refid?.length > 7 ? "RefId cannot be more than 7 characters" : dataState.refid === "" ? "Ref ID is required": "",
      channel: (!dataState.channel?.id)? "Channel is required":"",
      segment: (!dataState.segment?.id)? "Segment is required":"",
      creativenm: (dataState.creativenm === "")? "Creative Name is required":"",
      brand: (!dataState.brand?.id)? "Brand is required": "",
      provider: (!dataState.provider?.id) ? "Provider is required":"",
      hit: (!dataState.hit?.id) ? "Hit # is required" : "",
      tfn_ref_id: (dataState.tfn_ref_id === "") ? "TFN Ref ID is required": "",
      cms1: (dataState.cms1 === "") ? "CMS1 is required": "",
      seminarflag: (!dataState.seminarflag?.id) ? "Seminar flag is required": "",
      creativetyperefid: (!dataState.creativetyperefid?.id) ? "Creative Type is required": "",
      language: (!dataState.language?.id) ? "Language is required": ""
    }
    setErrorState(newErrorState);
    if (Object.values(newErrorState).filter(value => value !== "").length === 0)
      { const res=window.confirm("Updating this item may affect existing MBM items. Continue ?")
      if(res)
        setCallingApi(true);
      }
    else
      setCallingApi(false);
  };

  const handleInputChange = (event, callbackMethod) => {
    setBlocking(true);
    setDataState({
      ...dataState,
      [event.target.name]: event.target.value
    });
    callbackMethod && callbackMethod(event.target.value?.id);
    setErrorState({
      ...errorState,
      [event.target.name]: ""
    });
  }

  return (
    <>
      <h4 style={{color: '#8F8A92', fontSize: '14px', margin: 0}}>CREATIVE DATA</h4>
      <h3 style={{color: '#050307', fontSize: '29px', margin: 0}}>New</h3>
      <div className="new-wrapper">
        <div style={{padding: 30}}>
          <Stack direction="row" spacing={1}>
            <Button variant="outlined" className="button"
            data-testid="button-click"
                    onClick={handleChange}>Save
            </Button>
          </Stack>
          {isLoading && <LoadingPanel />}
          <Box pt={5} className={styles.sourceDocsContainer}>
            <Grid container spacing={2}>
              <Grid item sm={12} md={6}>
                <div>
                  <label className={`required font ${styles.labelText}`}>Ref.ID</label>
                </div>
                <div>
                  <input name="refid" id="refid" className={styles.inputText} type="text" value={dataState.refid}  data-testid="button-change"
                         onChange={handleInputChange}/>
                </div>
                <label>(Largest currently used RefId: {largestRefId})</label>
                <div><label style={{color: "red"}}>{errorState.refid}</label></div>
              </Grid>
              <Grid item sm={12} md={6}>
                <div><label className={`required font ${styles.labelText}`}>Creative Name</label></div>
                <div>
                  <input name="creativenm" id="creativenm" className={styles.inputText} type="text" value={dataState.creativenm}
                         onChange={handleInputChange}/>
                </div>
                <label style={{color: "red"}}>{errorState.creativenm}</label>
              </Grid>
              <Grid item sm={12} md={6}>
                <div><label className={`required font ${styles.labelText}`}>Channel</label></div>
                <DropDownList
                  name="channel"
                  id="channel"
                  className={styles.dropDownList}
                  value={dataState.channel}
                  data={channelOptions}
                  textField="text"
                  dataItemKey="id"
                  onChange={event => handleInputChange(event, populateSegments)}
                  defaultItem={{text: "Select Channel"}}
                />
                <div><label style={{color: "red"}}>{errorState.channel}</label></div>
              </Grid>
              <Grid item sm={12} md={6}>
                <div><label className={`required font ${styles.labelText}`}>Segment</label></div>
                <DropDownList
                  name="segment"
                  id="segment"
                  className={styles.dropDownList}
                  disabled={!dataState.channel?.id}
                  value={dataState.segment}
                  data={segmentOptions}
                  textField="text"
                  dataItemKey="id"
                  onChange={handleInputChange}
                  defaultItem={{text: "Select Segment"}}
                />
                <div><label style={{color: "red"}}>{errorState.segment}</label></div>
              </Grid>
              <Grid item sm={12} md={6}>
                <div><label className={`required font ${styles.labelText}`}>Brand</label></div>
                <DropDownList
                  name="brand"
                  id="brand"
                  className={styles.dropDownList}
                  value={dataState.brand}
                  data={brandOptions}
                  textField="text"
                  dataItemKey="id"
                  onChange={handleInputChange}
                  defaultItem={{text: "Select Brand"}}
                />
                <div><label style={{color: "red"}}>{errorState.brand}</label></div>
              </Grid>
              <Grid item sm={12} md={6}>
                <div><label className={`required font ${styles.labelText}`}>Provider</label></div>
                <DropDownList
                  name="provider"
                  id="provider"
                  className={styles.dropDownList}
                  value={dataState.provider}
                  data={booleanOptions}
                  textField="text"
                  dataItemKey="id"
                  onChange={handleInputChange}
                  defaultItem={{text: "Select Provider"}}
                />
                <div><label style={{color: "red"}}>{errorState.provider}</label></div>
              </Grid>
              <Grid item sm={12} md={6}>
                <div><label className={`font ${styles.labelText}`}>Lead Plan Graphic</label></div>
                <DropDownList
                  name="leadplangraphic"
                  id="leadplangraphic"
                  className={styles.dropDownList}
                  value={dataState.leadplangraphic}
                  data={LPGOptions}
                  textField="text"
                  dataItemKey="id"
                  onChange={handleInputChange}
                  defaultItem={{ text: "Select LPG", id: null }}
                />
              </Grid>
              <Grid item sm={12} md={6}>
                <div><label className={`required font ${styles.labelText}`}>Hit #</label></div>
                <DropDownList
                  name="hit"
                  id="hit"
                  className={styles.dropDownList}
                  value={dataState.hit}
                  data={hitOptions}
                  textField="text"
                  dataItemKey="id"
                  onChange={handleInputChange}
                  defaultItem={{text: "Select Hit Number"}}
                />
                <div><label style={{color: "red"}}>{errorState.hit}</label></div>
              </Grid>
              {!isProdEnv && <Grid item sm={12} md={6}>
                <div><label className={`font ${styles.labelText}`}>Lead Creative</label></div>
                <DropDownList
                  name="leadcreative"
                  id="leadcreative"
                  className={styles.dropDownList}
                  value={dataState.leadcreative}
                  data={leadCreativeOptions}
                  textField="text"
                  dataItemKey="id"
                  onChange={handleInputChange}
                  defaultItem={{text: "Select Lead Creative"}}
                />
              </Grid>}
              <Grid item sm={12} md={6}>
                <div>
                  <label className={`font ${styles.labelText}`}>Provider Code</label>
                </div>
                <div>
                  <input name="providercode" id="providercode" className={styles.inputText} type="text" value={dataState.providercode}
                         onChange={handleInputChange}/>
                </div>
              </Grid>
              <Grid item sm={12} md={6}>
                <div>
                  <label className={`required font ${styles.labelText}`}>TFN Ref ID</label>
                </div>
                <div>
                  <input name="tfn_ref_id" id="tfn_ref_id" className={styles.inputText} type="text" value={dataState.tfn_ref_id}
                         onChange={handleInputChange}/>
                </div>
                <div><label style={{color: "red"}}>{errorState.tfn_ref_id}</label></div>
              </Grid>
              {dataState.channel?.text?.toLowerCase().indexOf("email") > -1 && <Grid item sm={12} md={6}>
                <div>
                  <label className={`font ${styles.labelText}`}>DynamicTfnRefId</label>
                </div>
                <div>
                  <input
                    className={styles.inputText}
                    type="text"
                    name="dynamic_tfn_ref_id"
                    id="dynamic_tfn_ref_id"
                    value={dataState.dynamic_tfn_ref_id}
                    onChange={handleInputChange}
                  />
                </div>
              </Grid>}
              <Grid item sm={12} md={6}>
                <div>
                  <label className={`required font ${styles.labelText}`}>CMS1</label>
                </div>
                <div>
                  <input name="cms1" id="cms1" className={styles.inputText} type="text" value={dataState.cms1}
                         onChange={handleInputChange}/>
                </div>
                <div><label style={{color: "red"}}>{errorState.cms1}</label></div>
              </Grid>
              <Grid item sm={12} md={6}>
                <div><label className={`required font ${styles.labelText}`}>Seminar</label></div>
                <DropDownList
                  name="seminarflag"
                  id="seminarflag"
                  className={styles.dropDownList}
                  value={dataState.seminarflag}
                  data={booleanOptions}
                  textField="text"
                  dataItemKey="id"
                  onChange={handleInputChange}
                  defaultItem={{text: "Select Seminar flag"}}
                />
                <div><label style={{color: "red"}}>{errorState.seminarflag}</label></div>
              </Grid>
              <Grid item sm={12} md={6}>
                <div><label className={`required font ${styles.labelText}`}>Creative Type Ref ID</label></div>
                <DropDownList
                  name="creativetyperefid"
                  id="creativetyperefid"
                  className={styles.dropDownList}
                  value={dataState.creativetyperefid}
                  data={creativeTypeRefIDOptions}
                  textField="text"
                  dataItemKey="id"
                  onChange={handleInputChange}
                  defaultItem={{text: "Select Creative Type Ref ID"}}
                />
                <div><label style={{color: "red"}}>{errorState.creativetyperefid}</label></div>
              </Grid>
              <Grid item sm={12} md={6}>
                <div>
                  <label className={`font ${styles.labelText}`}>Phase</label>
                </div>
                <div>
                  <input name="phase" id="phase" className={styles.inputText} type="text" value={dataState.phase}
                         onChange={handleInputChange}/>
                </div>
              </Grid>
              <Grid item sm={12} md={6}>
                <div>
                  <label className={`font ${styles.labelText}`}>Creative Code</label>
                </div>
                <div>
                  <input name="creativecode" id="creativecode" className={styles.inputText} type="text" value={dataState.creativecode}
                         onChange={handleInputChange}/>
                </div>
              </Grid>
              <Grid item sm={12} md={6}>
                <div>
                  <label className={`font ${styles.labelText}`}>Template Code</label>
                </div>
                <div>
                  <input name="templatecode" id="templatecode" className={styles.inputText} type="text" value={dataState.templatecode}
                         onChange={handleInputChange}/>
                </div>
              </Grid>
              <Grid item sm={12} md={6}>
                <div><label className={`font ${styles.labelText}`}>Is Give Back</label></div>
                <DropDownList
                  name="isgiveback"
                  id="isgiveback"
                  className={styles.dropDownList}
                  value={dataState.isgiveback}
                  data={booleanOptions}
                  textField="text"
                  dataItemKey="id"
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item sm={12} md={6}>
                <div><label className={`font ${styles.labelText}`}>Platform</label></div>
                <DropDownList
                  name="platform"
                  id="platform"
                  className={styles.dropDownList}
                  value={dataState.platform}
                  data={platformOptions}
                  textField="text"
                  dataItemKey="id"
                  onChange={handleInputChange}
                  defaultItem={{text: "Select Platform", id: null}}
                />
              </Grid>
              <Grid item sm={12} md={6}>
                <div><label className={`required font ${styles.labelText}`}>Language</label></div>
                <DropDownList
                  name="language"
                  id="language"
                  className={styles.dropDownList}
                  value={dataState.language}
                  data={languageOptions}
                  textField="text"
                  dataItemKey="id"
                  onChange={handleInputChange}
                  defaultItem={{text: "Select Language"}}
                />
                <div><label style={{color: "red"}}>{errorState.language}</label></div>
              </Grid>
              <Grid item sm={12} md={6}>
                <div>
                  <label className={`font ${styles.labelText}`}>Size</label>
                </div>
                <div>
                  <input name="size" id="size" className={styles.inputText} type="text" value={dataState.size}
                         onChange={handleInputChange}/>
                </div>
              </Grid>
              <Grid item sm={12} md={6}>
                <div>
                  <label className={`font ${styles.labelText}`}>Url</label>
                </div>
                <div>
                  <input name="url" id="url" className={styles.inputText} type="text" value={dataState.url}
                         onChange={handleInputChange}/>
                </div>
              </Grid>
              <Grid item sm={12} md={6}>
                <div>
                  <label className={`font ${styles.labelText}`}>Marketing Bullets</label>
                </div>
                <div>
                  <input name="marketingbullets" id="marketingbullets" className={styles.inputText} type="text"
                         value={dataState.marketingbullets}
                         onChange={handleInputChange}/>
                </div>
              </Grid>
              {/* Email specific fields */}
              {dataState.channel?.text?.toLowerCase().indexOf("email") > -1 && <>
                <Grid item sm={12} md={6}>
                  <div>
                    <label className={`font ${styles.labelText}`}>Age Grouping</label>
                  </div>
                  <div>
                    <input
                      name="agegrouping"
                      id="agegrouping"
                      className={styles.inputText}
                      type="text"
                      value={dataState?.agegrouping}
                      onChange={handleInputChange}
                    />
                  </div>
                </Grid>
                <Grid item sm={12} md={6}>
                  <div>
                    <label className={`font ${styles.labelText}`}>Birthdate Request</label>
                  </div>
                  <div>
                    <input
                      className={styles.inputText}
                      type="text"
                      name="birthdatereq"
                      id="birthdatereq"
                      value={dataState?.birthdatereq}
                      onChange={handleInputChange}
                    />
                  </div>
                </Grid>
                <Grid item sm={12} md={6}>
                  <div>
                    <label className={`font ${styles.labelText}`}>Split Percent</label>
                  </div>
                  <div>
                    <input
                      className={styles.inputText}
                      type="text"
                      name="splitpercent"
                      id="splitpercent"
                      value={dataState?.splitpercent}
                      onChange={handleInputChange}
                    />
                  </div>
                </Grid>
              </>}
            </Grid>
          </Box>
        </div>
      </div>
      <PromptAlert isBlocking={isBlocking}/>
    </>
  )
};

export default withRouter(NewCreativeData); 
