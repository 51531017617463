import React, { useContext, useEffect } from "react";

import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import CheckIcon from "@mui/icons-material/Check";
import PropTypes from "prop-types";
import ReplayRounded from "@mui/icons-material/ReplayRounded";
import CircularProgress from "@mui/material/CircularProgress";
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import ScienceOutlinedIcon from '@mui/icons-material/ScienceOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";

import styles from './modules/GridHeaderButtons.module.scss';
import ManageColumns from "../manageColumns";
import { WARM_WHITE } from "../../theme/colors";
import { SnackbarContext } from "context/SnackbarContext";
import DownloadWithFilters from "./DownloadWithFilters";

function GridHeaderButtons
  ({
    onButtonClick,
    selectedRows,
    manageColumnData,
    updateUserPreferences,
    idsToBeDeleted,
    hasClone,
    isExcel,
    isDownloading,
    customButtonList,
    hasPublish,
    hasUpload,
    hasTest,
    hasSaveAs
  }) {

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const { setSnackBarConfig } = useContext(SnackbarContext);

  /**
   * Handle click event based on button type, if saveAs the open menuItem popover
   * @param event
   * @param value
   * @param hasArrow
   */
  const handleClick = (event, value, hasArrow) => {
    if (hasArrow) {
      setAnchorEl(event.currentTarget);
    } else {
      onButtonClick(value)
    }
  };

  useEffect(() => {
    let timeOutID;
    if (isDownloading) {
      timeOutID = setTimeout(() => {
        if (isDownloading) {
          setSnackBarConfig({ open: true, message: 'File downloading is taking more time then expected', severity: 'info' })
        }
      }, 60000);
    }
    if (timeOutID) {
      return () => {
        clearTimeout(timeOutID);
      }
    }

  }, [isDownloading])
  /**
   * Handle Excel Download, and close menu popover
   */
  const handleDownload = (value, customFileName) => {
    setAnchorEl(null);
    onButtonClick(value, customFileName)
  };
  const buttonList = [
    {
      name: 'New',
      startIcon: <img alt='new' src="/icons/addNew.svg" className="buttonIcon" />,
      visible: true,
      value: 'new'
    },
    {
      name: 'Edit',
      startIcon: <img alt='edit' className="buttonIcon" src="/icons/edit.svg" />,
      visible: true,
      value: 'edit',
      disabled: idsToBeDeleted?.length > 1
    },
    {
      name: 'Delete',
      startIcon: <DeleteOutlinedIcon />,
      visible: true,
      value: 'delete',
    },
    {
      name: 'Clone',
      startIcon: <img src="/icons/clone.svg" class="buttonIcon" alt="clone" />,
      visible: hasClone,
      value: 'clone',
      disabled: idsToBeDeleted?.length > 1
    },
    {
      name: 'Upload',
      startIcon: <FileUploadOutlinedIcon />,
      visible: hasUpload,//No upload for MBWorkingSet
      value: 'upload',
    },
    {
      name: 'Save As',
      startIcon: <img alt='save' src="/icons/saveAs.svg" className="buttonIcon" />,
      visible: hasSaveAs,
      value: 'saveAs',
      hasArrow: true,
      disabled: isDownloading
    },
    {
      name: 'Reset Grid',
      startIcon: <ReplayRounded />,
      visible: true,
      value: 'resetGrid'
    },
    {
      name: 'Test',
      startIcon: <ScienceOutlinedIcon />,
      visible: hasTest,
      value: 'test',
      disabled: idsToBeDeleted?.length > 1
    },
    {
      name: "Publish",
      startIcon: <CheckIcon />,
      visible: hasPublish,
      value: 'publish'
    }
  ]

  const dropMenuList = {
    sotSnap: [
      {
        label: ' All Channels Creative Data File',
        value: 'channel_cd',
        customFileName: 'CreativeData.xlsx',
        icon: <img src="/icons/icons8-excel-18.png" />
      },
      {
        label: ' Email Creative data',
        value: 'email_cd',
        customFileName: 'CreativeDataEmail.xlsx',
        icon: <img src="/icons/icons8-excel-18.png" />
      }
    ],
    excel: [
      {
        label: ' Excel',
        value: 'excel',
        icon: <img src="/icons/icons8-excel-18.png" />
      }
    ]
  }

  return (<Stack direction="row" spacing={2} className={styles.gridHeader} sx={{ backgroundColor: WARM_WHITE }}>
    {(customButtonList || buttonList).map(function (item, i) {
      if (item.name === "Save As")// for Custom button List->Save As on Published Grids
        item.disabled = isDownloading;
      return <React.Fragment key={`${item.value}${i} `}>
        {item.visible && <Button
          id={`sot-grid-table-button-${item.value}`}
          data-testid={`sot-grid-table-button-${item.value}`}
          variant={item.variant || 'outlined'}
          aria-controls={open ? 'sot-grid-table-menu' : undefined}
          aria-haspopup="true"
          className="button"
          aria-expanded={open ? 'true' : undefined}
          onClick={(event) => {
            handleClick(event, item.value, item.hasArrow)
          }}
          startIcon={item.startIcon}
          endIcon={item.hasArrow && <KeyboardArrowDownIcon />}
          disabled={item.disabled}
        >
          {item.name}
          {item.name === "Save As" && item.disabled && isDownloading && <CircularProgress sx={{ ml: 1 }} size={16}></CircularProgress>}
        </Button>}
        {item.visible && item.hasArrow && <Menu
          id="sot-grid-table-menu"
          data-testid="sot-grid-table-menu"
          aria-labelledby="sot-grid-table-button"
          anchorEl={anchorEl}
          open={open}
          onClose={() => {
            setAnchorEl(null)
          }}
        >
          {!isExcel ?
            <DownloadWithFilters
              optionsList={dropMenuList?.sotSnap}
            />
            :
            ((customButtonList && item.menuItems) || dropMenuList?.excel).map((menuItem, index) => (<MenuItem
              key={`${menuItem.value}${index}`}
              onClick={() => handleDownload(menuItem.value, menuItem.customFileName)}>
              {menuItem.icon} {menuItem.label}
            </MenuItem>))
          }
        </Menu>}
      </React.Fragment>
    })}
    <Box className={styles.manageButton}>
      <ManageColumns
        menuList={manageColumnData}
        onChangeOption={updateUserPreferences}
      />
    </Box>
  </Stack>)
}

GridHeaderButtons.propTypes = {
  // Expose the button click event so that it could be handle at parent
  onButtonClick: PropTypes.func,
  // Pass the total number of rows selected on grid
  selectedRobws: PropTypes.object,
  manageColumnData: PropTypes.array,
  updateUserPreferences: PropTypes.func,
  idsToBeDeleted: PropTypes.array,
  hasClone: PropTypes.bool,
  isExcel: PropTypes.bool
}

GridHeaderButtons.defaultProps = {
  hasClone: true,
  isExcel: true,
  hasPublish: false,
  hasUpload: true,
  hasSaveAs: true,
}

export default GridHeaderButtons;
